import $ from 'jquery';

export function filterControl(options) {
    new FilterControl(this, options);
}

class FilterControl {

    constructor(container, options) {
        this.options = options;
        this.$container = $(container);
        this.drawFilter(0, this.options.name, this.options.data, this.$container);
    }

    typeOfProperty(name) {
        return this.options.structure.properties[name];
    }

    drawFilter(level, prefix, data, $container) {
        const $level = $('<div></div>', {
            'class': 'filter-level',
            'data-level': level,
            'data-conjunction': data.conjunction,
            'data-type': this.typeOfProperty(data.property),
            'data-equality': data.equality,
            'id': $container.attr('id') + '_level'
        }).appendTo($container);
        $('<input>', {
            'type': 'hidden',
            'name': prefix + '[conjunction]',
            'value': data.conjunction,
            'id': $container.attr('id') + '_conjunction',
        }).appendTo($level);
        $('<input>', {
            'type': 'hidden',
            'name': prefix + '[equality]',
            'value': data.equality,
            'id': $container.attr('id') + '_equality',
        }).appendTo($level);
        $('<input>', {
            'type': 'hidden',
            'name': prefix + '[property]',
            'value': data.property,
            'id': $container.attr('id') + '_property',
        }).appendTo($level);
        $('<input>', {
            'type': 'hidden',
            'name': prefix + '[value]',
            'value': data.value,
            'id': $container.attr('id') + '_value',
        }).appendTo($level);
        const $header = $('<div></div>', { 'class': 'filter-header' }).appendTo($level);
        const $line = $('<div></div>', { 'class': 'filter-line' }).appendTo($level);
        const $children = $('<div></div>', {
            'class': 'filter-children-container',
            'data-next': data.children ? data.children.length : 0,
        }).appendTo($level);
        if (data.children) {
            for (let i = 0; i < data.children.length; i++) {
                this.drawFilter(level + 1, prefix + '[children][' + i + ']', data.children[i], $children);
            }
        }

        this.drawConjunctionChoice($header, data, $level.attr('id'));
    }

    drawConjunctionChoice($container, data, parentId) {

    }

}
