import $ from 'jquery';
import createUid from 'muuri/src/utils/createUid';

class FragmentManager {

    run() {
        this.queue = [];
        $('.fragment:visible').each((index, element) => this.check(element));
        if (this.queue.length > 0) {
            Pace.ignore(() => $.ajax({
                url: '/fragment',
                data: JSON.stringify(this.queue),
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json'
            })
                .then((response) => this.processResponse(response))
                .always(() => setTimeout(() => this.run(), 5000)));
        } else {
            setTimeout(() => this.run(), 5000);
        }
    }

    processResponse(response) {
        for (let i = 0; i < response.length; i++) {
            let reply = response[i];
            const $element = $('#' + reply.uid);
            $element.html(reply.html);
            const date = new Date(reply.refreshAt);
            $element.attr('data-refresh', date.getTime());
        }
    }

    check(element) {
        const $element = $(element);
        let uid = $element.attr('id');
        if (uid === undefined || uid === '') {
            uid = 'fragment-' + createUid();
            $element.attr('id', uid);
        }
        const fid = $element.attr('data-fragment');
        const p1 = $element.attr('data-param') || 0;
        const uat = $element.attr('data-refresh');
        const refresh = new Date();
        if (uat) {
            const now = refresh.getTime();
            if (now < uat) {
                return;
            }
        }
        const request = {
            uid: uid,
            fragment: fid,
            parameters: [p1]
        };
        this.queue.push(request);
    }

    start() {
        this.run();
    }
}

window.fragmentManager = new FragmentManager();
$(function () {
    window.fragmentManager.start();
});
