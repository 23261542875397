export function clipboardCopy(options) {
    return new ClipboardCopy(this, options);
}

class ClipboardCopy {

    constructor(element) {
        const $element = $(element);
        let flash = $element.attr('data-success');
        if (!flash) {
            flash = Translator.trans('flash.copied_to_clipboard');
        }
        const target = $element.attr('data-target');
        if (target) {
            const $target = $(target);
            if ($target.is('table')) {
                $target.tableClipboard('copy');
                if (flash) {
                    window.flash.notify(flash, null, 'success');
                }
            }
        } else {
            const value = $element.attr('data-value');
            copyToClipboard(value);
            if (flash) {
                window.flash.notify(flash, null, 'success');
            }
        }
    }

}
