import moment from 'moment-timezone';

export class DeferredControl
{
    constructor($container) {
        this.$container = $container;
        this.$group = $container.find('.action-defer-group');
        this.$group.find('.dropdown-item').off('click').on('click', (event) => this.click(event));
        this.$button = this.$group.find('button');
        this.$buttonCaption = this.$button.find('span');
        this.$button.bootstrapMaterialDatePicker({
            format: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
            minDate: new Date(),
            shortTime: true,
            triggerEvent: 'dblclick'
        }).on('change', (e, date) => {
            const $control = this.$container.find('.action-defer-time');
            this.$button.removeClass('icon-btn');
            this.$buttonCaption.text(date.format('ddd, MMM Do h:mm a'));
            $control.val(date.format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
        });
    }

    reset() {
        const $control = this.$container.find('.action-defer-time');
        $control.val('');
        this.$buttonCaption.empty();
        this.$button.addClass('icon-btn');
    }

    click(event) {
        this.$button.removeClass('icon-btn');
        const $control = this.$container.find('.action-defer-time');
        const $option = $(event.currentTarget);
        if ($option.attr('data-defer-custom') === '1') {
            this.$button.dblclick();
            return;
        }
        if ($option.attr('data-defer-clear') === '1') {
            this.$buttonCaption.text($option.text());
            $control.val('');
            return;
        }
        if ($option.attr('data-defer-automate') === '1') {
            this.$buttonCaption.text($option.text());
            $control.val('*');
            return;
        }
        let time = moment();
        let hour = parseInt($option.attr('data-defer-hour'));
        let day = parseInt($option.attr('data-defer-day'));
        let hours = parseInt($option.attr('data-defer-hours'));
        if (hours) {
            time.add(hours, 'hours');
        }
        if (hour) {
            time.hour(hour);
        }
        if (day) {
            while (time.day() !== day) {
                time.add(1, 'days');
            }
        }
        this.$buttonCaption.text(time.format('ddd, MMM Do h:mm a'));
        $control.val(time.utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
    }

}
