import $ from 'jquery';
import { validateEmail } from '../util/ValidateEmail';

export function emailListControl() {
    $(this).on('beforeItemAdd', (event) => {
        const email = event.item;
        if (!validateEmail(email)) {
            event.cancel = true;
            const $control = $(this).prev();
            $control.popover({
                content: Translator.trans('error.invalid_email_address', { 'email': event.item }),
                placement: 'top',
                trigger: 'manual'
            }).popover('show');
            setTimeout(function () { $control.popover('dispose'); }, 3000);
        }
    }).tagsinput();
}

$(function () {
    $('.email-list-control').emailListControl();
});