//const PDFJS = require('pdfjs-dist');
//window.PDFJS.GlobalWorkerOptions.workerSrc = '/src/lib/pdf.worker.min.js';

import { Paginator } from '../control/Paginator';

export function pdf(options) {
    new PDF(this, options);
    return $(this);
}

class PDF {

    constructor(container, options) {
        this.options = options;
        this.modal = $('#pdf-modal');
        this.modal.find('.modal-title').text(options.title);
        this.modal.find('.action-open').attr('href', options.url);
        this.canvas = document.getElementById('pdf-canvas');
        this.context = this.canvas.getContext('2d');
        this.paginator = new Paginator('pdf-paginator');
        this.paginator.onPageSelect = (page) => this.openPage(page);

        if (window['pdfjs-dist/build/pdf'] === undefined) {
            let script = document.createElement('script');
            script.onload = () => {
                this.PDFJS = window['pdfjs-dist/build/pdf'];
                this.PDFJS.GlobalWorkerOptions.workersrc = '/js/pdf.worker.min.js';
                this.open(options.url);
            };
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', '/js/pdf.min.js');
            document.head.appendChild(script);
        } else {
            this.PDFJS = window['pdfjs-dist/build/pdf'];
            this.open(options.url);
        }
    }

    open(url) {
        this.modal.modal('show');
        this.task = this.PDFJS.getDocument(url);
        this.task.promise.then((pdf) => this.handlePdf(pdf));
    }

    handlePdf(pdf) {
        this.pdf = pdf;
        this.paginator.setPageCount(pdf.numPages);
        this.openPage(1);
    }

    openPage(number) {
        this.pdf.getPage(number).then((page) => this.drawPage(page))
    }

    drawPage(page) {
        this.paginator.setPage(page.pageIndex + 1);
        const pageViewport = page.getViewport(1.0);
        const viewport = page.getViewport(this.modal.width() / page.getViewport(1.0).width);
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;
        this.render = page.render({canvasContext: this.context, viewport: viewport});
    }
}

$(function () {
    $(document).on('click', '.pdf-link', function (e) {
        const url = $(this).attr('href');
        e.preventDefault();
        new PDF(this, {url:url, title: $(this).attr('data-title')});
        return true;
    });
});
