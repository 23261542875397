import $ from 'jquery';
import TableFormat from './TableFormat';
import ChartFormat from './ChartFormat';
import { Cookies } from '../util/Cookies';

export function reportViewer(options) {
    new ReportViewer($(this), options);
}

class ReportViewer
{
    constructor($container, options) {
        this.$container = $container;
        this.options = $.extend(options, {
            url: $container.attr('data-report-dataset-url'),
            exportUrl: $container.attr('data-report-export-url'),
            formatterId: $container.attr('data-report-format'),
            formatType: $container.attr('data-report-format-type'),
        });
        if (options.start) {
            this.load();
        }
        $('.btn-refresh').on('click', () => this.load());
        $('.report-export').on('click', (event) => {
            let href = $(event.currentTarget).attr('data-url');
            href += '&' + this.getVariables();
            window.location.href = href;
        });
    }

    handleDataset(dataset) {
        this.$container.empty();
        switch (this.options.formatterId) {
            case 'TABLE':
                const table = new TableFormat(dataset);
                table.draw(this.$container);
                break;
            case 'LINE':
                const line = new ChartFormat(dataset);
                line.draw(this.$container);
                break;
            case 'BAR':
                const bar = new ChartFormat(dataset);
                bar.draw(this.$container, 'bar');
                break;
        }
    }

    handleError(response) {
        this.$container.empty();
        this.$container.append('<h4 class="mt-5 text-center">Unexpected Error</h4>');
    }

    getVariables() {
        let parameters = [];
        $('.report-variable').each((index, element) => {
            const $element = $(element);
            const name = $element.attr('name');
            if (name) {
                const ref = $element.attr('data-autocomplete-transmit');
                let value = $element.val();
                if (ref) {
                    value = $element.attr(ref);
                }
                parameters.push(name + '=' + encodeURIComponent(value));
            }
        });
        return parameters.join('&');
    }

    downloadReceived(href) {
        this.$container.empty();
        const $a = $('<a></a>', { class: 'ml-2', href }).text(Translator.trans('report.export_complete'));
        const $i = $('<i></i>', { class: 'fa fa-check-circle' });
        const $h = $('<h4></h4>', { class: 'mt-5 text-center' });
        $h.appendTo(this.$container);
        $i.appendTo($h);
        $a.appendTo($h);
    }

    downloadMonitor(token, href, counter) {
        setTimeout(() => {
            const cToken = Cookies.get('DOWNLOAD-TOKEN');
            if (cToken === token) {
                this.downloadReceived(href);
                Cookies.delete('DOWNLOAD-TOKEN');
            } else {
                if (counter > 0) {
                    this.downloadMonitor(token, href, counter - 1);
                }
            }
        }, 1000);
    }

    load() {
        $('.processing-container .waiting').hide();
        $('.processing-container .processing').show();
        if (this.options.formatType === 'FILE') {
            const token = (Math.random() * 1000000).toString(10);
            const href = this.options.exportUrl + '?' + this.getVariables();
            window.location.href = href + '&token=' + token;
            this.downloadMonitor(token, href, 600);
        } else {
            $.ajax(this.options.url + '?' + this.getVariables())
                .done((data) => this.handleDataset(data))
                .fail((response) => this.handleError(response));
        }
    }

}
