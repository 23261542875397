import $ from 'jquery';

export class FormSubmit
{
    static disableSubmission() {
        $('input[type=submit],button[type=submit]').each((index, element) => {
            const $element = $(element);
            let counter = parseInt($element.attr('disable-counter')) || 0;
            counter++;
            $element.attr('disable-counter', counter);
            $element.attr('disabled', 'disabled');
        });
    }

    static enableSubmission() {
        $('input[type=submit],button[type=submit]').each((index, element) => {
            const $element = $(element);
            let counter = parseInt($element.attr('disable-counter')) || 1;
            counter--;
            $element.attr('disable-counter', counter);
            if (counter <= 0) {
                $element.removeAttr('disabled');
            }
        });
    }
}
