/**
 * Datasets have this structure
 *
 * {
 *     columns: [ { type, dataType, caption }... ]
 *     rows: [
 *          [ value, value... ]...
 *     ]
 * }
 *
 */
export class Dataset {

    constructor (dataset) {
        this.dataset = dataset;
        this.columns = dataset.columns;
        this.rows = dataset.rows;
    }

    columnCount() {
        return this.dataset.columns.length;
    }

    rowCount() {
        return this.dataset.rows.length;
    }

    getIndicesByType(type) {
        let output = [];
        for (let i = 0; i < this.dataset.columns.length; i++) {
            if (this.dataset.columns[i].type === type) {
                output.push(i);
            }
        }
        return output;
    }

    getDimensionIndices() {
        return this.getIndicesByType('DIMENSION');
    }

    getMetricIndices() {
        return this.getIndicesByType('METRIC');
    }

    getCombinedDataType(columns) {
        let output = null;
        columns.forEach((index) => {
            let column = this.dataset.columns[index];
            if (output === null) {
                output = column.dataType;
            } else if (output !== column.dataType) {
                output = 'STRING';
            }
        });
        return output;
    }

    getRowKey(keyIndices, row) {
        let output = [];
        keyIndices.forEach((index) => {
            let column = this.dataset.columns[index];
            if (column.type === 'METRIC') {
                output.push(column.caption);
            } else {
                output.push(row[index]);
            }
        });
        return output;
    }

    getAxis(keyIndices, join) {
        let output = [];
        this.dataset.rows.forEach((row) => {
            let key = this.getRowKey(keyIndices, row).join(join);
            if (output.indexOf(key) === -1) {
                output.push(key);
            }
        });
        return output;
    }

}