import $ from 'jquery';
import './CreditCardIssuerControl.scss';

export function creditCardIssuerControl(configuration) {
    new CreditCardIssuerControl(this, configuration);
}

class CreditCardIssuerControl {

    constructor(control, configuration) {
        this.configuration = configuration;
        this.$control = $(control);
        this.$container = this.$control.parent();
        this.$display = this.$container.find('.credit-card-issuer-display');
        this.$cardNumberControl = this.$container.closest('form').find('.credit-card-number');
        this.$cardNumberControl.on('keyup change', () => this.update());
        this.update();
    }

    getConfiguration(issuer) {
        for (let data of this.configuration.issuers) {
            if (data.id === issuer) {
                return data;
            }
        }
        return null;
    }

    draw(issuers, disabled = false) {
        this.$display.empty();
        for (let id of issuers) {
            const data = this.getConfiguration(id);
            const $logo = $('<img/>', {
                src: disabled ? data.logoDisabled : data.logo,
                class: 'credit-card-issuer-logo' + (disabled ? ' disabled' : ''),
            });
            $logo.appendTo(this.$display);
        }
    }

    determine(pan) {
        for (let data of this.configuration.issuers) {
            const regex = new RegExp(data.regex);
            if (regex.test(pan)) {
                return data.id;
            }
        }
        return null;
    }

    update() {
        this.$control.val('');
        const pan = this.$cardNumberControl.val();
        if (pan === '') {
            this.draw(this.configuration.availableIssuers, false);
        } else {
            const issuer = this.determine(pan);
            if (issuer) {
                this.draw([issuer]);
                this.$control.val(issuer);
            } else {
                this.draw(this.configuration.availableIssuers, true);
            }
        }
    }

}
