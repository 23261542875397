import $ from 'jquery';
import 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites';
import 'jsuites/dist/jsuites.css';
import { ConfirmModal } from '../modal/ConfirmModal';

export class RateBookEditor {

    init(url) {
        this.url = url;
        this.$modal = $('#rate-sheet-modal');
        this.$container = this.$modal.find('.rate-sheet-content');
        this.reset();
        this.mode = 'blended';
        this.dirty = false;
        this.$modal.find('.rate-sheet-tabs a').on('shown.bs.tab', (event) => {
            const mode = $(event.target).attr('data-mode');
            this.busy(true);
            this.changeMode(mode);
            this.busy(false);
        })
        this.$saveButton = this.$modal.find('#btn-rate-sheet-save');
        this.saveLadda = Ladda.create(this.$saveButton[0]);
        this.$saveButton.on('click', () => {
            this.fetchData();
            this.saving(true);
            this.save().done(() => $('#rate-sheet-modal').modal('hide')).always(() => this.saving(false));
        });
        this.$modal.find('.btn-close').on('click', (event) => {
            if (this.dirty) {
                new ConfirmModal(event.currentTarget, () => this.$modal.modal('hide'));
            } else {
                this.$modal.modal('hide');
            }
        })
    }

    reset() {
        this.$container.empty();
        this.dirty = false;
        this.$sheet = $('<div></div>').appendTo(this.$container);
    }

    saving(now) {
        if (now) {
            this.saveLadda.start();
            this.$saveButton.addClass('disabled');
        } else {
            this.saveLadda.stop();
            this.$saveButton.removeClass('disabled');
        }
    }

    busy(now) {
        this.$container.toggleClass('busy-indicator', now);
        if (now) {
            this.$saveButton.attr('disabled', 'disabled');
        } else {
            this.$saveButton.removeAttr('disabled');
        }
    }

    dataReceived(data) {
        this.grid = data;
        this.reset();
        this.createEditor();
        $('#rate-sheet-courier-logo').attr('src', data.logo);
        $('#rate-sheet-service-name').text(data.serviceName);
        $('#rate-sheet-modal .ratesheet-label .country').text(data.country);
        $('#rate-sheet-modal .ratesheet-label .zonetype').text(data.zoneType);
        $('#rate-sheet-currency').text(data.currency);
        this.updateIndicators();
    }

    gridEntryToFloat(value) {
        if (value === null || value === '') {
            return null;
        }
        if (value === '-0') {
            return -0.001;
        } else {
            return parseFloat(value);
        }
    }

    floatToGridEntry(value) {
        if (value === null) {
            return '';
        }
        if (value < 0 && value > -0.1) {
            return '-0';
        }
        return value.toFixed(2);
    }

    fetchData() {
        const data = this.spreadsheet.getData();
        if (data === undefined) {
            return;
        }
        if (this.mode === 'blended') {
            const cost = [];
            const list = [];
            for (let y = 0; y < data.length; y++) {
                const costRow = [];
                const listRow = [];
                const row = data[y];
                for (let x = 0; x < row.length; x++) {
                    const value = this.gridEntryToFloat(row[x]);
                    const costValue = value < 0 ? null : value;
                    const listValue = value < 0 ? value : null;
                    costRow.push(costValue);
                    listRow.push(listValue);
                }
                cost.push(costRow);
                list.push(listRow);
            }
            this.grid.data['costPercent'] = cost;
            this.grid.data['listPercent'] = list;
        } else {
            const table = [];
            for (let y = 0; y < data.length; y++) {
                const tableRow = [];
                const row = data[y];
                for (let x = 0; x < row.length; x++) {
                    const value = this.gridEntryToFloat(row[x]);
                    tableRow.push(value);
                }
                table.push(tableRow);
            }
            this.grid.data[this.mode] = table;
        }
    }

    putData() {
        if (this.mode === 'blended') {
            const cost = this.grid.data['costPercent'];
            const list = this.grid.data['listPercent'];
            const output = [];
            for (let y = 0; y < cost.length; y++) {
                const costRow = cost[y];
                const listRow = list[y];
                const row = [];
                for (let x = 0; x < costRow.length; x++) {
                    let value = costRow[x];
                    if (listRow[x] !== null) {
                        value = listRow[x];
                    }
                    row.push(this.floatToGridEntry(value));
                }
                output.push(row);
            }
            this.spreadsheet.setData(output);
        } else {
            const grid = this.grid.data[this.mode];
            const output = [];
            for (let y = 0; y < grid.length; y++) {
                const row = [];
                for (let x = 0; x < grid[y].length; x++) {
                    row.push(this.floatToGridEntry(grid[y][x]));
                }
                output.push(row);
            }
            this.spreadsheet.setData(output);
        }
    }

    loadSheet(sheetId) {
        this.busy(true);
        this.sheetId = sheetId;
        $.ajax(this.url, {
            data: {
                'cmd': 'load',
                'sheetId': sheetId,
            }
        }).done((response) => this.dataReceived(response))
            .done(() => this.dirty = false)
            .always(() => this.busy(false));
    }

    labelBracket(bracket) {
        let text = '';
        if (bracket.pkgc !== null) {
            text += '<small class="badge badge-primary">' + bracket.pkgc + '</small> ';
        }
        if (bracket.pkg !== 'CUST' && bracket.pkg !== '*') {
            text += '<small>' + bracket.pkg.substr(0, 3) + '</small> ';
        }
        text += bracket.upper;
        text += ' <small>' + bracket.uom + '</small>';
        return { title: text };
    }

    createEditor() {
        this.spreadsheet = this.$sheet.jspreadsheet({
            columns: this.grid.shape.zones.map((zid) => ({ title: zid, width: 60 })),
            rows: this.grid.shape.brackets.map((bracket) => this.labelBracket(bracket)),
            contextMenu: false,
            allowInsertRow: false,
            allowManualInsertRow: false,
            allowInsertColumn: false,
            allowManualInsertColumn: false,
            allowDeleteRow: false,
            allowDeleteColumn: false,
            autoIncrement: false,
            rowDrag: false,
            rowResize: false,
            onbeforechange: (element, originalValue, x, y, newValue) =>
                this.onCellChange(element, originalValue, x, y, newValue)
        });
        // seems to be a problem with jspreadsheet not allowing header column width changing
        this.$container.find('colgroup col:first-child').attr('width', 120);
        this.putData();
    }

    changeMode(newMode) {
        if (newMode !== this.mode) {
            this.clearError();
            this.busy(true);
            this.fetchData();
            this.mode = newMode;
            this.putData();
            this.updateIndicators();
            this.busy(false);
        }
    }

    updateIndicators() {
        $('.rate-sheet-tabs a span.badge').each((index, element) => {
            const mode = $(element).parent().attr('data-mode');
            let count = 0;
            let values = 0;
            if (this.grid.data[mode]) {
                this.grid.data[mode].forEach((row) => row.forEach((value) => {
                    count++;
                    if (value !== null) {
                        values++;
                    }
                }));
                const percent = Math.floor((values / count) * 100);
                if (values === 0) {
                    $(element).text('');
                } else {
                    if (percent > 10) {
                        $(element).text(percent + '%');
                    } else {
                        $(element).text(values);
                    }
                }
            }
        });
    }

    save() {
        return $.ajax(this.url, {
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ cmd: 'save', sheetId: this.sheetId, grids: this.grid.data })
        }).done(() => this.dirty = false);
    }

    showError() {
        this.$modal.find('.instruction').hide();
        this.$modal.find('.alert').show();
    }

    clearError() {
        this.$modal.find('.instruction').show();
        this.$modal.find('.alert').hide();
    }

    onCellChange(element, originalValue, x, y, newValue) {
        if (newValue === "\u0000") {
            newValue = '';
        }
        const f = this.gridEntryToFloat(newValue);
        const rx = new RegExp(/^[+\-]?[0-9]*\.?[0-9]*$/);
        let error = newValue !== '' && !rx.test(newValue);
        if (!error) {
            switch (this.mode) {
                case 'blended':
                    break;
                case 'listPercent':
                    error = f >= 0;
                    break;
                default:
                    error = f < 0;
                    break;
            }
        }
        if (error) {
            this.showError();
            return originalValue.textContent;
        } else {
            this.clearError();
            this.dirty = true;
            return this.floatToGridEntry(f);
        }
    }

    summaryChange(input) {
        const $input = $(input);
        const $entry = $input.closest('.ratesheet-entry');
        if ($entry.hasClass('none')) {
            $input.val($input.attr('data-value'));
            return;
        }
        const sheetId = $entry.attr('data-sheet-id');
        const value = $input.val();
        $entry.addClass('busy-indicator');
        $.ajax(this.url, {
            data: { cmd: 'set', sheetId, value }
        }).done((response) => {
            if (response.classes) {
                $entry.removeClass();
                $entry.addClass('ratesheet-entry');
                $entry.addClass(response.classes);
            }
            if (response.value) {
                $input.val(response.value);
                $input.attr('data-value', response.value);
            }
            if (response.flash) {
                window.flash.info(response.flash);
            }
        }).always(() => {
            $entry.removeClass('busy-indicator');
        }).fail((message) => {
            window.flash.error(message);
            $input.val($input.attr('data-value'));
        });
    }

    activationChange(input) {
        const $entry = $(input).closest('.ratesheet-entry');
        const sheetId = $entry.attr('data-sheet-id');
        const active = $(input).prop('checked');
        $entry.addClass('busy-indicator');
        $.ajax(this.url, {
            data: { cmd: active ? 'create': 'delete', sheetId }
        }).done((response) => {
            if (response.classes) {
                $entry.removeClass();
                $entry.addClass('ratesheet-entry');
                $entry.addClass(response.classes);
            }
            if (response.value) {
                $entry.find('input[type=text]').val(response.value);
            }
            if (response.flash) {
                window.flash.info(response.flash);
            }
        }).fail(() => {
            $(input).prop('checked', !active);
        }).always(() => {
            $entry.removeClass('busy-indicator');
        });
    }

}
