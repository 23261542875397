import '../../assets/vendor/libs/dragula/dragula.css';
import '../../assets/vendor/libs/dragula/dragula.js';

export function dashboardEditor(options) {
    return new DashboardEditor(this, options);
}

class DashboardEditor
{

    constructor (container, options) {
        this.$container = $(container);
        this.options = options['options'];
        this.history = [];
        this.state = options['configuration'];
        this.returnUrl = options['returnUrl'];
        this.future = [];
        this.$undoButton = $(options['undoButton']);
        this.$undoButton.attr('disabled', 'disabled');
        this.$undoButton.on('click', () => this.undo());
        this.$redoButton = $(options['redoButton']);
        this.$redoButton.attr('disabled', 'disabled');
        this.$redoButton.on('click', () => this.redo());
        this.$addButtons = $(options['addButtons']);
        this.$addButtons.on('click', (event) => {
            const id = $(event.currentTarget).attr('data-id');
            this.addComponent(id);
        });
        this.$submitButton = $(options['submitButton']);
        this.$submitButton.on('click', () => this.submit());
        this.$nameControl = $(options['nameControl']);
        this.draw();
        dragula([this.$container[0]]).on('dragend', () => this.updateOrdering());
    }

    getComponentDefinition(id) {
        return this.options.components.find((component) => component.id === id);
    }

    pushState() {
        this.history.push(JSON.parse(JSON.stringify(this.state)));
        this.$undoButton.removeAttr('disabled');
        this.future = [];
        this.$redoButton.attr('disabled', 'disabled');
    }

    undo() {
        this.future.push(JSON.parse(JSON.stringify(this.state)));
        this.$redoButton.removeAttr('disabled');
        this.state = JSON.parse(JSON.stringify(this.history.pop()));
        if (this.history.length === 0) {
            this.$undoButton.attr('disabled', 'disabled');
        }
        this.draw();
    }

    redo() {
        this.history.push(JSON.parse(JSON.stringify(this.state)));
        this.state = JSON.parse(JSON.stringify(this.future.pop()));
        this.$undoButton.removeAttr('disabled');
        if (this.future.length === 0) {
            this.$redoButton.attr('disabled', 'disabled');
        }
        this.draw();
    }

    addComponent(componentId) {
        this.pushState();
        const component = { uid: 'c' + Date.now(), id: componentId };
        this.state.push(component);
        this.draw();
    }

    draw() {
        this.$container.empty();
        for (let i = 0; i < this.state.length; i++) {
            this.drawComponent(this.$container, this.state[i], this.options);
        }
    }

    drawComponent($container, configuration) {
        const uid = configuration['uid'];
        const componentId = configuration['id'];
        let componentOptions = this.getComponentDefinition(componentId);
        if (componentOptions === undefined) {
            return;
        }
        const size = configuration['size'] ? configuration['size'] : '4';
        let xlSize = Math.floor((size * 2) / 3);

        const $component = $(`
            <div class="dashboard-component col-sm-${size} col-xl-${xlSize} mb-4" data-uid="${uid}">
              <div class="card">
                <div class="card-header with-elements">
                  <div class="card-header-title">${componentOptions['caption']}</div>
                  <div class="card-header-elements ml-auto">
                    <button class="btn btn-xs btn-outline-secondary icon-btn borderless btn-delete-component">×</button>
                  </div>
                </div>
                <div class="card-body">                
                </div>
              </div>
            </div>
        `).appendTo($container);
        $component.find('.btn-delete-component').on('click', () => this.deleteComponent(uid));
        const $body = $component.find('.card-body');

        for (let i = 0; i < componentOptions['options'].length; i++) {
            const propertyOptions = componentOptions['options'][i];
            this.drawComponentProperty($body, uid, configuration[propertyOptions['id']], propertyOptions);
        }
    }

    drawComponentProperty($container, uid, currentValue, options) {
        const $group = $('<div></div>', {
            'class': 'form-group'
        }).appendTo($container);
        switch (options['type']) {
            case 'choice':
                this.drawPropertyChoice($group, uid, currentValue, options);
                break;
        }
    }

    drawPropertyChoice($container, uid, currentValue, options) {
        $('<label></label>', {
            'class': 'form-label'
        }).text(options['caption']).appendTo($container);
        const $select = $('<select></select>', {
            'class': 'form-control'
        }).appendTo($container);
        for (let i = 0; i < options['options'].length; i++) {
            const option = options['options'][i];
            const $option = $('<option></option>', {
                'value': option.id
            }).text(option.caption).appendTo($select);
            if (option.id === currentValue) {
                $option.attr('selected', 'selected');
            }
        }
        $select.on('change', () => {
            this.changeComponent(uid, options['id'], $select.val());
        })
    }

    deleteComponent(uid) {
        this.pushState();
        this.state = this.state.filter((component) => component.uid !== uid);
        this.draw();
    }

    changeComponent(uid, property, newValue) {
        this.pushState();
        this.state.map((component) => {
            if (component.uid === uid) {
                component[property] = newValue;
            }
        });
    }

    updateOrdering() {
        this.pushState();
        const newState = [];
        $('.dashboard-component').each((index, element) => {
            const uid = $(element).attr('data-uid');
            this.state.map((component) => {
                if (component.uid === uid) {
                    newState.push(component);
                }
            });
        });
        this.state = JSON.parse(JSON.stringify(newState));
        this.draw();
    }

    submit() {
        $.ajax('', {
            method: 'POST',
            data: { data: this.state, caption: this.$nameControl.val() },
            success: (response) => {
                if (response.success) {
                    window.location.href = this.returnUrl + '?db=' + response.id;
                } else if (response.error) {
                    flash.error(response.error);
                }
            },
            error: (xhr, status) => {
                flash.error(status);
            }
        })
    }
}
