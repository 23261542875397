/*
 * status.js
 *
 * Manage status updates from server to display on screen
 */

import '../../assets/vendor/libs/idletimer/idletimer';

import { ViewModal } from '../modal/ViewModal';
import moment from 'moment-timezone';
import { EventSourcePolyfill } from 'event-source-polyfill';

class StatusManager {

    constructor () {
        this.objectHash = null;
        this.progressHash = null;
        this.pageUser = null;
        this.currentUser = null;
        this.route = null;
        this.routeParameters = null;
        this.lastUpdate = null;
        this.timerActive = null;
        this.reloadIn = 30;
        this.data = null;
        this.completedIds = [];
        this.completionCallbacks = {};
        this.anyCompletionCallbacks = [];
        this.flashCompleted = false;

        $(document).on('click', '.task-link', (event) => {
            this.statusLinkClicked(event);
        });
    }

    reloadViews() {
        const $container = $('.user-views-container');
        const url = $container.attr('data-reload-url');
        $.ajax(url).then((html) => $container.html(html));
    }

    taskCompleted(task) {
        if (this.completedIds.indexOf(task.id) === -1) {
            this.completedIds.push(task.id);
            if (this.completionCallbacks.hasOwnProperty(task.id)) {
                let callback = this.completionCallbacks[task.id];
                if (callback) {
                    callback(task);
                }
                this.completionCallbacks[task.id] = null;
            }
            for (let i = 0; i < this.anyCompletionCallbacks.length; i++) {
                this.anyCompletionCallbacks[i](task);
            }
        }
    }

    flashTask(task) {
        flash.notify(
            task.statusText,
            task.subject,
            task.status === 'ERROR' ? flash.errorType : flash.successType,
            () => {
                if (task.output === 'PAGE') {
                    window.location.href = task.link;
                } else {
                    const modal = new ViewModal();
                    modal.open(task.link);
                }
            }
        );
    }

    onTaskCompletion(id, callback) {
        if (id) {
            this.completionCallbacks[id] = callback;
        } else {
            this.anyCompletionCallbacks.push(callback);
        }
        if (this.data) {
            for (let i = 0; i < this.data.tasks.length; i++) {
                const task = this.data.tasks[i];
                if (task.id === id && task.completed) {
                    setTimeout(() => callback(task), 0);
                }
            }
        }
    }

    getTask(id) {
        if (this.data) {
            for (let i = 0; i < this.data.tasks.length; i++) {
                const task = this.data.tasks[i];
                if (task.id === id) {
                    return task;
                }
            }
        }
        return null;
    }

    statusLinkClicked(event) {
        const $source = $(event.currentTarget);
        let id = $source.attr('data-channel');
        if (id) {
            const task = this.getTask(id);
            if (task) {
                if (task.output === 'PAGE') {
                    window.location.href = task.link;
                } else {
                    const modal = new ViewModal();
                    modal.open(task.link);
                }
            }
        }
    }

    showChannelStatus(url) {
        const modal = new ViewModal();
        modal.open(url);
    }

    drawAvatar(user, size) {
        return '<img class="d-block ui-h-' + size + ' ui-w-' + size + ' rounded-circle" src="' + user.avatar + '">';
    };

    unlock() {
        $.unblockUI();
    };

    drawUserComponent(user, size) {
        return '<div class="d-flex align-items-center justify-content-center">' +
            this.drawAvatar(user, size) +
            '<div class="d-flex align-items-start flex-column ml-4"><div>' + user.fullName + '</div><div class="text-muted">' + user.username + '</div></div>' +
            '</div>';
    };

    userChanged(switched) {
        $('#current-user-avatar').src = this.currentUser.avatar;
        $('#current-user-username').text = this.currentUser.username;
        if (switched) {
            $.blockUI({
                message: '<div class="d-flex flex-column justify-content-center p-4" style="background-color: #fff; box-shadow: 0 0 30px rgba(0,0,0,0.75);">' +
                    this.drawUserComponent(this.currentUser, 40) + '<div>' +
                    '<div class="mt-2 mb-2">' + Translator.trans('status.user_change_detected') + '</div>' +
                    '<div class="d-flex justify-content-center">' +
                    '<button onclick="javascript:window.location.reload(true);" class="btn btn-primary mr-1">' +
                    Translator.trans('status.page_reload') +
                    '</button>' +
                    '<button onclick="javascript:statusManager.unlock();" class="btn btn-warning ml-1">' +
                    Translator.trans('status.page_unlock') +
                    '</button>' +
                    '</div>',
                css: {
                    backgroundColor: 'transparent',
                    border: '0',
                    zIndex: 9999999
                },
                overlayCSS:  {
                    backgroundColor: '#000',
                    opacity: 0.6,
                    zIndex: 9999990
                }
            });
        } else {
            this.unlock();
        }
    };

    activity() {
        if (this.timerActive) {
            clearTimeout(this.timerActive);
            this.timerActive = null;
        }
        //this.update();
        //this.reloadIn = 1;
    };

    startListening(url, token, topics) {
        return;
        const eventUrl = new URL(url);
        for (let topic of topics) {
            eventUrl.searchParams.append('topic', topic);
        }
        this.eventSource = new EventSourcePolyfill(eventUrl.toString(), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        this.eventSource.onmessage = ({ data }) => {
            this.messageReceived(JSON.parse(data));
        }
    }

    startWebSocket(url) {
        this.webSocket = new WebSocket(url);
        this.webSocket.addEventListener('close', (event) => {
            this.webSocket = null;
            this.startWebSocket(url);
        });
        this.webSocket.addEventListener('error', (event) => {
            console.log(event);
            this.webSocket = null;
            this.startWebSocket(url);
        });
        this.webSocket.addEventListener('message', (event) => {
            const payload = JSON.parse(event.data);
            if (payload.type === 'ACTION_UPDATE') {
                const action = payload.action;
                this.actionUpdateReceived(action);
            }
        });
    }

    messageReceived(data) {
        console.log('HUB', data);
        // need enough time to store the status in DynamoDB
        // TODO: transmit all status data in message to eliminate this
        setTimeout(() => this.update(), 1000);
    }

    updateTaskFromAction(task, action) {
        if (action.title) {
            task.title = action.title;
        }
        if (action.subTitle) {
            task.statusText = action.subTitle;
        }
        if (task.status !== action.status) {
            task.status = action.status;
            switch (action.status) {
                case 'COMPLETED':
                    task.status = action.status;
                    task.color = 'success';
                    task.completed = true;
                    task.progress = null;
                    break;
                case 'ERROR':
                    task.status = action.status;
                    task.color = 'danger';
                    task.completed = true;
                    task.progress = null;
                    break;
                case 'PROCESSING':
                    if (task.status === 'PENDING' || task.status === null) {
                        task.color = 'primary';
                        task.completed = false;
                        task.progress = action.progress;
                    }
                    break;
                case 'PENDING':
                    if (task.status === null) {
                        task.color = 'info';
                        task.completed = false;
                    }
                    break;
            }
            return true;
        } else {
            if (action.status === 'PROCESSING' && action.progress >= task.progress) {
                task.progress = action.progress;
            }
        }
        return false;
    }

    createTaskFromAction(action) {
        const timestamp = action.createdAt ? new Date(action.createdAt) : new Date();
        const task = {
            icon: 'fa fa-clipboard',
            status: null,
            color: 'info',
            category: 'DEFAULT',
            completed: false,
            id: action.channel,
            channel: action.channel,
            link: '/channel/' + action.channel + '/status',
            progress: action.progress ? action.progress : 0,
            statusText: '',
            subject: action.title ? action.title : Translator.trans('action.' + action.actionId.toLowerCase() + '.title'),
            timestamp: timestamp.getTime() / 1000,
        };
        this.updateTaskFromAction(task, action);
        return task;
    }

    actionUpdateReceived(action) {
        if (this.data) {
            let drawObjects = false;
            let matched = false;
            for (let i = 0; i < this.data.tasks.length; i++) {
                const task = this.data.tasks[i];
                if (task.channel === action.channel) {
                    matched = true;
                    /* eliminate out of order messages */
                    if (task.status === 'COMPLETED' || task.status === 'ERROR') {
                        return;
                    }
                    if (task.status === 'PARTIAL' && action.status !== 'COMPLETED' && action.status !== 'ERROR') {
                        return;
                    }
                    if (action.status === 'PENDING') {
                        return;
                    }
                    if (task.status === 'PROCESSING' && action.status === 'PROCESSING' && task.progress && task.progress > action.progress) {
                        return;
                    }
                    drawObjects = this.updateTaskFromAction(task, action);
                    break;
                }
            }
            if (!matched) {
                const task = this.createTaskFromAction(action);
                this.data.tasks = [ task, ...this.data.tasks ];
                drawObjects = true;
            }
            this.data.tasksPending = 0;
            for (let i = 0; i < this.data.tasks.length; i++) {
                const task = this.data.tasks[i];
                if (!task.completed) {
                    this.data.tasksPending++;
                }
            }
            if (drawObjects) {
                this.drawObjects(this.data);
            }
            this.drawProgress(this.data);
        }
    }

    drawObjects(data) {
        const $tasksHeaderContainer = $('#tasks-header-container');
        const $tasksHeaderComplete = $('#tasks-header-indicator-complete');
        const $tasksHeaderPending = $('#tasks-header-indicator-pending');
        const $tasksHeaderIndicatorCount = $('#tasks-header-indicator-count');
        const $tasksHeaderSection = $('#tasks-header-section');
        const $tasksHeaderMenu = $('#tasks-header-menu');
        const $tasksSidebarInactive = $('#tasks-sidebar-inactive');
        const $tasksSidebarContainer = $('#tasks-sidebar-container');
        $tasksHeaderContainer.toggle(data.tasks.length > 0);
        $tasksHeaderComplete.toggle(data.tasksPending === 0);
        $tasksHeaderPending.toggle(data.tasksPending > 0);
        $tasksHeaderSection.toggle(data.tasksPending > 0);
        $tasksHeaderSection.text(Translator.trans('status.pending_tasks_counter', { 'count': data.tasksPending }));
        $tasksHeaderIndicatorCount.text(data.tasksPending).toggle(data.tasksPending > 0);
        $tasksHeaderMenu.empty();
        if (data.tasks.length > 0) {
            if (data.tasksPending > 0) {
                $tasksSidebarInactive.hide();
            }
            for (let i = 0; i < data.tasks.length; i++) {
                const task = data.tasks[i];
                if (task.completed) {
                    this.taskCompleted(task);
                }
                let $sideItem = $('#sidebar_' + task.id);
                if ($sideItem.length === 0 && !task.completed) {
                    $sideItem = $('<div></div>')
                        .appendTo($tasksSidebarContainer)
                        .attr('id', 'sidebar_' + task.id)
                        .addClass('sidenav-item');
                }
                $sideItem.empty();
                const $sideItemLink = $('<a></a>')
                    .attr('href', task.link ? task.link : 'javascript:void(0)')
                    .attr('data-channel', task.id)
                    .addClass('sidenav-link task-link')
                    .appendTo($sideItem);
                $('<i></i>')
                    .addClass(task.icon)
                    .addClass('sidenav-icon')
                    .appendTo($sideItemLink);
                const $sideItemText = $('<div></div>')
                    .addClass('w-100')
                    .appendTo($sideItemLink);
                const $sideItemSubject = $('<div></div>')
                    .addClass('small')
                    .text(task.subject)
                    .appendTo($sideItemText);
                if (task.statusText) {
                    $('<div></div>')
                        .addClass('text-muted')
                        .text(task.statusText);
                }
                const $sideProgress = $('<div></div>')
                    .addClass('progress')
                    .css('height', '6px')
                    .appendTo($sideItemText);
                $('<div></div>')
                    .addClass('progress-bar bg-primary')
                    .addClass('task-progressbar-' + task.id)
                    .appendTo($sideProgress);
                $('<span></span>')
                    .addClass('float-right')
                    .text(task.progress ? (Math.round(task.progress) + '%') : '')
                    .addClass('task-progress-' + task.id)
                    .appendTo($sideItemSubject);
                const $item = $('<a></a>')
                    .addClass('list-group-item list-group-item-action media d-flex align-items-center task-link')
                    .attr('data-channel', task.id)
                    .attr('href', 'javascript:void(0)')
                    .appendTo($tasksHeaderMenu);
                $('<div></div>')
                    .addClass('ui-icon ui-icon-sm border-0 text-white')
                    .addClass(task.icon)
                    .addClass('bg-' + task.color)
                    .appendTo($item);
                const $content = $('<div></div>')
                    .addClass('media-body line-height-condenced ml-3')
                    .appendTo($item);
                const timestamp = moment.unix(task.timestamp);
                $('<small></small>', { 'class': 'float-right text-muted' })
                    .text(timestamp.fromNow())
                    .appendTo($content);
                $('<div></div>')
                    .addClass('text-dark')
                    .text(task.subject)
                    .appendTo($content);
                if (task.statusText) {
                    $('<div></div>')
                        .addClass('text-light small mt-1')
                        .text(task.statusText)
                        .appendTo($content);
                }
                if (task.progress !== null) {
                    const $progress = $('<div></div>')
                        .addClass('progress')
                        .css('height', '6px')
                        .appendTo($content);
                    $('<div></div>')
                        .addClass('progress-bar bg-' + task.color)
                        .addClass('task-progressbar-' + task.id)
                        .css('width', task.progress + '%')
                        .appendTo($progress);
                }
            }
        }
        this.updateTagsMenu(data.recentTags);
    }

    drawProgress(data) {
        for (let i = 0; i < data.tasks.length; i++) {
            const task = data.tasks[i];
            if (task.statusText) {
                $('.task-status-text-' + task.id).text(task.statusText);
            }
            if (task.status === 'PENDING' || task.status === 'PROCESSING') {
                this.notifyProgressReporters(task.id, task.progress);
            } else {
                $('.task-progressbar-' + task.id).parent().hide();
                $('.task-progress-' + task.id).hide();
            }
        }
    }

    processData(data) {
        if (data) {
            if (this.data === null) {
                this.startWebSocket(data.journal.messageUrl);
                //this.startListening(data.hubUrl, data.hubToken, [data.hubUserTopic, data.hubOrganizationTopic]);
            }
            this.data = data;
            this.flashCompleted = data.flashCompleted;
            this.lastUpdate = Math.round((new Date()).getTime() / 1000);
            this.reloadIn = Math.min(this.reloadIn + 1, data.reloadIn);
            if (this.pageUser === null) {
                this.pageUser = data.user;
            }
            let uid = this.currentUser ? this.currentUser.id : null;
            this.currentUser = data.user;
            if (uid !== this.currentUser.id) {
                this.userChanged(this.pageUser.id !== this.currentUser.id);
            }
        }
        // this.timerActive = setTimeout(() => { this.update(); }, this.reloadIn * 1000);
        if (data && (this.objectHash !== data.objectHash)) {
            this.objectHash = data.objectHash;
            this.drawObjects(data);
        }
        if (data) {
            if (this.progressHash !== data.progressHash) {
                this.progressHash = data.progressHash;
                this.drawProgress(data);
            }
        }
    };

    notifyProgressReporters(id, progress) {
        progress = Math.round(progress);
        $('.task-progressbar-' + id).each((index, element) => {
            const $element = $(element);
            const current = $element.attr('data-progress') ? parseInt($element.attr('data-progress')) : 0;
            progress = Math.max(current, progress);
            $element.attr('data-progress', progress);
            if (progress > 0) {
                $element.css('width', progress + '%');
            }
        });
        $('.task-progress-' + id).each((index, element) => {
            const $element = $(element);
            const current = $element.attr('data-progress') ? parseInt($element.attr('data-progress')) : 0;
            progress = Math.max(current, progress);
            $element.attr('data-progress', progress);
            if (progress > 0) {
                $element.text(progress + '%');
            }
        });
    }

    update() {
        const lastActive = Math.round($(document).idleTimer("getLastActiveTime") / 1000);
        if (lastActive < (this.lastUpdate - 60)) {
            this.processData(null);
        } else {
            Pace.ignore(() => {
                $.ajax({
                    url: '/ajax/user_status',
                    success: (data) => { this.processData(data); },
                    error: () => { this.processData(null); }
                });
            });
        }
    }

    updateTagsMenu(recentTags) {
        const $container = $('#tags-header-container');
        const $menu = $('#tags-header-menu');
        const $counter = $('#tags-header-indicator-completion');
        $menu.empty();
        let total = 0;
        let completed = 0;
        for (let i = 0; i < recentTags.length; i++) {
            const tag = recentTags[i];
            let icon = 'fa fa-tag';
            switch (tag['type']) {
                case 'SHIPMENT':
                    icon = 'fa fa-fw fa-box-open';
                    break;
                case 'ORGANIZATION':
                    icon = 'fa fa-fw fa-building';
                    break;
                case 'USER':
                    icon = 'fa fa-fw fa-user';
                    break;
                default:
                    icon = 'fa fa-fw fa-tag';
                    break;
            }
            const $item = $(`
                <a class="dropdown-item d-flex" href="${tag.url}">
                    <div class="tag-icon">
                        <i class="text-lightest mr-1 ${icon}"></i>
                    </div>
                    <div class="tag-detail d-flex flex-column" style="flex: 1;">
                        <div class="tag-name">${tag.name}</div>
                        <div class="tag-progress d-flex flex-row align-items-center">
                            <div class="progress" style="flex: 1; height: 6px;">
                                <div class="progress-bar"></div>                            
                            </div>                        
                            <div class="tag-indicator small text-muted ml-1">
                            
                            </div>
                        </div>                     
                    </div>
                </a>
            `).appendTo($menu);
            if (tag['isProgress'] && tag['progress']['count'] > 0) {
                const progress = Math.round((tag['progress']['completed'] / tag['progress']['count']) * 100);
                $item.find('.progress-bar').css('width', progress + '%');
                $item.find('.tag-indicator').text(tag['progress']['completed'] + ' / ' + tag['progress']['count']);
                total += tag['progress']['count'];
                completed += tag['progress']['completed'];
            } else {
                $item.find('.tag-progress').removeClass('d-flex').hide();
            }
        }
        $container.toggle(recentTags.length > 0);
        if (total > 0) {
            const progress = Math.round((completed / total) * 100);
            $counter.text(progress + '%').show();
        } else {
            $counter.hide();
        }
    }

}

window.statusManager = new StatusManager();

$(function () {
    $(document).idleTimer(10000);
});
