import $ from 'jquery';
import { ConfirmModal } from '../modal/ConfirmModal';

$(function () {
    $(document).on('click', '.btn-confirm', (event) => {
        const $button = $(event.currentTarget);
        new ConfirmModal(event.currentTarget, () => {
            $button.removeClass('btn-confirm');
            event.currentTarget.click();
            $button.addClass('btn-confirm');
            if ($button.hasClass('btn-lock')) {
                const ladda = Ladda.create(event.currentTarget);
                ladda.start();
                $button.attr('disabled', 'disabled');
            }
        });
        return false;
    });
});
