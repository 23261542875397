import $ from 'jquery';
import 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites';
import 'jsuites/dist/jsuites.css';
import './RateBookEditor.scss'
import {Paginator} from "../control/Paginator";

export class BatchEditor {

    init(url, selector, columns, groups, paginator, rowCount, pageSize) {
        this.$container = $(selector);
        this.$page = this.$container.closest('.layout-content');
        this.url = url;
        this.columns = columns;
        this.groups = groups;
        this.changesPending = 0;
        this.changeQueue = [];
        this.errorMessages = [];
        this.$errorContainer = $('#batch-errors');
        this.$search = $('#batch-search input');
        this.$search.on('paste keyup', () => {
            const terms = this.$search.val();
            if (terms === '') {
                this.spreadsheet.resetSearch();
            } else {
                this.spreadsheet.search(terms);
            }
        });
        this.rowCount = rowCount;
        this.pageSize = pageSize;
        this.pageCount = Math.ceil(rowCount / pageSize);
        this.paginator = new Paginator(paginator, this.pageCount);
        this.pageNumber = 1;
        if (this.paginator) {
            this.paginator.onPageSelect = (pageNumber) => {
                this.pageNumber = pageNumber;
                this.load();
                this.paginator.setPage(pageNumber);
            }
        }

        this.spreadsheet = this.$container.jspreadsheet({
            columns: columns,
            nestedHeaders: this.groups.length ? [this.groups] : null,
            contextMenu: false,
            freezeColumns: 1,
            defaultColWidth: 120,
            allowInsertColumn: false,
            allowDeleteColumn: false,
            allowInsertRow: false,
            allowDeleteRow: false,
            allowManualInsertColumn: false,
            allowReorder: false,
            autoIncrement: false,
            //lazyLoading: true,
            //filters: true,
            tableOverflow: true,
            tableWidth: '100%',
            tableHeight: 'calc(100vh - 160px)',
            onundo: (a, b, c, d) => {
                //console.log(a, b, c, d);
            },
            onbeforechange: (element, originalValue, x, y, newValue) =>
                this.onChange(element, originalValue, x, y, newValue),
            onchange: (instance, cell, x, y, value) => {
                //console.log('onchange: ' + value + '/' + this.spreadsheet.getValueFromCoords(0, y));
            },
            onafterchanges: (parameter) => {
                //console.log('onafterchanges:', parameter);
                this.onAfterChanges();
            }
        });

        this.load();
    }

    updateProperty(shipmentId, property, value, previous) {
        if (value === true) {
            value = '1';
        } else if (value === false) {
            value = '0';
        }
        this.changeQueue.push({ action: 'update', shipmentId, property, value, previous });
    }

    getCellNameFromCoords(x, y) {
        let c = String.fromCharCode(64 + (x % 26));
        if (x >= 26) {
            c = String.fromCharCode(64 + Math.floor(x / 26)) + c;
        }
        return c + y;
    }

    getRowFromShipment(shipmentNumber) {
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i][0] === shipmentNumber) {
                return i + 1;
            }
        }
        return null;
    }

    getColumnFromProperty(propertyId) {
        for (let i = 0; i < this.columns.length; i++) {
            if (this.columns[i].name === propertyId) {
                return i + 1;
            }
        }
        return null;
    }

    getCellNameFromReference(shipmentNumber, propertyId) {
        return this.getCellNameFromCoords(this.getColumnFromProperty(propertyId),
            this.getRowFromShipment(shipmentNumber));
    }

    setSheetCell(shipmentId, property, value) {
        let y = null;
        for (let i = 0; i < this.columns.length; i++) {
            if (this.columns[i].name === property) {
                for (let j = 0; j < this.data.length; j++) {
                    if (this.data[j][0] === shipmentId) {

                    }
                }
            }
        }
    }

    highlight(shipmentId, propertyId, state) {
        const ref = this.getCellNameFromReference(shipmentId, propertyId);
        const existing = this.spreadsheet.getStyle(ref, 'background-color');
        const updated = state ? 'yellow' : '';
        if (existing !== updated) {
            this.spreadsheet.setStyle(ref, 'background-color', 'yellow');
        }
    }

    reverseChanges(entries) {

    }

    addError(error) {
        for (let i = 0; i < this.errorMessages.length; i++) {
            if (this.errorMessages[i].text === error.text) {
                return;
            }
        }
        this.highlight(error.shipment, error.property, true);
        this.errorMessages.push(error);
    }

    displayErrors() {
        this.$errorContainer.empty();
        if (this.errorMessages.length === 0) {
            this.$errorContainer.hide();
        } else {
            this.$errorContainer.show();
            const $btn = $('<a></a>', { 'href': '#', 'class': 'btn btn-danger btn-xs dropdown-toggle', 'data-toggle': 'dropdown' }).appendTo(this.$errorContainer);
            const $menu = $('<div></div>', { 'class': 'dropdown-menu' }).appendTo(this.$errorContainer);
            for (let i = 0; i < this.errorMessages.length; i++) {
                const error = this.errorMessages[i];
                if (i === 0) {
                    $btn.text(error.message);
                }
                const $link = $('<a></a>', { 'class': 'dropdown-item error-link', 'href': '#' }).appendTo($menu);
                $link.attr('data-shipment', error.shipment);
                $link.attr('data-property', error.property);
                $link.text(error.text);
            }
        }
    }

    processErrors(errors) {
        for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            error.text = error.shipment + ' - ' + error.caption + ': ' + error.message;
            this.addError(errors[i]);
        }
        this.displayErrors();
    }

    clearError(shipmentId, propertyId) {
        this.errorMessages = this.errorMessages.filter((error) =>
            error.shipment !== shipmentId && error.property !== propertyId);
        this.displayErrors();
    }

    savePendingChanges() {
        if (this.changeQueue.length) {
            $('#batch-saving').show();
            this.changesPending++;
            const changes = {};
            const deletions = [];
            const queue = this.changeQueue;
            this.changeQueue = [];
            queue.forEach(function (entry) {
                if (entry.action === 'update') {
                    if (!changes[entry.shipmentId]) {
                        changes[entry.shipmentId] = {};
                    }
                    changes[entry.shipmentId][entry.property] = entry.value;
                }
                if (entry.action === 'delete') {
                    deletions.push(entry.shipmentId);
                }
            });
            $.ajax(this.url, {
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json; charset=UTF-8',
                data: JSON.stringify({
                    cmd: 'update',
                    changes,
                    deletions
                })
            }).done((response) => {
                this.processErrors(response.errors);
                console.log(response);
            }).always(() => {
                this.changesPending--;
                if (this.changesPending === 0) {
                    $('#batch-saving').hide();
                }
            });
        }
    }

    onChange(element, originalValue, x, y, newValue) {
        const shipmentId = this.spreadsheet.getValueFromCoords(0, y);
        const propertyId = this.columns[x].name;
        if (originalValue !== newValue) {
            this.updateProperty(shipmentId, propertyId, newValue, originalValue);
            this.highlight(shipmentId, propertyId, false);
            this.clearError(shipmentId, propertyId);
        }
        console.log('onbeforechange: ' + newValue + '/' + this.spreadsheet.getValueFromCoords(0, y));
    }

    onAfterChanges() {
        this.savePendingChanges();
    }

    load() {
        this.$page.addClass('busy-indicator');
        $.ajax(this.url, {
            data: { 'cmd': 'load', 'start': (this.pageNumber - 1) * this.pageSize, 'rows': this.pageSize }
        }).done((data) => {
            this.data = data;
            this.spreadsheet.setData(data);
        }).always(() => {
            this.$page.removeClass('busy-indicator');
        });
    }

}
