import TableBuilder from '../table/TableBuilder';
import Currency from '../util/Currency';
import $ from 'jquery';
import moment from 'moment-timezone';

export default class TableFormat
{
    constructor(dataset) {
        this.dataset = dataset;
        this.builder = new TableBuilder();
    }

    draw($container) {
        let dataTypes = [];
        this.dataset.columns.forEach((column) => {
            this.builder.addHeaderColumn(column.caption, column.dataType);
            this.builder.addFooterColumn('', column.dataType);
            dataTypes.push(column.dataType);
        });
        this.dataset.rows.forEach((row) => {
            this.builder.addRow();
            let columnIndex = 0;
            row.forEach((value) => {
                let dataType = dataTypes[columnIndex++];
                if (dataType === 'MONEY' && value !== null) {
                    value = Currency.format(value, this.dataset.options.currency);
                }
                if (dataType === 'DATETIME' && value !== null) {
                    const timestamp = moment(value);
                    value = timestamp.tz(this.dataset.options.timezone).format('YYYY-MM-DD h:mm:ss A');
                }
                if (dataType === 'PERCENT' && value !== null) {
                    value = (value * 100).toFixed(2) + '%';
                }
                if (dataType === 'NUMBER' && value !== null) {
                    if (value.toString().indexOf('.')) {
                        value = (value * 1).toFixed(2);
                    }
                }
                this.builder.addCell(value, dataType);
            });
        });
        const self = this;
        this.builder.appendTo($container).DataTable({
            scrollY: '80vh',
            scrollX: true,
            scrollCollapse: true,
            paging: false,
            order: [],
            drawCallback: function( settings ) {
                // Reset margin to 0 after datatable render
                var ele = document.querySelector('.dataTables_scrollBody');
                if(ele){
                    ele = ele.querySelector('.dataTable');
                    if(ele){
                        ele.style.margin = 0;
                    }
                }
            },
            footerCallback: function (/*row, data, start, end, display*/) {
                self.calculateFooters(this.api());
            }
        });
        $(window).on('resize', () => this.updateTableSize());
        this.updateTableSize();
    }

    updateTableSize() {
        const container = '#' + this.builder.getId() + '_wrapper';
        const $table = $(container + ' .dataTables_scrollBody');
        if ($table.length) {
            const top = $table.offset().top;
            const buttonsHeight = 0;
            const windowHeight = $(window).height();
            const headerHeight = $(container + ' .row:first-child').height();
            const footerHeight = $(container + ' .row:last-child').height();
            let space = windowHeight - (top + buttonsHeight + headerHeight + footerHeight + 40);
            space = Math.max(space, 300);
            $table.css('height', space + 'px');
        }
    }

    calculateFooters(api) {
        for (let i = 0; i < this.dataset.columns.length; i++) {
            const column = this.dataset.columns[i];
            if (column.dataType === 'MONEY' || column.dataType === 'NUMBER' || column.dataType === 'COUNT') {
                const floatVal = function (value) {
                    if (value === null || value === undefined || value === '') {
                        return 0;
                    }
                    if (typeof value === 'string') {
                        return parseFloat(value.replace(/[^0-9\.\-]/g, ''));
                    }
                    return value;
                };
                let total = api.column(i).data().reduce(function (a, b) {
                    return floatVal(a) + floatVal(b);
                }, 0);
                let pageTotal = api.column(i, { page: 'current' }).data().reduce(function (a, b) {
                    return floatVal(a) + floatVal(b);
                }, 0);
                if (column.dataType === 'MONEY') {
                    total = Currency.format(total, this.dataset.options.currency);
                    pageTotal = Currency.format(pageTotal, this.dataset.options.currency);
                }
                if (column.dataType === 'NUMBER') {
                    if (total.toString().indexOf('.') || pageTotal.toString().indexOf('.')) {
                        total = (total * 1).toFixed(2);
                        pageTotal = (pageTotal * 1).toFixed(2);
                    }
                }
                if (total !== pageTotal) {
                    total = pageTotal + '<br/>' + total;
                }
                $(api.column(i).footer()).html(total);
            }
        }
    }
}
