import $ from 'jquery';

export class ConfirmModal
{
    constructor (button, callback) {
        this.$button = $(button);
        this.$modal = $('#confirm-modal');
        const text = this.$button.attr('data-confirm');
        this.$modal.find('.modal-body').text(text);
        this.$modal.modal('show');
        this.$modal.find('.action-confirm').on('click.confirm', () => {
            callback(this);
            this.$modal.modal('hide');
        });
        this.$modal.on('hide.bs.modal', () => {
            this.$button.off('click.confirm');
        });
    }
}