import { Ladda } from '../../assets/vendor/libs/ladda/ladda';

export function ajaxLoadButton(selector, url) {
    const $btn = $(this);
    $btn.on('click', (event) => {
        const ladda = Ladda.create($btn[0]);
        ladda.start();
        $btn.attr('disabled', 'disabled');
        $.ajax(url, {
            success: (response) => {
                const $response = $(response);
                $(selector).replaceWith($response.find(selector));
            },
            complete: () => {
                ladda.stop();
                $btn.removeAttr('disabled');
            }
        });
        event.preventDefault();
    });
}
