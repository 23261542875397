import { Form } from './Form';
import { FormEvent } from './FormEvent';

export class EventForm extends Form
{
    constructor($form) {
        super($form);
        this.maxDisplayEvents = 10;
        this.undo = [];
        this.redo = [];
        this.$undoMenu = $('#form-undo-menu');
        this.$undoButton = $('#form-undo-button');
        this.$undoButton.parent().show();
        this.$redoMenu = $('#form-redo-menu');
        this.$redoButton = $('#form-redo-button');
        this.$redoButton.parent().show();
        this.$undoMenu.on('click', '.btn-undo', (event) => {
            const $control = $(event.currentTarget);
            this.rewindEvents($control.attr('data-id'));
        });
        this.$redoMenu.on('click', '.btn-redo', (event) => {
            const $control = $(event.currentTarget);
            this.repeatEvents($control.attr('data-id'));
        });
        this.handleLogChanged();
        this.createSnapshot();
    }

    handleEvent(eventId, parameters, undoEventId, undoParameters) { }

    postEvent(eventId, parameters) {
        const output = this.handleEvent(eventId, parameters);
        if (output) {
            this.recordEvent(eventId, parameters, output.id, output.parameters);
        }
    }

    updateUndoMenus() {
        if (this.undo.length === 0) {
            this.$undoButton.attr('disabled', 'disabled');
        } else {
            let count = 0;
            this.$undoButton.removeAttr('disabled');
            this.$undoMenu.find('.dropdown-dynamic').remove();
            for (let i = this.undo.length - 1; i >= 0; i--) {
                const event = this.undo[i];
                const $item = $('<a></a>', {
                    'href': 'javascript:void(0);',
                    'class': 'dropdown-item btn-undo dropdown-dynamic',
                    'data-id': event.uid
                }).html(this.describeEvent(event));
                this.$undoMenu.append($item);
                if (++count === this.maxDisplayEvents) {
                    const remaining = this.undo.length - this.maxDisplayEvents;
                    const caption = Translator.trans('form.undo_more', { 'count': remaining });
                    const $more = $('<h6></h6>', {
                        'class': 'dropdown-header dropdown-dynamic'
                    }).text(caption);
                    this.$undoMenu.append($more);
                    break;
                }
            }
        }
        if (this.redo.length === 0) {
            this.$redoButton.attr('disabled', 'disabled');
        } else {
            let count = 0;
            this.$redoButton.removeAttr('disabled');
            this.$redoMenu.find('.dropdown-dynamic').remove();
            for (let i = this.redo.length - 1; i >= 0; i--) {
                const event = this.redo[i];
                const $item = $('<a></a>', {
                    'href': 'javascript:void(0);',
                    'class': 'dropdown-item btn-redo dropdown-dynamic',
                    'data-id': event.uid
                }).html(this.describeEvent(event));
                this.$redoMenu.append($item);
                if (++count === this.maxDisplayEvents) {
                    const remaining = this.redo.length - this.maxDisplayEvents;
                    const caption = Translator.trans('form.redo_more', { 'count': remaining });
                    const $more = $('<h6></h6>', {
                        'class': 'dropdown-header dropdown-dynamic'
                    }).text(caption);
                    this.$redoMenu.append($more);
                    break;
                }
            }
        }
    }

    handleLogChanged() {
        this.updateUndoMenus();
    }

    describeEvent(event) {
        return event.timestamp.toDateString();
    }

    recordEvent(eventId, parameters, undoEventId, undoParameters) {
        this.redo = [];
        const snapshot = this.getSnapshot();
        const event = new FormEvent(eventId, parameters, this.getDelta(this.snapshot, snapshot), undoEventId,
            undoParameters, this.getDelta(snapshot, this.snapshot));
        this.snapshot = snapshot;
        this.undo.push(event);
        this.handleLogChanged();
    }

    rewindEvents(targetId) {
        while (true) {
            const event = this.undo.pop();
            if (event) {
                this.handleEvent(event.undoId, event.undoParameters);
                this.applyDelta(event.undoDelta);
                this.redo.push(event);
                if (event.uid === targetId) {
                    break;
                }
            } else {
                break;
            }
        }
        this.handleLogChanged();
    }

    repeatEvents(targetId) {
        while (true) {
            const event = this.redo.pop();
            if (event) {
                this.handleEvent(event.id, event.parameters);
                this.applyDelta(event.delta);
                this.undo.push(event);
                if (event.uid === targetId) {
                    break;
                }
            } else {
                break;
            }
        }
        this.handleLogChanged();
    }

    getSnapshot() {
        let output = {};
        this.getControls().each((index, element) => {
            const $control = $(element);
            const name = $control.attr('name');
            output[name] = this.read(name);
        });
        return output;
    }

    createSnapshot() {
        this.snapshot = this.getSnapshot();
    }

    applyDelta(delta) {
        for (const field in delta.add) {
            if (delta.add.hasOwnProperty(field)) {
                this.write(field, delta.add[field]);
            }
        }
        for (const field in delta.change) {
            if (delta.change.hasOwnProperty(field)) {
                this.write(field, delta.change[field]);
            }
        }
    }

    getDelta(before, after) {
        let output = { change: {}, add: {}, remove: [] };
        for (let name in before) {
            if (before.hasOwnProperty(name)) {
                if (after.hasOwnProperty) {
                    if (before[name] !== after[name]) {
                        output.change[name] = after[name];
                    }
                } else {
                    output.remove.push(name);
                }
            }
        }
        for (let name in after) {
            if (after.hasOwnProperty(name)) {
                if (!before.hasOwnProperty(name)) {
                    output.add[name] = after[name];
                }
            }
        }
        return output;
    }
}