export class Assistant
{
    init(enabled, cache, savePath)
    {
        this.enabled = enabled;
        this.cache = cache ? cache : [];
        this.savePath = savePath;
        this.keys = [];
        this.text = {};
        this.titles = {};
        this.elements = {};
        $('.assistant-enable').on('click', (event) => this.enableToggle(event));
        $('.assistant-reset').on('click', (event) => this.resetClick(event));
    }

    save(key, value)
    {
        Pace.ignore(() => $.ajax(this.savePath, {
            data: { key: key, value: value }
        }));
    }

    saveEnabled()
    {
        this.save('assistant_disable', this.enabled ? '0' : '1');
    }

    saveCache()
    {
        this.save('assistant_cache', this.cache);
    }

    enableToggle(event)
    {
        this.enabled = !this.enabled;
        $('.assistant-enable input').prop('checked', this.enabled).trigger('change');
        this.saveEnabled();
        event.preventDefault();
        if (this.enabled) {
            this.run(true);
        }
        return false;
    }

    resetClick()
    {
        this.cache = [];
        this.saveCache();
    }

    attach()
    {
        for (let i = 0; i < this.keys.length; i++) {
            const $element = $(this.elements[this.keys[i]]);
            $element.popover({
                'trigger': 'hover',
                'delay': { show: 1200, hide: 100 },
                'content': this.text[this.keys[i]],
                'title': this.titles[this.keys[i]],
                'template': '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
            });
        }
    }

    run(force = false)
    {
        this.keys = [];
        this.text = {};
        this.elements = {};
        $('[data-assistant]:visible').each((index, element) => {
            const $element = $(element);
            const key = $element.attr('data-assistant');
            let j = key.indexOf(':');
            const id = j > -1 ? key.substr(j + 1) : key;
            this.keys.push(key);
            let text = $element.attr('data-help');
            if (!text) {
                text = Translator.trans('assistant.' + id);
            }
            let title = $element.attr('data-title');
            if (!title) {
                const titleKey = 'assistant.' + id + '_title';
                title = Translator.trans(titleKey);
                if (title === titleKey) {
                    title = '';
                }
            }
            this.text[key] = text;
            this.elements[key] = $element.attr('data-target') ? $element.attr('data-target') : '[data-assistant="' + key + '"]';
            this.titles[key] = title;
        });
        this.keys.sort();
        let steps = [];
        for (let i = 0; i < this.keys.length; i++) {
            let key = this.keys[i];
            let j = key.indexOf(':');
            const id = j > -1 ? key.substr(j + 1) : key;
            const priority = j > -1 ? key.substr(0, j) : '500';
            if (priority >= '300') {
                if (force || this.cache.indexOf(id) === -1) {
                    steps.push({
                        'element': this.elements[key],
                        'content': this.text[key]
                    });
                    this.cache.push(id);
                }
            }
        }
        if ((force || this.enabled) && steps.length > 0) {
            const tour = new Tour({
                'steps': steps,
                'storage': null,
                'backdrop': true,
                'onEnd': () => { window.assistant.saveCache(); this.attach(); },
                'template': $('#assistant-template').html(),
                'debug': true

            });
            tour.init();
            tour.start();
        } else {
            this.attach();
        }
    }
}
