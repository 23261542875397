import { Ladda } from '../../assets/vendor/libs/ladda/ladda';

export class Modal
{

    constructor(id) {
        this.busyCounter = 0;
        this.id = id;
        this.buttonCallbacks = {};
        this.ladda = null;
        this.subtitle = null;
        const self = this;
        this.getModal().find('.modal-footer button').off('click').on('click', function () {
            self.buttonClicked(this);
        });
    }

    buttonClicked(button) {
        const name = $(button).attr('data-name');
        if (this.buttonCallbacks.hasOwnProperty(name)) {
            this.buttonCallbacks[name](button);
        }
    }

    onButtonClick(name, callback) {
        this.buttonCallbacks[name] = callback;
    }


    show() {
        this.getModal().modal('show').one('hidden.bs.modal', () => {
            if (window.currentModal === this) {
                window.currentModal = null;
            }
        });
        window.currentModal = this;
    }

    hide(callback) {
        if (this.getModal().hasClass('show')) {
            if (callback) {
                this.getModal().one('hidden.bs.modal', callback);
            }
            this.getModal().modal('hide');
        } else if (callback) {
            callback();
        }
    }

    onHide(callback) {
        this.getModal().off('hidden.bs.modal').on('hidden.bs.modal', callback);
    }

    getModal() {
        return $('#' + this.id);
    }

    getModalTools() {
        return this.getModal().find('.modal-tools');
    }

    getModalExtra() {
        return this.getModal().find('.modal-extra');
    }

    getBody() {
        return this.getModal().find('.modal-body');
    }

    getBanner() {
        return this.getModal().find('.modal-banner');
    }

    getCustomButtons() {
        return this.getModal().find('.modal-buttons');
    }

    getButtonByName(name) {
        return this.getModal().find('.modal-footer button[data-name=' + name + ']');
    }

    setVisibleButtons(list, leave) {
        this.getModal().find('.modal-footer button[data-name]').each((index, element) => {
            const $button = $(element);
            if (leave && $.inArray($button.attr('data-name'), leave) !== -1) {
                return;
            }
            $button.hide();
        });
        for (let i = 0; i < list.length; i++) {
            this.getButtonByName(list[i]).show();
        }
    }

    setTitle(title, subtitle) {
        if (subtitle === undefined && this.subtitle) {
            subtitle = this.subtitle;
        }
        const $title = this.getModal().find('.modal-title').empty().text(title);
        if (subtitle) {
            this.subtitle = subtitle;
            $('<small></small>').text(subtitle).addClass('ml-2').appendTo($title);
        }
    }

    setSubTitle(subtitle) {
        const $subtitle = this.getModal().find('.modal-title small');
        if ($subtitle.length === 0) {
            $('<small></small>').text(subtitle).appendTo(this.getModal().find('.modal-title'));
        } else {
            $subtitle.text(subtitle);
        }
    }

    clear() {
        this.getBody().empty();
        this.getCustomButtons().empty();
        this.getBanner().empty().hide();
        this.getModal().find('.modal-clearable').remove();
    }

    addBanner($element) {
        $element.appendTo(this.getBanner());
        this.getBanner().show();
    }

    addContentText(text, classNames) {
        const $content = $('<div></div>').addClass('modal-body-content').text(text).appendTo(this.getBody());
        if (classNames) {
            $content.addClass(classNames);
        }
    }

    addContent($element) {
        $element.appendTo(this.getBody());
    }

    addCustomButtons($element) {
        $element.appendTo(this.getCustomButtons());
    }

    enableButton(name) {
        this.getButtonByName(name).removeAttr('disabled').show();
    }

    enableButtons(list) {
        for (let i = 0; i < list.length; i++) {
            this.enableButton(list[i]);
        }
    }

    disableButton(name) {
        this.getButtonByName(name).attr('disabled', 'disabled');
    }

    disableButtons(list) {
        for (let i = 0; i < list.length; i++) {
            this.disableButton(list[i]);
        }
    }

    addProgressBar() {
        const $progressContainer = $('<div></div>').addClass('progress').appendTo(this.getBody());
        $('<div></div>')
            .addClass('progress-bar progress-bar-striped progress-bar-animated')
            .css('width', '100%')
            .appendTo($progressContainer);
    }

    removeProgressBar() {
        this.getBody().find('.progress').remove();
    }

    setProgressBar(percent) {
        this.getModal().find('.progress-bar').css('width', percent + '%');
    }

    getProgressBar() {
        return this.getModal().find('.progress-bar');
    }

    addError(message) {
        $('<div></div>').addClass('alert alert-danger').text(message).prependTo(this.getBody());
        this.removeProgressBar();
    }

    startBusy(button) {
        this.busyCounter++;
        this.getBody().addClass('busy-indicator');
        if (button) {
            const $button = this.getButtonByName(button);
            if ($button.length) {
                this.ladda = Ladda.create($button[0]);
                this.ladda.start();
            }
        }
    }

    stopBusy() {
        this.busyCounter--;
        if (this.busyCounter === 0) {
            this.getBody().removeClass('busy-indicator');
        }
        if (this.ladda) {
            this.ladda.stop();
            this.ladda = null;
        }
    }

    addStatus(text) {
        $('<div></div>').addClass('action-status mb-2').text(text).appendTo(this.getBody());
    }

    getStatus() {
        return this.getBody().find('.action-status');
    }

    flashes(list) {
        for (let category in list) {
            if (list.hasOwnProperty(category)) {
                for (let i = 0; i < list[category].length; i++) {
                    flash.notify(list[category][i], null, category);
                }
            }
        }
    }

    modalButtonClick(event) {
        const $button = $(event.currentTarget);
        const name = $button.attr('data-name');
        if ($button.attr('data-ajax')) {
            const url = $button.attr('data-ajax');
            this.startBusy(name);
            $.ajax(url, {
                success: (response) => {
                    if (response.flash) {
                        this.flashes(response.flash);
                    }
                    if (response.success) {
                        this.hide();
                    }
                },
                complete: () => {
                    this.stopBusy();
                },
                error: (xhr, status) => {
                    this.addError(status);
                }
            })
        }
    }

    load(url, callback) {
        this.startBusy();
        $.ajax(url, {
            success: (response) => {
                if (response.content) {
                    this.clear();
                    this.setTitle(response.title);
                    this.addContent($(response.content));
                    if (response.banner) {
                        this.addBanner($(response.banner));
                    }
                    if (response.buttons) {
                        for (let name in response.buttons) {
                            if (response.buttons.hasOwnProperty(name)) {
                                this.enableButton(name);
                                for (let attr in response.buttons[name]) {
                                    if (response.buttons[name].hasOwnProperty(attr)) {
                                        this.getButtonByName(name).attr(attr, response.buttons[name][attr]);
                                        if (this.modalButtonClick && attr === 'data-ajax') {
                                            this.getButtonByName(name)
                                                .off('click')
                                                .on('click', (event) => this.modalButtonClick(event));
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    const $response = $(response);
                    const $content = $($response.find('.modal-content').html());
                    this.clear();
                    this.addContent($content);
                    $('.select2entity[data-autostart="true"]').select2entity();
                    const title = $response.find('.modal-title').text().trim();
                    if (title) {
                        this.setTitle(title);
                    }
                }
                if (callback) {
                    callback();
                }
            },
            error: (xhr) => {
                const $error = $(xhr.responseText);
                this.clear();
                this.addContent($error);
            },
            complete: () => {
                this.stopBusy();
            }
        });
    }

    preSubmitValidate() {
        this.preValidateResult = true;
        this.getBody().find('input:visible,select:visible').each((index, element) => {
            if (element.checkValidity) {
                if (!element.checkValidity()) {
                    element.reportValidity();
                    this.preValidateResult = false;
                }
            }
        });
        return this.preValidateResult;
    }
}
