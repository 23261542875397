import { Cookies } from './Cookies';

export class DownloadMonitor {

    constructor() {
        this.lastUid = null;
        this.callbacks = [];
        this.run();
    }

    run() {
        const downloadUid = Cookies.get('downloaduid');
        if (downloadUid && downloadUid !== this.lastUid) {
            this.lastUid = downloadUid;
            if (this.callbacks[downloadUid]) {
                this.callbacks[downloadUid]();
            }
        }
        window.setTimeout(() => this.run(), 1000);
    }

    monitor(url, callback) {
        const uid = (Math.random() * 1000).toString();
        this.callbacks[uid] = callback;
        return url + '&downloaduid=' + uid;
    }

    get(url, callback) {
        const uid = (Math.random() * 1000).toString();
        this.callbacks[uid] = callback;
        url += url.indexOf('?') ? '&' : '?';
        url += 'downloaduid=' + uid;
        window.location.href = url;
    }

}