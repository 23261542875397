export default class TableBuilder
{
    constructor($table) {
        if ($table) {
            this.$table = $table;
        } else {
            this.$table = $('<table class="table w-100"><thead><tr></tr></thead><tbody></tbody><tfoot><tr></tr></tfoot></tr></tfoot></table>');
        }
    }

    setId(id) {
        this.$table.attr('id', id);
    }

    getId() {
        let id = this.$table.attr('id');
        if (!id) {
            id = 'table_' + Math.random();
            this.setId(id);
        }
        return id;
    }

    appendTo($target) {
        $target.append(this.$table);
        return this.$table;
    }

    header() {
        return this.$table.find('thead tr');
    }

    body() {
        return this.$table.find('tbody')
    }

    footer() {
        return this.$table.find('tfoot tr');
    }

    addHeaderColumn(caption, dataType) {
        let classNames = [];
        if (dataType === 'NUMBER' || dataType === 'MONEY' || dataType === 'COUNT' || dataType === 'PERCENT') {
            classNames.push('text-right');
        }
        return $('<th></th>', {
            'data-type': dataType,
            'class': classNames.join(' ')
        }).text(caption).appendTo(this.header());
    }

    addFooterColumn(caption, dataType) {
        let classNames = [];
        if (dataType === 'NUMBER' || dataType === 'MONEY' || dataType === 'COUNT') {
            classNames.push('text-right');
        }
        return $('<th></th>', {
            'data-type': dataType,
            'class': classNames.join(' ')
        }).text(caption).appendTo(this.footer());
    }

    addRow() {
        return this.$currentRow = $('<tr></tr>').appendTo(this.body());
    }

    addCell(value, dataType) {
        let classNames = [];
        if (dataType === 'NUMBER' || dataType === 'MONEY' || dataType === 'COUNT' || dataType === 'PERCENT') {
            classNames.push('text-right');
        }
        return $('<td></td>', {
            'class': classNames.join(' ')
        }).text(value).appendTo(this.$currentRow);
    }

    table() {
        return this.$table;
    }
}