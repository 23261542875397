import $ from 'jquery';
import Currency from '../util/Currency';

export function applyToInvoiceControl() {
    new ApplyToInvoiceControl(this);
}

class ApplyToInvoiceControl {

    constructor (control) {
        this.$control = $(control);
        this.$container = this.$control.closest('.apply-to-invoice');
        this.$input = this.$control.closest('form').find('.apply-to-invoice-amount');
        this.$checkboxes = this.$container.find('.apply-to-invoice-select');
        this.$auto = this.$container.find('#invoice-auto');
        this.$total = this.$container.find('.invoice-selected-total');
        this.$balance = this.$container.find('.invoice-balance');
        this.currency = this.$control.attr('data-currency');
        this.updateTotal();
        this.$checkboxes.on('click', () => this.updateFromCheckboxes());
        this.$input.on('keyup change', () => this.updateFromAmount());
        this.$auto.on('click', () => this.updateFromAmount());
        this.$auto.prop('checked', true);
    }

    updateFromAmount() {
        const amount = parseFloat(this.$input.val());
        if (this.$auto.prop('checked')) {
            let remaining = amount;
            this.$checkboxes.each((index, element) => {
                const $checkbox = $(element);
                const outstanding = parseFloat($checkbox.attr('data-invoice-outstanding'));
                if (remaining > 0) {
                    $checkbox.prop('checked', true);
                    remaining -= outstanding;
                } else {
                    $checkbox.prop('checked', false);
                }
            })
        }
        this.updateTotal();
    }

    updateFromCheckboxes() {
        const total = this.updateTotal();
        if (this.$auto.prop('checked')) {
            if (this.$input.attr('readonly') !== 'readonly') {
                this.$input.val(total.toFixed(2));
                this.updateTotal();
            }
        }
    }

    updateTotal() {
        let total = 0;
        let selection = [];
        this.$checkboxes.each((index, element) => {
            const $element = $(element);
            if ($element.prop('checked')) {
                const value = parseFloat($element.attr('data-invoice-outstanding'));
                selection.push($element.attr('data-invoice-id'));
                total += value;
            }
        });
        const display = Currency.format(total, this.currency);
        this.$total.text(display);
        this.$control.val(selection.join(','));
        let amount = parseFloat(this.$input.val()) || 0;
        let balance = amount - total;
        if (balance.toFixed(2) === '0.00') {
            balance = 0;
        }
        this.$balance.text(Currency.format(balance, this.currency));
        this.$balance.toggleClass('badge-danger', balance < 0);
        this.$balance.toggleClass('badge-success', balance > 0);
        this.$balance.toggleClass('badge-default', balance === 0);
        return total;
    }

}
