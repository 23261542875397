import $ from 'jquery';
import { Form } from '../form/Form';
import { Ladda } from '../../assets/vendor/libs/ladda/ladda';

export function sendTableModal(options) {
    new SendTable(this, options);
}

class SendTable
{
    constructor (modal, options) {
        this.options = options;
        this.$modal = $(modal);
        this.form = new Form(this.$modal.find('form'));

        this.$modal.on('show.bs.modal', () => {
            this.form.write('subject', $('.display-page-title').text().trim());
            this.$modal.find('.alert').empty().hide();
        });

        this.$modal.find('.button-submit').on('click', (event) => {
            const l = Ladda.create(event.currentTarget);
            l.start();
            this.form.busy();
            this.$modal.find('.alert').empty().hide();

            let params = $('.dataTable').DataTable().ajax.params();
            $.extend(params, this.form.getData());
            params.action = 'export';
            params.length = 9999;
            params.start = 0;

            Pace.track(() => $.ajax({
                method: 'POST',
                data: params,
                success: () => {
                    this.$modal.modal('hide');
                    flash.success(Translator.trans('flash.send_table_success'))
                },
                error: (response) => this.$modal.find('.alert').text(response.statusText).show(),
                complete: () => {
                    l.stop();
                    this.form.ready();
                }
            }));
        });
    }
}
