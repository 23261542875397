export function cursorTable(options) {
    return new CursorTable(this, options);
}

class CursorTable {

    constructor(element, options) {
        this.$element = $(element);
        this.id = this.$element.attr('id');
        this.url = this.$element.attr('data-url');
        this.currentCursor = this.$element.attr('data-current-cursor');
        this.nextCursor = this.$element.attr('data-next-cursor');
        this.cursorStack = [];
        this.$next = this.$element.find('.btn-next');
        this.$next.on('click', (event) => {
            this.next();
            event.preventDefault();
            return false;
        });
        this.$previous = this.$element.find('.btn-previous');
        this.$previous.on('click', (event) => {
            this.previous();
            event.preventDefault();
            return false;
        });
        this.$element.on('table:filter', () => {
            this.currentCursor = '';
            this.cursorStack = [];
            this.reload();
        });
        this.update();
    }

    update() {
        this.$next.toggleClass('disabled', !this.nextCursor);
        this.$previous.toggleClass('disabled', this.cursorStack.length === 0);
    }

    next() {
        if (this.nextCursor) {
            this.cursorStack.push(this.nextCursor);
            this.currentCursor = this.nextCursor;
            this.nextCursor = null;
            this.reload();
        }
    }

    previous() {
        if (this.cursorStack.length > 0) {
            this.currentCursor = this.cursorStack.pop();
            this.nextCursor = null;
            this.reload();
        }
    }

    reload() {
        const params = { cursor: this.currentCursor };
        this.$element.find('.table-parameter').each((index, element) => {
            params[$(element).attr('data-name')] = $(element).val();
        });
        this.$element.addClass('busy-indicator');
        $.ajax(this.url, {
            data: params,
            success: (response) => {
                const $response = $(response).find('#' + this.id);
                const $tbody = $response.find('tbody');
                this.currentCursor = $response.attr('data-current-cursor');
                this.nextCursor = $response.attr('data-next-cursor');
                this.$element.find('tbody').replaceWith($tbody);
                this.update();
            },
            complete: () => {
                this.$element.removeClass('busy-indicator');
            }
        });
    }

}
