import { copyToClipboard } from '../util/Clipboard';

export function tableClipboard(options) {
    return new TableClipboard(this, options);
}

class TableClipboard {

    constructor(table, options) {
        this.table = table;
        this.options = options;
        if (options === 'copy') {
            this.copy();
        }
    }

    copy() {
        copyToClipboard('<table>' + $(this.table).html() + '</table>');
    }
}
