import $ from 'jquery';
import { Template } from '../template/Template';

export function findSimilarShipments(url, formSelector, displaySelector, templateSelector) {
    const $display = $(displaySelector);
    const modal = window.currentModal;
    const initialIds = modal.ids.slice(0);

    if (initialIds.length !== 1) {
        return;
    }

    $display.addClass('busy-indicator');

    const update = () => {
        let list = initialIds.slice(0);
        $display.find('.similar-select').each((index, element) => {
            const checkbox = $(element);
            if (checkbox.is(':checked')) {
                const id = checkbox.attr('data-id');
                list.push(id);
            }
        });
        modal.ids = list;
        modal.setSubTitle(Translator.transChoice('table.selection_count', list.length,
            { '%count%': list.length }));
    };

    $.ajax(url, {
        success: (response) => {
            const template = new Template(templateSelector);
            if (response.success && response.shipments.length > 0) {
                const count = response.shipments.length;
                $('<a></a>', {
                    'class': 'btn btn-xs btn-secondary d-block mb-2',
                    'href': '#',
                }).appendTo($display)
                    .text(Translator.transChoice('edi.select_similar_shipments',
                        count,{'%count%': count}))
                    .on('click', () => {
                        let checked = modal.ids.length === 1;
                        $display.find('.similar-select').each((index, element) => {
                            $(element).prop('checked', checked);
                        });
                        update();
                    });
                for (let i = 0; i < count; i++) {
                    const $item = $(template.render(response.shipments[i]));
                    $item.appendTo($display);
                    $item.find('input').on('change', update);
                }
            }
        },
        complete: () => {
            $display.removeClass('busy-indicator');
        }
    });
}

