export class FavoriteManager {

    constructor() {
        $(() => {
            $(document).on('click', '.favorite-indicator', (event) => {
                this.click(event.currentTarget);
                event.preventDefault();
                return false;
            });
        });
    }

    click(element) {
        const url = $(element).attr('href');
        const state = $(element).hasClass('favorite-set');
        const newState = state ? '0' : '1';
        $.ajax({
            url: url,
            data: { state: newState }
        });
        $(element).toggleClass('favorite-set', newState);
    }

}