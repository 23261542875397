import $ from 'jquery';

export function dropdownSearch(options) {
    new DropdownSearch($(this), options);
}

class DropdownSearch
{
    constructor ($container, options) {
        this.options = Object.assign({
            'selector': '.dropdown-menu-search',
            'height': '20rem',
            'placeholder': ''
        }, options);
        $container
            .on('show.bs.dropdown', this.options.selector, (event) => this.dropdownOpening(event))
            .on('shown.bs.dropdown', this.options.selector, (event) => this.dropdownOpened(event));

    }

    initializeDropdown($dropdown)
    {
        const $menu = $dropdown.find('.dropdown-menu');
        const $menuItems = $menu.find('.dropdown-item,.dropdown-header');
        $menuItems.wrapAll('<div class="scroller"></div>');
        const $scroll = $dropdown.find('.scroller');
        $scroll.css('max-height', this.options.height).css('overflow', 'auto');
        const $input = $('<input>', {
            'class': 'search-control form-control',
            'placeholder': this.options.placeholder
        });
        const $div = $('<div></div>', { 'class': 'search-container ml-1 mr-1' }).insertBefore($scroll);
        $div.append($input);
        $input.on('keyup change', () => this.filter($input.val(), $scroll));
        $dropdown.addClass('initialized');
    }

    filter(text, $scroll) {
        text = text.toLowerCase();
        $scroll.find('a').each((index, element) => {
            const match = $(element).text().toLowerCase().indexOf(text) >= 0;
            $(element).toggleClass('filtered', !match);
        });
        $scroll.scrollTop(0);
    }

    dropdownOpening(event) {
        const $dropdown = $(event.currentTarget);
        if (!$dropdown.hasClass('initialized')) {
            this.initializeDropdown($dropdown);
        }
        $dropdown.find('input').val('').trigger('change');
    }

    dropdownOpened(event) {
        const $dropdown = $(event.currentTarget);
        const itemCount = $dropdown.find('.dropdown-item:visible').length;
        $dropdown.find('.search-container').toggle(itemCount > 10);
    }
}