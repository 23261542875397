import './Avatars.scss'

function initAvatars() {
    $('.user-avatar').popover();
}

$(function () {
    $(document).on('content-loaded', () => initAvatars());
    initAvatars();
});
