/*
 * Structure
 *
 *
 *    .editor-container
 *      .editor-control-container
 *        textarea.editor-control
 *        .editor-upload-container
 *      .editor-preview-container
 */

import { Dropzone } from '../../assets/vendor/libs/dropzone/dropzone';
import showdown from 'showdown';
import $ from 'jquery';

import './EditorControl.scss';

export function editorControl(options) {
    new EditorControl(this, options);
}

class EditorControl
{
    constructor(textarea, options) {
        this.$textarea = $(textarea);
        this.id = this.$textarea.attr('id');
        if (!this.id) {
            this.id = '_' + Math.random().toString(36).substr(2, 9);
            this.$textarea.attr('id', this.id);
        }
        this.containerId = this.id + '_container';
        this.options = $.extend({
            'url': '/_uploader/resources/upload',
            'resource_prefix': '/res/',
            'lang_preview': 'Preview',
            'lang_edit': 'Edit',
            'lang_uploading': 'Uploading %filename%...',
            'lang_instructions': 'To attach files or images, drag and drop them here, paste from the clipboard or click here.',
            'lang_error': 'Unable to upload %filename%: %message%'
        }, options);
        this.prepare();
        this.markdown = new showdown.Converter({
            tables: true,
            extensions: [
                {
                    type: 'output',
                    regex: /\<table/g,
                    replace: '<table class="table"'
                },
                {
                    type: 'output',
                    regex: /\<img src\=\"([^\.]*)\.(?!jpg|gif|png|bmp|jpeg)([a-z]+)\" alt\=\"([^\"]+)\" \/\>/g,
                    replace: '<i class="fa fa-paperclip"></i> <a class="file-download" href="$1.$2" download>$3</a>'
                },
                {
                    type: 'output',
                    regex: /\<img /g,
                    replace: '<img class="img-fluid" '
                }
            ]
        });
    }

    showMessage(key, parameters) {
        let text = this.options['lang_' + key];
        for (let name in parameters) {
            if (parameters.hasOwnProperty(name)) {
                text = text.replace('%' + name + '%', parameters[name]);
            }
        }
        this.removeMessage(key);
        $('<div></div>', { 'class': 'editor-message-' + key }).text(text).appendTo(this.$messages);
    }

    removeMessage(key, parameters) {
        if (parameters) {
            let text = this.options['lang_' + key];
            for (let name in parameters) {
                if (parameters.hasOwnProperty(name)) {
                    text = text.replace('%' + name + '%', parameters[name]);
                }
            }
            this.$messages.find('.editor-message-' + key).each((index, element) => {
                if ($(element).text() === text) {
                    $(element).remove();
                }
            });
        } else {
            this.$messages.find('editor-message-' + key).remove();
        }
    }

    preview() {
        this.$buttonEdit.show();
        this.$buttonPreview.hide();
        let height = this.$controlContainer.height() + 2;
        this.$previewContainer.css('height', height + 'px');
        this.$previewContainer.css('max-height', height + 'px');
        this.$previewContainer.show();
        this.$controlContainer.hide();
        let source = this.$textarea.val();
        let html = this.markdown.makeHtml(source);
        this.$previewContainer.html(html);
    }

    edit() {
        this.$buttonEdit.hide();
        this.$buttonPreview.show();
        this.$controlContainer.show();
        this.$previewContainer.hide();
    }

    prepare() {
        if (this.$textarea.hasClass('editor-loaded')) {
            return;
        }

        this.$textarea.addClass('editor-loaded');
        this.$textarea.wrap('<div class="editor-container w-100"><div class="editor-control-container"></div></div>');
        this.$controlContainer = this.$textarea.closest('.editor-control-container');
        this.$container = this.$textarea.closest('.editor-container');
        this.$container.attr('id', this.containerId);

        this.$uploadContainer = $('<div></div>', {
            'class': 'editor-attachment-container'
        }).appendTo(this.$controlContainer);

        this.$progressContainer = $('<div></div>', {
            'class': 'editor-progress-container'
        }).appendTo(this.$uploadContainer);

        this.$progress = $('<div></div>', {
            'class': 'editor-progress-indicator'
        }).appendTo(this.$progressContainer);

        this.$messages = $('<div></div>', {
            'class': 'editor-messages'
        }).appendTo(this.$uploadContainer);

        this.$previewContainer = $('<div></div>', {
            'class': 'editor-preview-container',
            'style': 'display: none'
        }).appendTo(this.$container);

        this.$label = $(`label[for="${this.id}"]`);
        this.$buttonPreview = $('<button></button>', {
            'class': 'btn btn-secondary btn-xs ml-1',
            'type': 'button'
        }).text(this.options['lang_preview']).appendTo(this.$label).on('click', () => this.preview());
        this.$buttonEdit = $('<button></button>', {
            'class': 'btn btn-secondary btn-xs ml-1',
            'type': 'button',
            'style': 'display: none'
        }).text(this.options['lang_edit']).appendTo(this.$label).on('click', () => this.edit());

        this.$textarea.on('focus', () => this.$controlContainer.addClass('focus'));
        this.$textarea.on('blur', () => this.$controlContainer.removeClass('focus'));

        this.$textarea.on('paste', (event) => this.paste(event));

        this.initDropzone();
        this.showProgress(100);
        this.showMessage('instructions');
    }

    paste(event) {
        if (event.originalEvent.clipboardData.files) {
            const file = event.originalEvent.clipboardData.files[0];
            if (file) {
                this.handler.addFile(file);
            }
        } else if (event.originalEvent.clipboardData) {
            let file = event.originalEvent.clipboardData.items[0];
            if (file) {
                file = file.getAsFile();
                handler.addFile(file);
            }
        }
    }

    initDropzone() {
        this.handler = new Dropzone('#' + this.containerId, {
            url: this.options['url'],
            createImageThumbnails: false,
            previewsContainer: false,
            clickable: `#${this.containerId} .editor-attachment-container`
        });
        this.handler.on('addedfile',(file) => {
            this.insertAttachmentPlaceholder(file.upload.uuid);
            this.showProgress(0);
            this.showMessage('uploading', { 'filename': file.name });
        });

        this.handler.on('totaluploadprogress',
            (uploadProgress) => this.showProgress(uploadProgress - 1));

        this.handler.on('queuecomplete',() => {
            this.showProgress(100);
            this.removeMessage('uploading');
        });

        this.handler.on('success', (file, response) => {
            this.replaceAttachmentPlaceholder(file.upload.uuid, file.name, response.filename);
            this.removeMessage('uploading', { 'filename': file.name });
        });

        this.handler.on('error', (file, response) => {
            let message = response.error.message;
            if (response.error.exception) {
                message = response.error.exception[0].message;
            }
            this.removeAttachmentPlaceholder(file.upload.uuid);
            this.removeMessage('uploading', { 'filename': file.name });
            this.showMessage('error', { 'filename': file.name, 'message': message });
            this.removeMessage('uploading');
        })
    }

    showProgress(percent) {
        if (percent === 0) {
            this.$progress.addClass('indeterminate');
        } else if (percent === 100) {
            this.$progress.removeClass('indeterminate').css('width', '0');
        } else {
            this.$progress.removeClass('indeterminate').css('width', percent + '%');
        }
    }

    urlFromFilename(filename) {
        return this.options['resource_prefix'] + filename;
    }

    placeholderFromUuid(uuid) {
        return '![uploading-' + uuid + ']\n';
    }

    insertAttachmentPlaceholder(uuid) {
        this.insertInEditor(this.placeholderFromUuid(uuid));
    }

    removeAttachmentPlaceholder(uuid) {
        this.replaceInEditor(this.placeholderFromUuid(uuid), '');
    }

    replaceAttachmentPlaceholder(uuid, name, filename) {
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(name)[1];
        let insert = '[' + name + '](' + this.urlFromFilename(filename + '.' + extension) + ')';
        if (extension.match(/gif|png|bmp|jpg|jpeg|tif|tiff/) !== null) {
            insert = '!' + insert;
        }
        this.replaceInEditor(this.placeholderFromUuid(uuid), insert);
    }

    insertInEditor(newText) {
        const start = this.$textarea.prop('selectionStart');
        const end = this.$textarea.prop('selectionEnd');
        let text = this.$textarea.val();
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        text = before + newText + after;
        this.$textarea.val(text);
        this.$textarea.selectionStart = this.$textarea.selectionEnd = start + newText.length;
        this.$textarea.focus();
    }

    replaceInEditor(original, replacement)
    {
        let text = this.$textarea.val();
        this.$textarea.val(text.replace(original, replacement));
    }
}