export function formAccessor() {
    return new Form($(this));
}

export class Form
{
    constructor($form) {
        this.$form = $form;
    }

    busy() {
        this.$form.parent().addClass('busy-indicator');
    }

    ready() {
        this.$form.parent().removeClass('busy-indicator');
    }

    getControl(name) {
        if (name.indexOf('[') === -1) {
            name = this.getName() + '[' + name + ']';
        }
        return this.$form.find('[name="' + name + '"]');
    }

    getLabelForControl($control) {
        const id = $control.attr('id');
        const $labelControl = $('label[for=' + id + ']');
        if ($labelControl.length === 1) {
            return $labelControl.text();
        }
        if ($control.attr('placeholder')) {
            return $control.attr('placeholder');
        }
        if ($control.is('select')) {
            const $first = $control.find('option:first-child');
            if ($first.length) {
                return $first.text();
            }
        }
        let path = this.getPathFromControl($control);
        return path.pop();
    }

    readFromControl($control) {
        if ($control.hasClass('select2entity')) {
            const data = $control.select2('data');
            for (let i = 0; i < data.length; i++) {
                if (data[i].id) {
                    return data[i].id + "\t" + data[i].text;
                }
            }
            return null;
        }
        let value = $control.val();
        value = this.normalizeValueForControl($control, value);
        return value;
    }

    read(name) {
        const $control = this.getControl(name);
        return this.readFromControl($control);
    }

    write(name, value) {
        const $control = this.getControl(name);
        $control.val(value);
    }

    getName() {
        return this.$form.attr('name');
    }

    getControls() {
        return this.$form.find('input[name],select[name]');
    }

    normalizeValueForControl($control, value)
    {
        return value;
    }

    getControlByPath(path) {
        let name = this.getName() + '[' + path.join('][') + ']';
        return this.$form.find('[name="' + name + '"]');
    }

    getPathFromControl($control) {
        let name = $control.attr('name');
        const i = name.indexOf('[');
        name = name.substr(i + 1);
        name = name.substr(0, name.length - 1);
        return name.split('][');
    }

    setControlValue($control, value) {
        if ($control.hasClass('select2entity')) {
            if (value === '') {
                const option = new Option('', null, true, true);
                $control.append(option).trigger('change');
            } else {
                const parts = value.split("\t");
                if (parts.length === 2) {
                    const option = new Option(parts[1], parts[0], true, true);
                    $control.append(option).trigger('change');
                }
            }
        } else if ($control.attr('type') === 'checkbox') {
            if (value === '0' || value === 0 || value === false) {
                $control.removeProp('checked');
            } else {
                $control.prop('checked', 'checked');
            }
        } else {
            $control.val(value);
        }
    }

    setDataWithPath(path, object) {
        for (const field in object) {
            if (object.hasOwnProperty(field)) {
                let subPath = path;
                subPath.push(field);
                const value = object[field];
                if (typeof value == 'object') {
                    this.setDataWithPath(subPath, value);
                } else {
                    const $control = this.getControlByPath(subPath);
                    this.setControlValue($control, value);
                }
            }
        }
    }

    setData(data) {
        this.setDataWithPath([], data);
    }

    setObjectProperty(object, path, value) {
        let n = path.shift();
        if (path.length) {
            if (!object.hasOwnProperty(n)) {
                object[n] = {};
            }
            this.setObjectProperty(object[n], path, value);
        } else {
            object[n] = value;
        }
    }

    getData() {
        let data = {};
        const properties = this.$form.serializeArray();
        for (let i = 0; i < properties.length; i++) {
            const $control = this.getControl(properties[i].name);
            const value = this.readFromControl($control);
            this.setObjectProperty(data, this.getPathFromControl($control), value);
        }
        return data;
    }

    getFormText() {
        let output = "";
        const properties = this.$form.serializeArray();
        const prefix = 'form';
        for (let i = 0; i < properties.length; i++) {
            let name = properties[i].name.replace(prefix, '');
            if (name === '[_token]') {
                continue;
            }
            const $control = this.getControl(properties[i].name);
            let value = this.readFromControl($control);
            if (typeof value === "string" && value.match(/^[0-9]+\t.+$/)) {
                value = value.replace("\t", ":");
            }
            if (value === null) {
                value = '';
            }
            output += name + "=" + value + "\r\n";
        }
        return output;
    }

    setFormText(text) {
        const lines = text.split("\n");
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            const j = line.indexOf('=');
            const property = line.substr(0, j);
            let value = line.substr(j + 1);
            const $control = this.getControl(this.getName() + property);
            if ($control.length) {
                if ($control.hasClass('select2entity')) {
                    value = value.replace(":", "\t");
                }
                this.setControlValue($control, value);
            }
        }
    }
}
