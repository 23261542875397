export function avatarUploader(options) {
    new UploadAvatar(this, options);
}

class UploadAvatar
{
    constructor (imageControl)
    {
        this.$image = $(imageControl);
        const uploadSelector = this.$image.attr('data-upload');
        const saveSelector = this.$image.attr('data-save');
        this.$upload = $(uploadSelector);
        this.$save = $(saveSelector);
        this.uploadedImageURL = null;
        this.URL = window.URL || window.webkitURL;
        this.$upload.on('change', (event) => this.onUpload(event));
        this.$save.on('click', (event) => this.onSave(event));
    }

    onUpload(event) {
        const files = event.currentTarget.files;
        let file;

        this.$save.show();

        if (files && files.length) {
            file = files[0];

            if (/^image\/\w+$/.test(file.type)) {
                if (this.uploadedImageURL) {
                    this.URL.revokeObjectURL(this.uploadedImageURL);
                }

                this.uploadedImageURL = this.URL.createObjectURL(file);
                this.$image.cropper('destroy').attr('src', this.uploadedImageURL).cropper({
                    aspectRatio: 1,
                    preview: '.user-avatar-container'
                });
            } else {
                window.alert(Translator.trans('error.image_format_required'));
            }
        }
    }

    onSave(event) {
        const ladda = Ladda.create(event.target).start();
        this.$image.cropper('getCroppedCanvas').toBlob((blob) => {
            const formData = new FormData();

            formData.append('image', blob);

            $.ajax(this.$save.attr('data-url'), {
                method: "POST",
                data: formData,
                processData: false,
                contentType: false,
                success: () => window.location.reload(true),
                error: (error) => flash.error(error.statusText),
                complete: () => ladda.stop()
            });
        });
    }
}

