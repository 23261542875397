import $ from 'jquery';
import 'jquery-ui-bundle';
import './Frame.scss';

export class Frame
{
    constructor(id, initialUrl) {
        this.id = id;
        this.openingUrl = initialUrl;
        this.$container = null;
        this.headerHeight = 16;
        this.borderWidth = 4;
        this.position = this.defaultPosition();
    }

    setUrl(url) {
        this.openingUrl = url;
    }

    positionKey() {
        return 'frame.' + this.id + '.position';
    }

    defaultPosition() {
        let position = {
            width: 450,
            height: 600,
            left: window.innerWidth - 500,
            top: window.innerHeight - 610
        };
        if (!this.positionValid(position)) {
            position.width = window.innerWidth - 20;
            position.height = window.innerHeight - 80;
            position.left = 10;
            position.top = 75;
        }
        return position;
    }

    positionValid(position) {
        return position.top > 65 && position.left > 0
            && ((position.left + position.width) < window.innerWidth)
            && ((position.top + position.height) < window.innerHeight);
    }

    loadPosition() {
        let position = window.localStorage.getItem(this.positionKey());
        if (position) {
            position = JSON.parse(position);
            if (this.positionValid(position)) {
                this.position = position;
                return position;
            }
        }
        return this.position;
    }

    storePosition() {
        const position = {
            left: parseInt(this.$container.css('left').replace('px', '')),
            top: parseInt(this.$container.css('top').replace('px', '')),
            width: parseInt(this.$container.css('width').replace('px', '')),
            height: parseInt(this.$container.css('height').replace('px', '')),
        };
        window.localStorage.setItem(this.positionKey(), JSON.stringify(position));
    }

    applyPosition(position) {
        this.$container.css('left', position.left + 'px');
        this.$container.css('top', position.top + 'px');
        this.$container.css('width', position.width + 'px');
        this.$container.css('height', position.height + 'px');
        this.$header.css('height', this.headerHeight + 'px');
        this.$container.css('padding', this.borderWidth + 'px');
        this.$body.css('height', 'calc(100% - ' + this.headerHeight + 'px)');
    }

    create() {
        this.$container = $('<div></div>', {
            'class': 'frame-container',
            'id': this.id
        }).appendTo($('body'));
        this.$header = $('<div></div>', {
            'class': 'frame-header'
        }).appendTo(this.$container);
        this.$body = $('<div></div>', {
            'class': 'frame-body'
        }).appendTo(this.$container);
        this.$frame = $('<iframe></iframe>', {
            'class': 'frame-iframe',
            'src': this.openingUrl
        }).appendTo(this.$body);
        this.$expandButton = $('<a></a>', {
            'href': this.openingUrl,
            'target': '_blank'
        }).appendTo(this.$header).html('<i class="fa fa-expand-alt"></i>');
        this.$expandButton.on('click', () => this.close());
        this.$closeButton = $('<a></a>', {
            'href': '#'
        }).appendTo(this.$header).html('<i class="fa fa-times"></i>');
        this.$closeButton.on('click', () => this.close());
        this.$container
            .draggable({ 'stop': () => this.storePosition() })
            .resizable()
            .on('resize', () => this.storePosition());
        this.applyPosition(this.loadPosition());
    }

    close() {
        if (this.$container) {
            this.$container.hide();
        }
    }

    open() {
        if (this.$container === null) {
            this.create();
        } else {
            this.$container.show();
        }
        this.applyPosition(this.loadPosition());
    }
}
