$(function () {
    $('input[data-typeahead-route]').each(function () {
        $(this).typeahead(null, {
            display: 'value',
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: Router.route($(this).attr('data-typeahead-route')) + '?q=%QUERY%',
                    wildcard: '%QUERY%'
                }
            })
        });
    });
});