import $ from 'jquery';

export class ListGroupCollection
{
    constructor() {
        this.registeredGroups = [];
        $(document).on('click', '.btn-list-group-collection-add', (event) => this.onAddClick(event));
        $(document).on('click', '.btn-list-group-collection-delete', (event) => this.onDeleteClick(event));
    }

    register(id, prototype) {
        this.registeredGroups[id] = prototype;
    }

    onAddClick(event) {
        const $add = $(event.currentTarget);
        const id = $add.attr('data-collection');
        const $collection = $('#' + id);
        let prototype = this.registeredGroups[id];
        let counter = parseInt($collection.attr('data-counter')) + 1;
        $collection.attr('data-counter', counter);
        prototype = prototype.replace(/__name__/g, counter);
        const $container = $('<li></li>', {
            'class': 'list-group-item list-group-collection-item'
        });
        const $button = $('<a></a>', {
            'href': '#',
            'class': 'btn btn-xs btn-secondary btn-collection-delete btn-list-group-collection-delete'
        }).text($add.attr('data-delete-label')).appendTo($container);
        $(prototype).appendTo($container);
        const $controls = $('#' + id + ' > .list-group-collection-controls');
        $container.insertBefore($controls);
        $container.trigger('content-loaded');
    }

    onDeleteClick(event) {
        $(event.currentTarget).closest('.list-group-collection-item').remove();
    }

}
