import Handlebars from 'handlebars/dist/handlebars';

export function helperCard(card) {
    let output = [];
    if (card.attn) {
        output.push(card.attn);
    }
    if (card.orgname) {
        output.push(card.orgname);
    }
    if (card.street1) {
        output.push(card.street1);
    }
    if (card.street2) {
        output.push(card.street2);
    }
    if (card.street3) {
        output.push(card.street3);
    }
    let line = [];
    if (card.city) {
        line.push(card.city);
    }
    let postalcode = [];
    if (card.province) {
        postalcode.push(card.province);
    }
    if (card.postalcode) {
        postalcode.push(card.postalcode);
    }
    if (postalcode.length) {
        line.push(postalcode.join(' '));
    }
    if (card.country) {
        line.push(card.country);
    }
    output.push(line.join(', '));
    return new Handlebars.SafeString(
        output.map((line) => `<span class="d-inline-block text-nowrap text-truncate w-100">${line}</span>`)
            .join('')
    );
}