export class GridTransform {
    constructor () {
        this.SERIES_SEPARATOR = ' - ';
    }

    /**
     * This function takes a row of data and a list of key columns to generate a key
     * For example, given the dataset
     *            D       D       M       M
     *      [ [ 'Date', 'Name', 'Cost', 'Sale' ],
     *        [ '2018', 'Joe',  100,    200 ],
     *        [ '2019', 'Joe',  200,    300 ],
     *        [ '2018', 'Bob',  300,    400 ],
     *        [ '2019', 'Bob',  400,    500 ] ]
     * You want to transform it into
     *   axis : '2018', '2019'
     *   buckets: [ { series: 'Joe - Cost', data: [ 100, 200 ] },
     *              { series: 'Joe - Sale', data: [ 200, 300 ] },
     *              { series: 'Bob - Cost', data: [ 300, 400 ] },
     *              { series: 'Bob - Sale', data: [ 400, 500 ] } ]
     *   axisType: DATE
     *   seriesType: STRING
     *   metricType: MONEY
     *
     * @param dataset     the input dataset
     * @returns object    the transformed dataset
     */
    transform(dataset) {
        let dimensionIndices = dataset.getDimensionIndices();
        let metricIndices = dataset.getMetricIndices();

        let axisTransform = dimensionIndices.splice(0, 1);
        let seriesTransforms = [];
        for (let i = 0; i < metricIndices.length; i++) {
            seriesTransforms.push([...dimensionIndices, metricIndices[i]]);
        }

        let output = {
            axis: dataset.getAxis(axisTransform, this.SERIES_SEPARATOR),
            buckets: [],
            axisType: dataset.getCombinedDataType(axisTransform),
            metricType: dataset.getCombinedDataType(metricIndices),
            seriesType: dataset.getCombinedDataType(dimensionIndices)
        };
        let bucketKeys = [];

        dataset.rows.forEach((row) => {
            let axisKey = dataset.getRowKey(axisTransform, row).join(this.SERIES_SEPARATOR);
            let x = output.axis.indexOf(axisKey);
            seriesTransforms.forEach((columns) => {
                let bucketKey = dataset.getRowKey(columns, row).join(this.SERIES_SEPARATOR);
                let bucketIndex = bucketKeys.indexOf(bucketKey);
                let bucket = {};
                if (bucketIndex === -1) {
                    bucketKeys.push(bucketKey);
                    bucket = { series: bucketKey, data: new Array(output.axis.length) };
                    bucket.data.fill(null);
                    output.buckets.push(bucket);
                } else {
                    bucket = output.buckets[bucketIndex];
                }
                let metric = columns[columns.length - 1];
                bucket.data[x] = row[metric];
            });
        });

        return output;
    }

}