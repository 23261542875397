import $ from 'jquery';
import { EventForm } from '../form/EventForm';
import { QuoteEditorRule } from './QuoteEditorRule';

export function quoteEditor(options) {
    new QuoteEditor($(this), options);
}

class QuoteEditor extends EventForm
{
    constructor ($form, options) {
        super($form);

        this.FREIGHT = 'freight';
        this.FUEL = 'fuel';
        this.FUEL_RATE = 'fuelRate';
        this.SURCHARGES = 'surcharges';
        this.AMOUNT = 'amount';
        this.DESCRIPTION = 'description';
        this.CHARGE = 'charge';
        this.NET = 'net';
        this.TAX1_AMOUNT = 'tax1Amount';
        this.TAX2_AMOUNT = 'tax2Amount';
        this.TAX1_CODE = 'tax1Code';
        this.TAX2_CODE = 'tax2Code';
        this.GROSS = 'gross';
        this.CUSTOMER_FUEL_APPLICATION = 'customerFuelApplication';
        this.RESELLER_FUEL_APPLICATION = 'resellerFuelApplication';
        this.CUSTOMER_MARKUP = 'customerMarkup';
        this.RESELLER_MARKUP = 'resellerMarkup';
        this.CUSTOMER_CURRENCY_RATE = 'customerCurrencyConversion';
        this.RESELLER_CURRENCY_RATE = 'resellerCurrencyConversion';

        this.SECTION_COST = 'cost';
        this.SECTION_CUSTOMER = 'customer';
        this.SECTION_RESELLER = 'reseller';
        this.SECTION_LIST = 'list';

        this.ANY = '*';

        this.EVENT_ADD_SURCHARGE = 'ADD_SURCHARGE';
        this.EVENT_REMOVE_SURCHARGE = 'REMOVE_SURCHARGE';
        this.EVENT_CHANGE = 'CHANGE';
        this.EVENT_REPLAY = 'REPLAY';

        this.FUEL_APPLICATION_FREIGHT_AND_SURCHARGES = "1";
        
        this.sections = [this.SECTION_COST, this.SECTION_CUSTOMER, this.SECTION_RESELLER, this.SECTION_LIST];
        this.rules = [];

        this.changedControl = null;

        this.initRules();

        $form.on('change', 'input,select', (event) => {
            const $changedControl = $(event.currentTarget);
            const id = $changedControl.attr('id');
            this.changedControl = id;
            this.onControlChanged($changedControl);
            this.postEvent(this.EVENT_CHANGE, { id: id });
            this.changedControl = null;
        });

        $form.on('click', '.btn-delete-surcharge', (event) => {
            const $button = $(event.currentTarget);
            const parameters = {
                index: $button.attr('data-index'),
                section: $button.closest('.quote-section').attr('data-section')
            };
            this.postEvent(this.EVENT_REMOVE_SURCHARGE, parameters);
            event.preventDefault();
            return true;
        });

        $form.on('click', '.btn-add-surcharge', (event) => {
            const $button = $(event.currentTarget);
            const parameters = {
                section: $button.attr('data-section'),
                index: null
            };
            this.postEvent(this.EVENT_ADD_SURCHARGE, parameters);
            event.preventDefault();
            return true;
        });
    }

    describeEvent(event) {
        if (event.description === null) {
            let fields = [];
            let description = '';
            switch (event.id) {
                case this.EVENT_ADD_SURCHARGE:
                case this.EVENT_REMOVE_SURCHARGE:
                    description = $('.quote-section[data-section="' + event.parameters.section + '"]').attr('data-caption');
                    const caption = event.id === this.EVENT_ADD_SURCHARGE ?
                        Translator.trans('edi.quote_editor_add_surcharge') : Translator.trans('edi.quote_editor_remove_surcharge');
                    description += ' ' + caption;
                    break;
                case this.EVENT_REPLAY:
                case this.EVENT_CHANGE:
                    for (let name in event.undoDelta.change) {
                        if (event.undoDelta.change.hasOwnProperty(name)) {
                            const $control = this.getControl(name);
                            const section = $control.closest('.quote-section').attr('data-caption');
                            const caption = this.getLabelForControl($control);
                            fields.push(section + ' ' + caption);
                        }
                    }
                    description = fields.slice(0, 4).join(', ');
                    break;
            }
            event.description = description + '<br/><small class="text-muted">' + event.timestamp.toLocaleTimeString() + '</small>';
        }
        return event.description;
    }

    handleEvent(eventId, eventParameters) {
        switch (eventId) {
            case this.EVENT_REMOVE_SURCHARGE:
                const $amount = this.getControlByPath([eventParameters.section, 'surcharges', eventParameters.index, 'amount']);
                $amount.val(0);
                this.loadFormValues();
                this.getControls().removeClass('calculated');
                this.propagateChange($amount);
                this.handleEvent(this.EVENT_CHANGE, { id: $amount.attr('id') });
                $amount.closest('.editor-surcharge').remove();
                return { id: this.EVENT_ADD_SURCHARGE, parameters: { section: eventParameters.section, index: eventParameters.index } };
            case this.EVENT_ADD_SURCHARGE:
                const $surcharges = this.$form.find('.quote-section[data-section="' + eventParameters.section + '"] .editor-surcharges-container');
                const $field = $('#' + $surcharges.attr('data-link'));
                let newIndex = eventParameters.index;
                if (newIndex === null) {
                    newIndex = parseInt($surcharges.attr('data-length')) + 1;
                    $surcharges.attr('data-length', newIndex);
                    eventParameters.index = newIndex;
                }
                let prototype = $field.attr('data-prototype').replace(/__name__/g, newIndex);
                $(prototype).appendTo($field);
                return { id: this.EVENT_REMOVE_SURCHARGE, parameters: { section: eventParameters.section, index: newIndex }};
            case this.EVENT_CHANGE:
                return { id: this.EVENT_REPLAY, parameters: eventParameters };
            case this.EVENT_REPLAY:
                this.getControls().removeClass('calculated');
                return null;
        }
    }

    isLocked($control) {
        const $lock = this.$form.find('button[data-link="' + $control.attr('id') + '"]');
        return $lock.hasClass('active');
    }

    normalizeValueForControl($control, value) {
        if ($control.hasClass('tax-select')) {
            const caption = $control.find('option:selected').text();
            const match = caption.match(/\(([0-9\.]+)\%/);
            if (match) {
                return match[1] / 100;
            }
            return 0;
        }
        /* we can tell it is a number if it is right-aligned */
        if ($control.hasClass('text-right')) {
            value = Math.round(parseFloat(value) * 100) / 100;
        }
        return value;
    }

    prepareValueForControl($control, value) {
        if ($control.hasClass('text-right')) {
            return value.toFixed(2);
        }
        return value;
    }

    loadFormValues() {
        this.currentValues = [];
        this.$form.find('input,select').each((index, element) => {
            const $control = $(element);
            const path = this.getPathFromControl($control);
            let value = this.normalizeValueForControl($control, $control.val());
            this.currentValues.push({ path: path, value: value });
        });
    }

    matchPath(path1, path2) {
        for (let i = 0; i < path1.length; i++) {
            if (path1[i] === path2[i] || path1[i] === this.ANY || path2[i] === this.ANY || path1[i] === undefined
                || path2[i] === undefined) {
                continue;
            }
            return false;
        }
        return true;
    }

    getValueByPath(path) {
        let output = null;
        for (let i = 0; i < this.currentValues.length; i++) {
            const entry = this.currentValues[i];
            if (this.matchPath(path, entry.path)) {
                const $control = this.getControlByPath(entry.path);
                const value = this.normalizeValueForControl($control, $control.val());
                if (output === null) {
                    output = value;
                } else {
                    output += value;
                }
            }
        }
        return output;
    }

    createRule(path, parameters, callback) {
        if (path[0] === this.ANY) {
            for (let i = 0; i < this.sections.length; i++) {
                let rulePath = JSON.parse(JSON.stringify(path));
                let ruleParameters = JSON.parse(JSON.stringify(parameters));
                rulePath[0] = this.sections[i];
                for (let j = 0; j < parameters.length; j++) {
                    if (parameters[j][0] === this.ANY) {
                        ruleParameters[j][0] = rulePath[0];
                    }
                }
                this.createRule(rulePath, ruleParameters, callback);
            }
            return;
        }
        const rule = new QuoteEditorRule(this, path, parameters, callback);
        this.rules.push(rule);
    }

    initRules() {
        this.createRule([ this.ANY, this.NET ], [
            [ this.ANY, this.FREIGHT ],
            [ this.ANY, this.FUEL ],
            [ this.ANY, this.SURCHARGES, this.ANY, this.AMOUNT ]
        ], (params) => {
            return params[0] + params[1] + params[2];
        });
        this.createRule([ this.ANY, this.GROSS ], [
            [ this.ANY, this.NET ],
            [ this.ANY, this.TAX1_CODE ],
            [ this.ANY, this.TAX2_CODE ]
        ], (params) => {
            const net = params[0];
            const pct1 = params[1];
            const pct2 = params[2];
            const pct = pct1 + pct2;
            return net * (1 + pct);
        });
        this.createRule([ this.ANY, this.NET ], [
            [ this.ANY, this.GROSS ],
            [ this.ANY, this.TAX1_CODE ],
            [ this.ANY, this.TAX2_CODE ]
        ], (params) => {
            const gross = params[0];
            const pct1 = params[1];
            const pct2 = params[2];
            const pct = pct1 + pct2;
            return gross / (1 + pct);
        });
        this.createRule([ this.ANY, this.TAX1_AMOUNT ], [
            [ this.ANY, this.TAX1_CODE ],
            [ this.ANY, this.NET ]
        ], (params) => {
            return params[0] * params[1];
        });
        this.createRule([ this.ANY, this.TAX2_AMOUNT ], [
            [ this.ANY, this.TAX2_CODE ],
            [ this.ANY, this.NET ]
        ], (params) => {
            return params[0] * params[1];
        });
        this.createRule([ this.ANY, this.GROSS ], [
            [ this.ANY, this.NET ],
            [ this.ANY, this.TAX1_AMOUNT ],
            [ this.ANY, this.TAX2_AMOUNT ]
        ], (params) => {
            return params[0] + params[1] + params[2];
        });
        this.createRule([ this.SECTION_COST, this.FUEL ], [
            [ this.SECTION_COST, this.FREIGHT ],
            [ this.SECTION_COST, this.FUEL_RATE ]
        ], (params) => {
            const freight = params[0];
            const fuelRate = params[1];
            if (freight === 0) return 0;
            return freight * (fuelRate / 100);
        });
        this.createRule([ this.SECTION_COST, this.FUEL_RATE ], [
            [ this.SECTION_COST, this.FREIGHT ],
            [ this.SECTION_COST, this.FUEL ]
        ], (params) => {
            const freight = params[0];
            const fuel = params[1];
            if (freight === 0 || fuel === 0) return 0;
            return (fuel / freight) * 100;
        });
        this.createRule([ this.SECTION_LIST, this.FUEL ], [
            [ this.SECTION_LIST, this.FREIGHT ],
            [ this.SECTION_LIST, this.FUEL_RATE ]
        ], (params) => {
            const freight = params[0];
            const fuelRate = params[1];
            if (freight === 0) return 0;
            return freight * (fuelRate / 100);
        });
        this.createRule([ this.SECTION_LIST, this.FUEL_RATE ], [
            [ this.SECTION_LIST, this.FREIGHT ],
            [ this.SECTION_LIST, this.FUEL ]
        ], (params) => {
            const freight = params[0];
            const fuel = params[1];
            if (freight === 0 || fuel === 0) return 0;
            return (fuel / freight) * 100;
        });
        this.createRule([ this.SECTION_CUSTOMER, this.FUEL ], [
            [ this.SECTION_CUSTOMER, this.FREIGHT ],
            [ this.SECTION_CUSTOMER, this.FUEL_RATE ],
            [ this.CUSTOMER_FUEL_APPLICATION ],
            [ this.SECTION_CUSTOMER, this.SURCHARGES, this.ANY, this.AMOUNT ]
        ], (params) => {
            const freight = params[0];
            const fuelRate = params[1];
            const fuelApplication = params[2];
            const surcharges = params[3];
            if (fuelRate === 0) return 0;
            let base = freight;
            if (fuelApplication === this.FUEL_APPLICATION_FREIGHT_AND_SURCHARGES) {
                base = freight + surcharges;
            }
            return (fuelRate / 100) * base;
        });
        this.createRule([ this.SECTION_CUSTOMER, this.FUEL_RATE ], [
            [ this.SECTION_CUSTOMER, this.FREIGHT ],
            [ this.SECTION_CUSTOMER, this.FUEL ],
            [ this.CUSTOMER_FUEL_APPLICATION ],
            [ this.SECTION_CUSTOMER, this.SURCHARGES, this.ANY, this.AMOUNT ]
        ], (params) => {
            const freight = params[0];
            const fuel = params[1];
            const fuelApplication = params[2];
            const surcharges = params[3];
            if (fuel === 0) return 0;
            let base = freight;
            if (fuelApplication === this.FUEL_APPLICATION_FREIGHT_AND_SURCHARGES) {
                base = freight + surcharges;
            }
            return (fuel / base) * 100;
        });
        this.createRule([ this.SECTION_RESELLER, this.FUEL ], [
            [ this.SECTION_RESELLER, this.FREIGHT ],
            [ this.SECTION_RESELLER, this.FUEL_RATE ],
            [ this.RESELLER_FUEL_APPLICATION ],
            [ this.SECTION_RESELLER, this.SURCHARGES, this.ANY, this.AMOUNT ]
        ], (params) => {
            const freight = params[0];
            const fuelRate = params[1];
            const fuelApplication = params[2];
            const surcharges = params[3];
            if (fuelRate === 0) return 0;
            let base = freight;
            if (fuelApplication === this.FUEL_APPLICATION_FREIGHT_AND_SURCHARGES) {
                base = freight + surcharges;
            }
            return (fuelRate / 100) * base;
        });
        this.createRule([ this.SECTION_RESELLER, this.FUEL_RATE ], [
            [ this.SECTION_RESELLER, this.FREIGHT ],
            [ this.SECTION_RESELLER, this.FUEL ],
            [ this.RESELLER_FUEL_APPLICATION ],
            [ this.SECTION_RESELLER, this.SURCHARGES, this.ANY, this.AMOUNT ]
        ], (params) => {
            const freight = params[0];
            const fuel = params[1];
            const fuelApplication = params[2];
            const surcharges = params[3];
            if (fuel === 0) return 0;
            let base = freight;
            if (fuelApplication === this.FUEL_APPLICATION_FREIGHT_AND_SURCHARGES) {
                base = freight + surcharges;
            }
            return (fuel / base) * 100;
        });
        this.createRule([ this.SECTION_CUSTOMER, this.FREIGHT ], [
            [ this.CUSTOMER_MARKUP ],
            [ this.SECTION_COST, this.FREIGHT ],
            [ this.SECTION_LIST, this.FREIGHT ],
            [ this.CUSTOMER_CURRENCY_RATE ]
        ], (params) => {
            const markup = params[0];
            const costFreight = params[1];
            const listFreight = params[2];
            const conversion = params[3];
            if (markup.substr(0, 1) === '-') {
                const rate = 1 + (parseFloat(markup) / 100);
                return listFreight * conversion * rate;
            } else {
                const rate = 1 + (parseFloat(markup) / 100);
                return costFreight * conversion * rate;
            }
        });
        this.createRule([ this.SECTION_CUSTOMER, this.FREIGHT ], [
            [ this.SECTION_CUSTOMER, this.NET ],
            [ this.SECTION_CUSTOMER, this.SURCHARGES, this.ANY, this.AMOUNT ],
            [ this.SECTION_CUSTOMER, this.FUEL_RATE ]
        ], (params) => {
            const net = params[0];
            const surcharges = params[1];
            const fuelRate = params[2] / 100;
            const freightPlusFuel = net - surcharges;
            return freightPlusFuel / (1 + fuelRate);
        });
        this.createRule([ this.SECTION_RESELLER, this.FREIGHT ], [
            [ this.RESELLER_MARKUP ],
            [ this.SECTION_COST, this.FREIGHT ],
            [ this.SECTION_LIST, this.FREIGHT ],
            [ this.RESELLER_CURRENCY_RATE ]
        ], (params) => {
            const markup = params[0];
            const costFreight = params[1];
            const listFreight = params[2];
            const conversion = params[3];
            if (markup.substr(0, 1) === '-') {
                const rate = 1 + (parseFloat(markup) / 100);
                return listFreight * conversion * rate;
            } else {
                const rate = 1 + (parseFloat(markup) / 100);
                return costFreight * conversion * rate;
            }
        });
        this.createRule([ this.SECTION_RESELLER, this.FREIGHT ], [
            [ this.SECTION_RESELLER, this.NET ],
            [ this.SECTION_RESELLER, this.SURCHARGES, this.ANY, this.AMOUNT ],
            [ this.SECTION_RESELLER, this.FUEL_RATE ]
        ], (params) => {
            const net = params[0];
            const surcharges = params[1];
            const fuelRate = params[2] / 100;
            const freightPlusFuel = net - surcharges;
            return freightPlusFuel / (1 + fuelRate);
        });
    }

    eachControl(callback) {
        this.getControls().each((index, element) => {
            const $control = $(element);
            callback(this.getPathFromControl($control), $control);
        });
    }

    propagateChange($control) {
        const path = this.getPathFromControl($control);
        for (let i = 0; i < this.rules.length; i++) {
            const rule = this.rules[i];
            if (rule.isAffectedBy(path)) {
                const $ruleControl = this.getControlByPath(rule.path);
                if (!this.isLocked($ruleControl) && !$ruleControl.hasClass('calculated') && $ruleControl.attr('id') !== this.changedControl) {
                    const value = this.normalizeValueForControl($ruleControl, rule.calculate());
                    const currentValue = this.getValueByPath(rule.path);
                    if (currentValue !== value) {
                        $ruleControl.val(this.prepareValueForControl($control, value));
                        $ruleControl.addClass('calculated');
                        this.propagateChange($ruleControl);
                    }
                }
            }
        }
    }

    onControlChanged($control) {
        const name = $control.attr('name');
        if (name) {
            this.loadFormValues();
            this.$form.find('input,select').removeClass('calculated');
            this.propagateChange($control);
        } else {
            console.log($control);
        }
    }
}
