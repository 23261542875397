export class PrivilegeEditor {

    constructor() {
        this.originalData = null;
        this.compareData = null;
        this.compareUsername = null;
    }

    checked(privilege, side, base) {
        if (base) {
            return base[side].indexOf(privilege) >= 0;
        } else {
            return !!$('#user_privileges_' + side + ' input[value="' + privilege+ '"]').prop('checked');
        }
    }

    badge(label, className, hint) {
        return $('<span></span>', {
            class: 'ml-1 badge badge-' + className,
            title: hint,
            'data-toggle': 'tooltip'
        } ).text(label);
    }

    update() {
        const userLoaded = !!this.compareData && this.compareUsername === this.$userInput.val()
        if (userLoaded) {
            $('#btn-clone-from, #btn-add-from, #btn-reduce-from').removeClass('disabled');
        } else {
            $('#btn-clone-from, #btn-add-from, #btn-reduce-from').addClass('disabled');
        }
        const regex = new RegExp(this.$userInput.val(), 'gi');
        this.each(($label, $input, privilege, side) => {
            const $badges = $label.find('.badges').empty();
            const html = $label.attr('data-text').replace(regex, '<span class="highlight">$&</span>');
            $label.find('.label').html(html);
            const isChecked = this.checked(privilege, side);
            const wasChecked = this.checked(privilege, side, privilegeEditor.originalData);
            if (isChecked && !wasChecked) {
                this.badge(Translator.trans('badge.added'), 'primary', Translator.trans('privileges.added_hint'))
                    .appendTo($badges);
            }
            if (!isChecked && wasChecked) {
                this.badge(Translator.trans('badge.removed'), 'outline-primary', Translator.trans('privileges.removed_hint'))
                    .appendTo($badges);
            }
            if (privilegeEditor.compareData) {
                const compared = this.checked(privilege, side, privilegeEditor.compareData);
                if (isChecked !== compared) {
                    this.badge(privilegeEditor.compareUsername,
                        compared ? 'success' : 'outline-success',
                        compared? Translator.trans('privileges.compared_present') : Translator.trans('privileges.compared_missing')
                    ).appendTo($badges);
                }
            }
        });
    }

    copyFrom(data, add, remove) {
        this.each(($label, $input, privilege, side) => {
            const sourceChecked = this.checked(privilege, side, data);
            if ((add && sourceChecked) || (remove && !sourceChecked)) {
                $input.prop('checked', sourceChecked);
            }
        });
        this.update();
    }

    each(callback) {
        $('#user_privileges_privileges label').each((index, elem) => {
            const $label = $(elem);
            const $input = $label.parent().find('input');
            const privilege = $input.attr('value');
            if (!$label.parent().hasClass('choice-disabled')) {
                callback($label, $input, privilege, 'privileges');
            }
        });
        $('#user_privileges_grantables label').each((index, elem) => {
            const $label = $(elem);
            const $input = $label.parent().find('input');
            const privilege = $input.attr('value');
            if (!$label.parent().hasClass('choice-disabled')) {
                callback($label, $input, privilege, 'grantables');
            }
        });
    }

    prepare() {
        this.each(($label, $input, privilege, side) => {
            const text = $label.text();
            $label.empty();
            $label.addClass('privilege-label');
            $label.attr('data-text', text);
            $label.attr('data-privilege', privilege);
            $label.attr('data-side', side);
            $input.click(() => this.update());
            $('<span></span>', { class: 'label' }).text(text).appendTo($label);
            $('<span></span>', { class: 'badges' }).appendTo($label);
        });
    }

    init(loaderPath, $userInput) {
        this.loaderPath = loaderPath;
        this.$userInput = $userInput;
        this.$userInput.autocompleteControl({
            onSelect: (suggestion) => {
                this.selectUser(suggestion.id);
                this.compareUsername = suggestion.text;
                this.update();
            },
            onKeyup: () => this.update()
        });
        this.originalData = this.read();
        this.prepare();
        $('#btn-reset').click(() => this.copyFrom(privilegeEditor.originalData, true, true));
        $('#btn-clone-from').click(() => this.copyFrom(privilegeEditor.compareData, true, true));
        $('#btn-add-from').click(() => this.copyFrom(privilegeEditor.compareData, true, false));
        $('#btn-reduce-from').click(() => this.copyFrom(privilegeEditor.compareData, false, true));
        this.update();
    }

    read() {
        const output = { privileges: [], grantables: [] };
        this.each(($label, $input, privilege, side) => {
            if ($input.prop('checked')) {
                output[side].push(privilege);
            }
        })
        return output;
    }

    selectUser(userId) {
        $.ajax(this.loaderPath, { data: { id: userId }}).then((response) => {
            this.compareData = response;
            this.update();
        });
    }

}