import $ from 'jquery';
import _ from 'underscore';
import { Form } from '../form/Form';

export function cardSearch(options) {
    const search = new CardSearch(this);
}

class CardSearch {

    constructor (control) {
        this.changeToken();
        this.control = control;
        this.$control = $(control);
        this.$control.addClass('pristine');
        this.form = new Form(this.$control.closest('form'));
        this.$control.typeahead({
            highlight: true,
            minLength: 3
        }, {
            name: 'form',
            limit: 10, // has to be double the actual results due to bug in typeahead:
                       // https://github.com/twitter/typeahead.js/issues/1730
            source: _.debounce((q, syncResults, asyncResults) =>
                this.sourceFunction(q, syncResults, asyncResults), 300),
            templates: {
                suggestion: (summary) => {
                    if (summary.providerDisplay) {
                        return `<div class="d-flex justify-content-between align-items-end">
                                    <span>${summary.title}</span>
                                    <small class="text-muted ml-2">${summary.providerDisplay}</small>
                                </div>`;
                    }
                    return '<div>' + summary.title + '</div>';
                }
            },
            display: (suggestion) => {
                return suggestion.title;
                return this.$control.val();
            }
        });
        this.$control.bind('typeahead:select', (event, suggestion) => {
            $.ajax('/search/card/retrieve', {
                data: {
                    key: suggestion.key,
                    session: this.sessionToken
                },
                success: (response) => {
                    this.applyCard(response);
                },
            });
            this.changeToken();
        });
        this.$control.on('keydown', () => {
            this.$control.removeClass('pristine');
        })
    }

    applyCard(card) {
        this.$control.addClass('pristine');
        this.$control.typeahead('val', card.organization);
        const path = this.form.getPathFromControl(this.$control);
        path.pop();
        Object.keys(card).map(field => {
            path.push(field);
            const fieldControl = this.form.getControlByPath(path);
            if (fieldControl) {
                let value = card[field] ? card[field] : '';
                fieldControl.val(value);
            }
            path.pop();
        });
    }

    changeToken() {
        this.sessionToken = Math.random() * 10000000000;
    }

    sourceFunction(q, syncResults, asyncResults) {
        if (this.$control.hasClass('pristine')) {
            syncResults([]);
        } else {
            $.ajax('/search/card/query', {
                data: {
                    q: q,
                    session: this.sessionToken,
                    type: 'ANY'
                },
                success: (response) => {
                    asyncResults(response.cardSummaries);
                }
            })
        }
    }
}

$(() => {
    $(document).on('content-loaded', () => {
        $('.card-search').each((index, element) => {
            new CardSearch(element);
        });
    });
});
