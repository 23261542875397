import { Ladda } from '../../assets/vendor/libs/ladda/ladda';

export class NoteManager {

    constructor() {
        $(() => {
            this.$submit = $('#note-submit');
            this.submitLadda = Ladda.create(this.$submit[0]);
            this.$modal = $('#entity-notes');
            this.$modal
                .draggable({ handle: '.modal-header' });
            this.$input = $('#note-text');
            $(document).on('click', '.note-indicator', (event) => {
                this.click(event.currentTarget);
                event.preventDefault();
                return false;
            });
            $(document).on('click', '.delete-note', (event) => {
                event.preventDefault();
                const $element = $(event.currentTarget);
                const path = $element.attr('data-path');
                $element.find('i').removeClass('fa-trash').addClass('fa-spinner').addClass('fa-spin').attr('disabled', 'disabled');
                $.ajax({
                    url: path,
                    data: {
                        id: $element.attr('data-id'),
                        delete: $element.attr('data-delete'),
                        entity: $element.attr('data-entity')
                    }
                }).then(() => {
                    $element.closest('.note-delete-container').remove();
                });
            });
        });
    }

    click(element) {
        const entity = $(element).attr('data-entity');
        const id = $(element).attr('data-id');
        switch (entity) {
            case 'shipment':
                this.shipment(id);
                break;
            case 'invoice':
                this.invoice(id);
                break;
            case 'organization':
                this.organization(id);
                break;
        }
    }

    invoice(id) {
        $.ajax({
            url: this.$modal.attr('data-path'),
            data: { entity: 'invoice', id }
        }).then((response) => this.update(response));
        this.$modal.modal('show');
        $('#note-submit')
            .off('click')
            .on('click', () => this.save('invoice', id));
    }

    organization(id) {
        $.ajax({
            url: this.$modal.attr('data-path'),
            data: { entity: 'organization', id }
        }).then((response) => this.update(response));
        this.$modal.modal('show');
        $('#note-submit')
            .off('click')
            .on('click', () => this.save('organization', id));
    }

    shipment(id) {
        $.ajax({
            url: this.$modal.attr('data-path'),
            data: { entity: 'shipment', id }
        }).then((response) => this.update(response));
        this.$modal.modal('show');
        $('#note-submit')
            .off('click')
            .on('click', () => this.save('shipment', id));
    }

    update(response) {
        const $page = $(response);
        const $heading = $page.find('.heading');
        const $body = $page.find('.content');
        $('#note-heading').html($heading.html());
        $('#notes-display').html($body.html());
    }

    save(entity, id) {
        this.submitLadda.start();
        this.$input.attr('disabled', 'disabled');
        $.ajax({
            url: this.$modal.attr('data-path'),
            data: { entity, id, append: this.$input.val() }
        })
            .then((response) => {
                this.update(response);
                this.$input.val('');
            })
            .always(() => {
                this.submitLadda.stop();
                this.$input.removeAttr('disabled');
            });
    }

}