export function stamps(options) {
    new Stamps($(this), options);
}

export function initStamps() {
    $('[data-stamps]').each((i, e) => $(e).stamps());
}

class Stamps {
    constructor($input, options) {
        this.$input = $input;
        this.url = $input.attr('data-stamps');
        this.options = options;
        this.stamps = [];
        this.deleted = [];
        this.readonly = true;
        this.$addEntry = null;
        this.$addText = null;
        this.createDropdown();
        this.updateItems();
        this.$input.on('keyup', () => this.change());
        this.change();
        this.fetch();
    }

    fetch() {
        $.ajax(this.url).then((response) => {
            this.stamps = response.stamps;
            this.readonly = response.readonly;
            this.updateItems();
            this.change();
            if (this.readonly && this.stamps.length === 0) {
                this.$input.removeAttr('data-toggle');
            }
        });
    }

    createDropdown() {
        this.$dropdown = this.$input.wrap($('<div class="dropdown"></div>')).parent();
        this.$input.attr('data-toggle', 'dropdown');
        this.$menu = $('<div class="dropdown-menu stamps-dropdown"></div>')
            .css('overflow', 'auto')
            .css('max-height', '200px')
            .appendTo(this.$dropdown);
    }

    change() {
        const enteredText = this.$input.val() || '';
        let match = false;
        for (let text of this.stamps) {
            match |= text === enteredText;
        }
        for (let text of this.deleted) {
            match |= text === enteredText;
        }
        if (this.$addEntry) {
            this.$addEntry.toggle(enteredText.length > 3 && !match);
            this.$addEntry.attr('data-text', enteredText);
            this.$addText.text(enteredText);
        }
    }

    addStamp(text) {
        $.ajax(this.url, { method: 'POST', data: { add: text } }).then((response) => {
            this.stamps = response.stamps;
            this.deleted = this.deleted.filter(t => t !== text);
            this.updateItems();
            this.change();
        });
    }

    deleteStamp(text) {
        $.ajax(this.url, { method: 'POST', data: { delete: text } }).then((response) => {
            this.stamps = response.stamps;
            this.deleted.push(text);
            this.updateItems();
            this.change();
        })
    }

    append(text, canDelete, canAdd, canUndelete) {
        if (this.readonly) {
            canDelete = false;
            canAdd = false;
            canUndelete = false;
        }
        const $entry = $('<div class="dropdown-item select"></div>').attr('data-text', text);
        if (canAdd) {
            $('<button class="btn btn-xs btn-success btn-add"></button>')
                .text(Translator.trans('button.add'))
                .appendTo($entry);
            this.$addEntry = $entry;
            $entry.addClass('add');
        }
        if (canUndelete) {
            $('<button class="btn btn-xs btn-warning btn-undelete"></button>')
                .text(Translator.trans('button.undelete'))
                .appendTo($entry);
            $entry.addClass('deleted');
        }
        const $span = $('<span class="text"></span>')
            .text(text)
            .appendTo($entry);
        if (canAdd) {
            this.$addText = $span;
        }
        $('<span class="spacer"></span>').appendTo($entry);
        if (canDelete) {
            $('<i class="btn-delete-activate fa fa-times"></i>')
                .appendTo($entry)
                .on('click', (e) => {
                    this.deleteStamp($entry.attr('data-text'));
                    e.preventDefault();
                    return false;
                });
        }
        $entry.appendTo(this.$menu);
        $entry.on('click', (e) => this.click(e));
    }

    click(e) {
        const $entry = $(e.currentTarget).closest('.select');
        const text = $entry.attr('data-text');
        if ($entry.hasClass('add') || $entry.hasClass('deleted')) {
            this.addStamp(text);
            e.preventDefault();
        } else {
            this.$input.val(text);
        }
    }

    updateItems() {
        this.$menu.empty();
        $('<h6 class="dropdown-header"></h6>')
            .text(Translator.trans('stamps.instructions'))
            .toggle(this.stamps.length === 0)
            .appendTo(this.$menu);
        for (let text of this.stamps) {
            this.append(text, true, false, false);
        }
        this.append('', false, true, false);
        for (let text of this.deleted) {
            this.append(text, false, false, true);
        }
    }
}