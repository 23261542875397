export class QuoteEditorRule
{
    constructor(editor, path, parameters, callback) {
        this.editor = editor;
        this.path = path;
        this.parameters = parameters;
        this.callback = callback;
    }

    isAffectedBy(path) {
        for (let i = 0; i < this.parameters.length; i++) {
            const params = this.parameters[i];
            if (this.editor.matchPath(path, params)) {
                return true;
            }
        }
        return false;
    }

    calculate() {
        let payload = [];
        for (let i = 0; i < this.parameters.length; i++) {
            payload.push(this.editor.getValueByPath(this.parameters[i]));
        }
        return this.callback(payload);
    }
}