import $ from 'jquery';
import '../../assets/vendor/libs/toastr/toastr';
import '../../assets/vendor/libs/toastr/toastr.css'
import './Flash.scss';

export class Flash
{
    constructor() {
        toastr.options.closeButton = true;
        toastr.options.progressBar = true;
        toastr.options.timeOut = 5000;
        toastr.options.extendedTimeOut = 2000;
        toastr.options.hideDuration = 300;
        this.warningType = 'warning';
        this.successType = 'success';
        this.errorType = 'error';
        this.infoType = 'info';
    }

    newId() {
        const ts = new Date();
        return 'flash_' + ts.getTime();
    }

    notify(message, title, notificationType = this.successType, onClick = null) {
        if (notificationType === 'notice') {
            notificationType = this.successType;
        }
        if (notificationType !== this.warningType && notificationType !== this.successType && notificationType !== this.errorType && notificationType !== this.infoType) {
            console.log('Flash: unrecognized type "' + notificationType + '"');
            notificationType = this.successType;
        }
        return toastr[notificationType](message, title, {
            onclick: onClick
        });
    }

    warning(message, title, onClick = null) {
        return this.notify(message, title, this.warningType, onClick);
    }

    error(message, title, onClick = null) {
        return this.notify(message, title, this.errorType, onClick);
    }

    success(message, title, onClick = null) {
        return this.notify(message, title, this.successType, onClick);
    }

    info(message, title, onClick = null) {
        return this.notify(message, title, this.infoType, onClick);
    }

    xhr(xhr) {
        if (xhr.status === 200) {
            if (xhr.responseJSON && xhr.responseJSON.flash) {
                for (const notificationType in xhr.responseJSON.flash) {
                    if (xhr.responseJSON.flash.hasOwnProperty(notificationType)) {
                        for (let i = 0; i < xhr.responseJSON.flash[notificationType].length; i++) {
                            const entry = xhr.responseJSON.flash[notificationType][i];
                            const message = entry.parameters === null ? entry.message :
                                Translator.trans(entry.message, entry.parameters);
                            this.notify(message, null, notificationType);
                        }
                    }
                }
            }
        } else {
            this.error(xhr.statusText, xhr.status);
        }
    }
}
