import $ from 'jquery';
import './Component.scss';

export function dashboardComponent() {
    new DashboardComponent(this);
}

class DashboardComponent {

    constructor(container) {
        this.$container = $(container);
        this.$modal = $('#dashboard-modal');
        if (this.$container.hasClass('component-expandable')) {
            this.$container.on('click', (event) => {
                const $target = $(event.target);
                if ($target.closest('a, button, input').length === 0) {
                    this.$modal.modal('show');
                    this.$modal.find('.modal-title').text(this.$container.attr('data-caption'));
                    this.$modal.find('.modal-body').empty().addClass('busy-indicator');
                    $.ajax(this.$container.attr('data-url')).then((response) => {
                        this.$modal.find('.modal-body').html(response);
                        this.$modal.find('a, button').on('click', () => {
                            this.$modal.modal('hide');
                        });
                    }).always(() => {
                        this.$modal.find('.modal-body').removeClass('busy-indicator');
                    });
                }
            });
        }
    }

}
