import $ from 'jquery';

export function ediProcess(options) {
    new EdiProcess($(this), options)
}

class EdiProcess
{
    constructor($container, options) {
        this.$container = $container;
        this.options = options;
        this.init();
        window.pageReloader.registerCallback(() => this.init());
    }

    init() {
        $('.edi-invoice-select').on('change', () => this.invoiceSelectionChanged());
        $('.edi-list-container').on('click', '.edi-invoice-control a', function() {
            let id = $(this).attr('data-element');
            $('#' + id).prop('checked', false).trigger('change');
        });
        $('#edi-list-add').on('click', () => this.addAll());
        $('#edi-list-clear').on('click', () => this.clearAll());
        this.invoiceSelectionChanged();
    }

    addAll() {
        $('.edi-invoice-select').each(function () {
            $(this).prop('checked', true).trigger('change');
        });
        return false;
    }

    clearAll() {
        $('.edi-invoice-select').each(function () {
            $(this).prop('checked', false).trigger('change');
        });
        return false;
    }

    invoiceSelectionChanged() {
        let list = [];
        $('.edi-invoice-select').each(function () {
            if ($(this).prop('checked')) {
                list.push($(this).val());
            }
        });
        const container = $('.edi-list-container');
        container.empty();
        for (let i = 0; i < list.length; i++) {
            let parts = list[i].split(':');
            let number = parts.length === 2 ? parts[0] : list;
            let account = parts.length === 2 ? parts[1] : null;
            let control = $('<span></span>').addClass('edi-invoice-control badge badge-primary m-1');
            $('<span></span>')
                .text(number)
                .appendTo(control);
            $('<a></a>')
                .attr('href', 'javascript:void(0);')
                .addClass('m-1')
                .attr('data-element', 'edi-invoice-' + number)
                .text('×')
                .appendTo(control);
            control.appendTo(container);
        }
        const $actionButton = $('[data-action=PROCESS_EDI]');
        if (list.length === 0) {
            $actionButton.attr('disabled', 'disabled').addClass('disabled');
        } else {
            $actionButton.removeAttr('disabled').removeClass('disabled');
        }
        const json = { invoices: list.join(',') };
        $actionButton.attr('data-parameters', JSON.stringify(json));
        $actionButton.attr('data-count', list.length);
    }
}
