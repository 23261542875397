import $ from 'jquery';

class FormDependencies
{
    constructor() {
        $(document).on('noviship:dependencies', () => this.attach());
        this.attach();
    }

    getSource($field, spec) {
        let failReturn = null;
        const source = spec.substr(0, spec.indexOf('='));
        let fieldName = $field.attr('data-form-name');
        if (fieldName === undefined) {
            fieldName = $field.attr('name');
        }
        if (fieldName === undefined) {
            let $control = $('[name="' + source + '"]');
            if ($control.length) {
                return $control;
            }
            const formName = $field.closest('form').attr('name');
            if (formName) {
                const sourceName = formName + '[' + source.replace(/\./g, '][') + ']';
                $control = $('[name="' + sourceName + '"]');
            }
            return $control;
        }
        const fieldNameParts = fieldName.replace(/\]/g, '').split('[');
        for (let i = fieldNameParts.length - 1; i > 0; i--) {
            let targetName = fieldNameParts[0];
            for (let j = 1; j < i; j++) {
                targetName += '[' + fieldNameParts[j] + ']';
            }
            targetName += '[' + source + ']';
            let $source = $('[name="' + targetName + '"]');
            if ($source.length > 1) {
                $source = $('[name="' + targetName + '"]:checked');
                failReturn = false;
            }
            if ($source.length === 1) {
                return $source;
            }
            // select2 multiple
            const select2name = targetName + '[]';
            $source = $('[name="' + select2name + '"]');
            if ($source.length === 1) {
                return $source;
            }
        }
        return failReturn;
    }

    doesMatch($field, spec) {
        let $source = this.getSource($field, spec);
        let currentValue;
        if ($source) {
            if ($source.length > 1) {
                $source = $source.filter(':checked');
            }
            const values = spec.substr(spec.indexOf('=') + 1).split(',');
            if ($source.attr('type') === 'checkbox') {
                currentValue = $source.is(':checked') ? '1' : '0';
                if ($.inArray(currentValue, values) !== -1) {
                    return true;
                }
            } else if ($source.attr('type') === 'radio') {
                currentValue = $source.val();
                if ($.inArray(currentValue, values) !== -1) {
                    return true;
                }
            } else {
                currentValue = $source.val();
                if ($.inArray(currentValue, values) !== -1) {
                    return true;
                }
                if (Array.isArray(currentValue)) {
                    for (let a of values) {
                        if (currentValue.indexOf(a) !== -1) {
                            return true;
                        }
                    }
                }
            }
        } else if ($source === null) {  /* $source will be false for multiple multiple matches, e.g. radios */
            console.log('FormDependencies: Cannot match ' + spec);
        }
        return false;
    }

    findTarget($element) {
        if ($element.hasClass('form-depends-target')) {
            return $element;
        }
        if ($element.parent().hasClass('input-group')) {
            $element = $element.parent();
        }
        if ($element.parent().hasClass('form-group')) {
            $element = $element.parent();
        } else if ($element.parent().parent().hasClass('form-group')) {
            $element = $element.parent().parent();
        }
        return $element;
    }

    setEnabledStatus($control, state) {
        if ($control.attr('data-role') === 'tagsinput') {
            $control.parent().find('.bootstrap-tagsinput').toggleClass('disabled', !state);
        }
        $control.prop('disabled', !state);
    }

    setVisibilityStatus($control, state) {
        if ($control.attr('data-role') === 'tagsinput' || $control.hasClass('form-group') || $control.hasClass('form-depends-target')) {
            if (state) {
                $control.show();
            } else {
                $control.hide();
            }
        } else {
            if (state) {
                $control.parent().show();
            } else {
                $control.parent().hide();
            }
        }
    }

    update() {
        $('[data-depends-show]').each((index, element) => {
            const $target = this.findTarget($(element));
            this.setVisibilityStatus($target, this.doesMatch($(element), $(element).attr('data-depends-show')));
        });
        $('[data-depends-hide]').each((index, element) => {
            const $target = this.findTarget($(element));
            this.setVisibilityStatus($target, !this.doesMatch($(element), $(element).attr('data-depends-hide')));
        });
        $('[data-depends-enable]').each((index, element) => {
            let $target = this.findTarget($(element));
            if (!$target.is('input') && !$target.is('select') && !$target.is('textarea')) {
                $target = $target.find('input.form-control,select.form-control,textarea.form-control');
            }
            this.setEnabledStatus($target, this.doesMatch($(element), $(element).attr('data-depends-enable')));
        });
        $('[data-depends-disable]').each((index, element) => {
            let $target = this.findTarget($(element));
            if (!$target.is('input') && !$target.is('select') && !$target.is('textarea')) {
                $target = $target.find('input.form-control,select.form-control,textarea.form-control');
            }
            this.setEnabledStatus($target, !this.doesMatch($(element), $(element).attr('data-depends-disable')));
        });
    }

    attach() {
        $('form').each((index, element) => {
            const $form = $(element);
            if ($form.attr('data-dependencies-observed') !== 'true') {
                $form.attr('data-dependencies-observed', 'true');
                $form.on('change', 'input,select', () => this.update());
                $form.on('click', '.btn-collection-add', () => this.update());
            }
        });
        this.update();
    }
}

$(() => {
    window.formDependencies = new FormDependencies();
});
