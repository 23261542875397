import { ViewModal } from '../modal/ViewModal';
import { FormModal } from '../modal/FormModal';
import { ActionModal } from '../modal/ActionModal';
import { TagParameter } from './TagParameter';
import { copyToClipboard } from '../util/Clipboard';
import { Ladda } from '../../assets/vendor/libs/ladda/ladda';

export class DataTableControl
{
    constructor(name, configuration) {
        window.dataTable = this;
        this.selectedRowIndex = null;
        this.name = name;
        this.configuration = configuration;
        this.refreshData = null;
        this.$table = $('#sg-datatables-' + name);
        this.dataTable = this.$table.DataTable();
        this.$wrapper = this.$table.closest('.dataTables_wrapper');
        this.$scroller = this.$table.closest('.dataTables_scrollBody');
        this.statusFragments = [];
        $(window).on('resize', () => this.updateTableSize());

        this.$lengthDiv = this.$wrapper.find('.dataTables_length');
        this.$lengthDiv.empty();
        this.$lengthDiv.parent().removeClass('col-sm-12').removeClass('col-md-6').addClass('col-6 col-sm-9');
        this.$wrapper.find('.dataTables_filter').parent().removeClass('col-sm-12').removeClass('col-md-6').addClass('col-6 col-sm-3');
        const $searchInput = this.$wrapper.find('.dataTables_filter input');
        $searchInput.attr('placeholder', Translator.trans('datatable.search'));

        const $iconConfigure = $('<i></i>').addClass('fa fa-cog');
        this.$buttonConfigure = $('<button></button>')
            .attr('href', '#')
            .attr('id', 'btn-table-configure')
            .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1')
            //.attr('data-toggle', 'tooltip')
            //.attr('title', Translator.trans('table.button_configure_hint'))
            .on('click', () => this.configureTable());
        //.attr('data-toggle', 'modal')
        //.attr('data-target', '#configure-table-modal');
        $iconConfigure.appendTo(this.$buttonConfigure);
        this.$buttonConfigure.appendTo($('<div></div>').appendTo(this.$lengthDiv));

        const $iconRefresh = $('<i></i>').addClass('fa fa-sync');
        this.$buttonRefresh = $('<button></button>')
            .attr('href', '#')
            .attr('id', 'btn-table-refresh')
            .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1')
            //.attr('data-toggle', 'tooltip')
            //.attr('title', Translator.trans('table.button_refresh_hint'))
            .on('click', () => this.refreshTable());
        $iconRefresh.appendTo(this.$buttonRefresh);
        this.$buttonRefresh.appendTo($('<div></div>').appendTo(this.$lengthDiv));
        this.$table.on('draw.dt', () => this.onTableDraw());

        const $groupDownload = $('<div></div>').addClass('dropdown').appendTo(this.$lengthDiv);
        const $buttonDownload = $('<button></button>')
            .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1')
            .attr('id', 'btn-table-export')
            .attr('data-toggle', 'dropdown')
            .appendTo($groupDownload);
        const $iconDownload = $('<i></i>').addClass('fa fa-download').appendTo($buttonDownload).attr('btn-export-table');
        const $menuDownload = $('<div></div>').addClass('dropdown-menu').appendTo($groupDownload);
        $('<a></a>', {
            'href': '#',
            'class': 'dropdown-item btn-table-export',
            'data-export': 'csv'
        }).text(Translator.trans('table.export_csv')).appendTo($menuDownload);
        $('<a></a>', {
            'href': '#',
            'class': 'dropdown-item btn-table-export',
            'data-export': 'excel'
        }).text(Translator.trans('table.export_excel')).appendTo($menuDownload);
        $('<a></a>', {
            'href': '#',
            'class': 'dropdown-item btn-table-export',
            'data-export': 'pdf'
        }).text(Translator.trans('table.export_pdf')).appendTo($menuDownload);
        $('<a></a>', {
            'href': '#',
            'class': 'dropdown-item btn-table-export',
            'data-export': 'clipboard'
        }).text(Translator.trans('table.export_clipboard')).appendTo($menuDownload);
        $('<a></a>', {
            'href': '#',
            'class': 'dropdown-item',
            'data-toggle': 'modal',
            'data-target': '#send-table-modal',
        }).text(Translator.trans('table.export_email')).appendTo($menuDownload);

        if (this.configuration.tag) {
            this.$tagButton = $('<button></button>')
                .attr('href', '#')
                .attr('id', 'btn-table-tag')
                .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1 popover-toggle')
                .attr('data-placement', 'right')
                .attr('data-state', 'info')
                .attr('data-title', Translator.trans('table.tag_button_title'))
                .appendTo($('<div></div>').appendTo(this.$lengthDiv));
            if (this.configuration.tag.tagId) {
                this.$tagButton.addClass('active');
            }
            const $iconTag = $('<i></i>').addClass('fa fa-tag').appendTo(this.$tagButton);
            const selectLabel = Translator.trans('table.tag_select');
            const $popoverHtml = $('<div><div class="form-group"><label for="table-tag-select">' +
                selectLabel + '</label><select name="table-tag-select" id="table-tag-select"></select></div>' +
                '<div><button class="btn btn-xs btn-secondary mr-1" id="tag-close">' +
                Translator.trans('button.close') + '</button><button class="btn btn-xs btn-secondary" id="tag-clear">' +
                Translator.trans('button.clear') + '</button></div>');
            this.$tagButton.popover({
                html: true,
                content: () => $popoverHtml.html()
            });
            this.$tagButton.on('click', () => {
                const $select = $('#table-tag-select');
                if (this.configuration.tag) {
                    const option = new Option(this.configuration.tag.tagName, this.configuration.tag.tagId, true, true);
                    $select.append(option);
                }
                $select.select2({
                    ajax: {
                        url: this.configuration.tag.selectPath
                    }
                }).closest('.popover').css('width', '550px');
                $select.on('select2:select', (e) => {
                    this.selectTag(e.params.data ? e.params.data.id : null, e.params.data ? e.params.data.text : null);
                    this.$tagButton.popover('hide');
                })
                $('#tag-close').on('click', () => this.$tagButton.popover('hide'));
                $('#tag-clear').on('click', () => {
                    this.$tagButton.popover('hide');
                    this.selectTag(null, null);
                });
            });
        }

        if ($('#sg-datatables-' + this.name + '-checkall').length === 1) {
            const $iconSelectAll = $('<i></i>').addClass('fa fa-check-double');
            this.$buttonSelectAll = $('<button></button>')
                .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1')
                .attr('href', '#');
            $iconSelectAll.appendTo(this.$buttonSelectAll);
            this.$buttonSelectAll.appendTo($('<div></div>').appendTo(this.$lengthDiv));
            this.$buttonSelectAll.on('click', () => this.selectAll());
        }

        $('<span></span>').addClass('ml-4 header-buttons-end').appendTo(this.$lengthDiv);


        this.$tableInfo = this.$wrapper.find('.dataTables_info');
        this.$tableInfo.addClass('mr-1').parent().addClass('pt-1');
        this.$selectionInfo = $('<div></div>', {
            'class': 'dataTables_selectionInfo'
        }).insertAfter(this.$tableInfo);

        $(document).on('draw.dt', '.dataTable', () => this.updateTableControls());
        $(document).on('change', 'input[type=checkbox]', () => this.selectionChanged());
        // also attach to label clicks for Bootstrap 4 Custom Controls
        $(document).on('click', 'label.custom-control-label, .dataTable tr', (event) => this.rowClicked(event));

        $('#configure-table-modal .sortable').sortable();

        this.updateTableControls();

        $(document).on('click', '.datatable-view', function (event) {
            event.preventDefault();

            const modal = new ViewModal();
            modal.openFromButton(this);
            return false;
        });

        $(document).on('click', '.datatable-form', (event) => {
            event.preventDefault();
            const $button = $(event.currentTarget);
            const modal = new FormModal('form-modal');
            if ($button.hasClass('datatable-multiselect')) {
                modal.openFromMultiSelectButton($button, this);
            } else {
                modal.openFromButton($button);
            }
            return false;
        });

        $(document).on('click', '.datatable-action', (event) => {
            event.preventDefault();
            const $button = $(event.currentTarget);
            const modal = new ActionModal('action-modal');
            if ($button.hasClass('datatable-multiselect')) {
                modal.openFromMultiSelectButton($button, this);
            } else {
                modal.openFromButton($button);
            }
            return false;
        });

        $(document).on('click', '.datatable-get', function (event) {
            event.preventDefault();
            const url = $(this).attr('data-href') + '?ids=' + $(this).attr('data-selection');
            window.location.href = url;
        });

        $(document).on('click', '.dataTable thead th input[type=checkbox]', function () {
            $(this).closest('.dataTables_wrapper').find('input.datatable-row-select').prop('checked', $(this).prop('checked'));
        });

        $(document).on('click', '.btn-table-export', function () {
            const ajax = $('.dataTable').DataTable().ajax;
            const button = $('#btn-export-table');
            let params = ajax.params();
            params.action = 'export';
            params.format = $(this).attr('data-export');
            params.length = 9999;
            params.start = 0;

            Pace.track(() => $.ajax({
                method: 'POST',
                url: ajax.url(),
                data: params,
                success: function (response) {
                    if (params.format === 'clipboard') {
                        $.ajax(response.url).then((html) => copyToClipboard(html));
                    } else {
                        $('<iframe></iframe>')
                            .attr('src', response.url)
                            .hide()
                            .appendTo('body');
                    }
                },
                complete: function () {
                    button.removeClass('fa-spin');
                }
            }));
        });


    }

    updateXhrData(data) {
        console.log('called getParameters with ', data);
        data.tag = this.configuration.tag ? this.configuration.tag.tagId : null;
        return data;
    }

    selectTag(tagId, tagName) {
        this.configuration.tag.tagId = tagId;
        this.configuration.tag.tagName = tagName;
        if (tagId) {
            this.$tagButton.addClass('active');
        } else {
            this.$tagButton.removeClass('active');
        }
        this.refreshTable();
    }

    configureView(caption) {
        $('.display-page-title').text(caption);
        this.$buttonConfigure.hide();
    }

    configureCustomOptions(customOptions) {
        for (let i = 0; i < customOptions.length; i++) {
            let option = customOptions[i];
            switch (option.type) {
                case 'boolean':
                    const $boolean = $('<label></label>', { 'class': 'switcher' }).appendTo(this.$lengthDiv);
                    const $option = $('<input>', {
                        'type': 'checkbox',
                        'class': 'switcher-input datatable-custom-option',
                        'data-type': 'boolean',
                        'name': option.name
                    }).appendTo($boolean);
                    if (option.value === '1') {
                        $option.attr('checked', 'checked');
                    }
                    const $switcher = $('<span></span>', { 'class': 'switcher-indicator' }).appendTo($boolean);
                    $('<span></span>', { 'class': 'switcher-yes' }).appendTo($switcher);
                    $('<span></span>', { 'class': 'switcher-no' }).appendTo($switcher);
                    $('<span></span>', { 'class': 'switcher-label '}).text(option.caption).appendTo($boolean);
                    $option.on('change', () => {
                        $('input[name="preferences[customOptions][' + option.name + ']"]')
                            .val($option.attr('checked') === 'checked' ? '1' : '0');
                        this.$buttonRefresh.trigger('click');
                    });
                    break;
                case 'status':
                    const $panel = $('<span></span>', { 'class': 'table-status-fragment', 'id': option.name })
                        .appendTo(this.$lengthDiv);
                    this.statusFragments.push({ url: option.parameters['url'], selector: '#' + option.name });
                    break;
            }
        }
        this.updateTableFragments();
    }

    configureTag(id, progressUrl, caption, showProgress, editUrl, parameterUrl) {
        this.tag = id;
        this.tagParameterHandler = new TagParameter(parameterUrl);
        const $buttonProgress = $('<button></button>')
            .addClass('btn btn-icon icon-btn btn-sm btn-outline-secondary borderless ml-1')
            .attr('id', 'btn-table-progress')
            .attr('data-id', id)
            .attr('data-url', progressUrl)
            .attr('href', '#')
            .insertBefore($('.header-buttons-end'));
        const $iconProgress = $('<i></i>').addClass('fa fa-tasks').appendTo($buttonProgress);
        if (showProgress) {
            $buttonProgress.addClass('active');
        }
        $buttonProgress.on('click', (event) => {
            const $button = $(event.currentTarget);
            const url = $button.attr('data-url');
            let active = $(event.currentTarget).hasClass('active');
            $.ajax(url, { data: { active: active ? 0 : 1 }}).then(() => {
                this.refreshTable();
                $button.toggleClass('active');
            })
        });
        const $panel = $('<span></span>', {
            'class': 'table-status-fragment',
            'style': 'cursor: pointer;',
            'id': 'table_tag_progress'
        }).appendTo(this.$lengthDiv);
        $panel.popover({
            'html': true,
            'content': '<a href="' + editUrl + '" class="btn btn-xs btn-info">' +
                Translator.trans('button.edit_tag') +
                '</a> <a href="#" class="btn btn-xs btn-secondary btn-reset-progress">' +
                Translator.trans('button.reset_progress') + '</a>',
            'title': caption,
            'trigger': 'click'
        });
        this.statusFragments.push({ url: progressUrl, selector: '#table_tag_progress' });
        this.updateTableFragments();
        $(document).on('click', '.btn-reset-progress', (event) => {
            $panel.popover('hide');
            $.ajax(progressUrl, {
                data: { 'reset': 1 },
            }).then(this.refreshTable());
        });
    }

    isSelectAll() {
        return this.$wrapper.hasClass('selectAll');
    }

    selectionChanged() {
        this.deselectAll();
        this.updateTableControls();
    }

    updateTableFragments() {
        for (let i = 0; i < this.statusFragments.length; i++) {
            const fragment = this.statusFragments[i];
            $.ajax(fragment.url).then((response) => {
                $(fragment.selector).html(response);
            });
        }
    }

    updateTableControls() {
        let ids = this.getSelection();
        if (ids.length === 0) {
            $('.datatable-multiselect').addClass('disabled').removeAttr('data-selection');
            this.$selectionInfo.text('');
        } else {
            $('.datatable-multiselect').removeClass('disabled').attr('data-selection', ids.join(','));
            let count = ids.length;
            if (this.isSelectAll()) {
                const info = this.dataTable.page.info();
                count = info.recordsDisplay;
            }
            const text = '(' + Translator.trans('datatable.selection', { 'count': count }) + ')';
            this.$selectionInfo.text(text);
        }
        $('.datatable-multiselect[href]').each(function () {
            $(this).attr('data-href', $(this).attr('href'));
            $(this).removeAttr('href');
            $(this).attr('tabindex', '0');
        });
        $('.datatable-link').on('click', function (event) {
            let url = $(this).attr('data-href') + '?ids=' + $(this).attr('data-selection');
            const l = Ladda.create(event.currentTarget);
            l.start();
            window.downloader.get(url, () => l.stop());
        });
        this.updateTableSize();
    }

    updateTableSize() {
        const $table = $('.dataTables_scrollBody');
        if (this.$table.hasClass('table-vertical-scrolling') && $table.length) {
            const top = $table.offset().top;
            const buttonsHeight = $('#table-buttons-container').height();
            const windowHeight = $(window).height();
            const headerHeight = $('.dataTables_wrapper .row:first-child').height();
            const footerHeight = $('.dataTables_wrapper .row:last-child').height();
            let space = windowHeight - (top + buttonsHeight + headerHeight + footerHeight + 28);
            if ($table.closest('.card').length === 0) {
                space += 28;
            }
            space = Math.max(space, 180);
            $table.css('height', space + 'px');
        }
    }

    configureTable() {
        $('#configure-table-modal').modal();
    }

    refreshTable() {
        this.refreshData = {
            selectAll: this.isSelectAll(),
            selectedIds: this.getSelection(),
            scrollTop: this.$scroller.scrollTop(),
        };
        this.$buttonRefresh.find('i').addClass('fa-spin');
        this.$table.dataTable().fnDraw();
        this.updateTableFragments();
    }

    onTableRefreshed() {
        if (this.refreshData.selectAll) {
            this.selectAll();
        } else {
            this.setSelection(this.refreshData.selectedIds);
        }
        this.$scroller.scrollTop(this.refreshData.scrollTop);
    }

    onTableDraw() {
        this.$buttonRefresh.find('i').removeClass('fa-spin');
        this.selectionChanged();
        if (this.refreshData) {
            this.onTableRefreshed();
            this.refreshData = null;
        }
    }

    selectAll() {
        if (this.$buttonSelectAll) {
            const selected = !this.$buttonSelectAll.hasClass('active');
            this.$wrapper.find('.custom-checkbox input').prop('checked', selected);
            this.$wrapper.toggleClass('selectAll', selected);
            this.$buttonSelectAll.toggleClass('active', selected);
            this.updateTableControls();
        }
    }

    deselectAll() {
        if (this.$buttonSelectAll) {
            this.$wrapper.removeClass('selectAll');
            this.$buttonSelectAll.removeClass('active');
        }
    }

    getSelection() {
        let ids = [];
        $('input.datatable-row-select:checked').each((index, element) => {
            ids.push($(element).val());
        });
        return ids;
    }

    setSelection(ids) {
        this.$table.find('input.datatable-row-select').each((index, element) => {
            const id = $(element).val();
            const selected = (ids.indexOf(id) >= 0);
            $(element).prop('checked', selected);
            $(element).closest('tr').toggleClass('selected', selected);
        });
    }

    fetchSelectedIdentifiers(callback) {
        if (this.isSelectAll()) {
            const ajax = this.dataTable.ajax;
            let params = ajax.params();
            params.action = 'identifiers';
            params.length = 9999;
            params.start = 0;

            Pace.track(() => $.ajax({
                method: 'POST',
                url: ajax.url(),
                data: params,
                success: (response) => callback(response.ids),
            }));
        } else {
            callback(this.getSelection());
        }
    }

    rowClicked(event) {
        let index = $(event.currentTarget).closest('tr').index();
        if (this.selectedRowIndex !== null && event.shiftKey) {
            if (index < this.selectedRowIndex) {
                const n = this.selectedRowIndex;
                this.selectedRowIndex = index;
                index = n;
            }
            const checked = $(event.currentTarget).closest('tr').find('input.datatable-row-select').prop('checked');
            $(event.currentTarget).closest('tbody').find('tr').each((idx, element) => {
                if (idx >= this.selectedRowIndex && idx <= index) {
                    $(element).find('input.datatable-row-select').prop('checked', checked);
                }
            });
            if (document.getSelection) {
                document.getSelection().empty();
            }
        }
        this.selectedRowIndex = index;
        this.selectionChanged();
    }

    configureHeaderSummaries(list) {
        for (let id of list) {
            const $th = $('th[data-column-id="' + id + '"');
            if ($th.length === 0) {
                continue;
            }
            const caption = $th.html();
            const div = '<div class="d-flex justify-content-between"><span class="column-caption-container">' + caption + '</span>';
            $th.html(div);
            const $summary = $('<a></a>', {
                'class': 'btn btn-xs rounded-pill btn-outline-secondary borderless icon-btn',
                'href': '#'
            });
            if ($th.hasClass('text-right')) {
                $summary.insertBefore($th.find('.column-caption-container')).css('margin-right', '6px');
            } else {
                $summary.insertAfter($th.find('.column-caption-container')).css('margin-left', '6px');
            }
            const $icon = $('<i></i>', { 'class': 'fa fa-sigma' }).appendTo($summary);
            const displayId = 'fragment-' + Math.ceil(Math.random() * 100000);
            const template = `<div class="popover popover-info" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`;
            const content = `<div id="${displayId}" style="min-width: 200px; min-height: 50px;"></div>`;
            $summary.popover({
                'content': content,
                'template': template,
                'trigger': 'manual',
                'html': true,
                'title': caption
            });
            $summary.on('shown.bs.popover', () => {
                const ajax = this.dataTable.ajax;
                let params = ajax.params();
                params.action = 'summary';
                params.columnId = id;
                const $display = $('#' + displayId);
                $display.empty().addClass('busy-indicator');
                $.ajax({
                    method: 'POST',
                    data: params,
                    url: ajax.url(),
                    success: (response) => {
                        $display.html(response);
                    },
                    complete: () => { $display.removeClass('busy-indicator')}
                });
                $('body').one('click', () => $summary.popover('hide'));
            });
            $summary.on('click', (event) => {
                event.preventDefault();
                $summary.popover('show');
                return false;
            });
        }
    }
}
