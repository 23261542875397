import $ from 'jquery';

class EdiStatus
{

    constructor(container) {
        this.container = container;
        window.pageReloader.registerCallback(() => this.init());
    }

    init() {
        $('#edi-status-refresh').on('click', () => this.update());
        this.update();
    }

    getStatusUrl() {
        return $(this.container).attr('data-url');
    }

    /**
     * @param {{
     *   customer_invoices_own_ready: any,
     *   customer_invoices_other_ready: any
     * }} data
     */
    processData(data) {
        $('.edi-status-property').each(function() {
            const bind = $(this).attr('data-bind');
            if (data.hasOwnProperty(bind)) {
                $(this).text(data[bind]);
                $(this).toggle(data[bind] !== "0" && data[bind] !== 0 && data[bind] !== "");
            }
        });
        $('#edi-status-flagged').toggle(data.flagged_own_shipments > 0);
        $('#edi-status-cost-flagged').toggle(data.cost_flagged_shipments > 0);
    }

    updateFinished() {
        $('#edi-status-refresh i').removeClass('fa-spin');
    }

    update() {
        $('#edi-status-refresh i').addClass('fa-spin');
        $.ajax(this.getStatusUrl()).then((data) => this.processData(data)).always(() => this.updateFinished());
    }
}

$(function () {
    $('#edi-status-container').each(function () {
        let status = new EdiStatus(this);
        status.init();
        window.statusManager.onTaskCompletion(null, () => status.update());
    });
});
