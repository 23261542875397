import showdown from 'showdown';
import $ from 'jquery';

export function markdownRender(text) {
    const markdown = new showdown.Converter({
        tables: true,
        extensions: [
            {
                type: 'output',
                regex: /\<table/g,
                replace: '<table class="table"'
            },
            {
                type: 'output',
                regex: /\<img src\=\"([^\.]*)\.(?!jpg|gif|png|bmp|jpeg)([a-z]+)\" alt\=\"([^\"]+)\" \/\>/g,
                replace: '<i class="fa fa-paperclip"></i> <a class="file-download" href="$1.$2" download>$3</a>'
            },
            {
                type: 'output',
                regex: /\<img /g,
                replace: '<img class="img-fluid" '
            }
        ]
    });
    return markdown.makeHtml(text);
}

$(function () {
    $('.markdown-render').each((index, element) => {
        let text = $(element).text();
        let html = markdownRender(text);
        $(element).html(html);
    })
});