import $ from 'jquery';
import 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites';
import 'jsuites/dist/jsuites.css';

export function tableCollection(options) {
    return new TableCollection(this, options);
}

class TableCollection {

    constructor (element, options) {
        window.spreadsheet = this;
        this.$element = $(element);
        this.options = options;
        this.busy = false;
        this.$form = this.$element.closest('form');
        this.$errorContainer = $('<div></div>').insertBefore(this.$element);
        this.$dataContainer = $('<div></div>').insertAfter(this.$element);
        this.$addButton = $(options.addButton);
        this.empty = options.data.length === 0;
        //this.$element.on('focus', () => this.focused = true);
        //this.$element.on('blur', () => this.focused = false);
        // this.$element.on('keydown', (event) => {
        //     if (event.keyCode === 13) {
        //         event.stopPropagation();
        //     }
        // })
        this.$element.on('keydown', (event) => {
            if (event.keyCode === 13) {
                this.busy = true;
                setTimeout(() => this.busy = false, 100);
            }
        })
        this.init();
    }

    init() {
        const settings = {
            data: this.options.data,
            columns: this.options.columns.map((column) => ({
                title: column.label,
                type: column.type ? column.type : (column.choices.length ? 'autocomplete' : 'text'),
                source: column.choices.length ? column.choices.map((choice) => ({
                    id: choice.id,
                    name: choice.name
                })) : null,
                width: column.width ? column.width : 140
            })),
            contextMenu: false,
            allowInsertRow: true,
            allowManualInsertRow: true,
            allowInsertColumn: false,
            allowManualInsertColumn: false,
            allowDeleteRow: true,
            allowDeleteColumn: false,
            autoIncrement: false,
            onchange: (instance, cell, x, y) => this.highlight(y, x, false),
            onafterchanges: () => this.onChange(),
            ondeleterow: () => this.onChange(),
            footers: this.getFooters()
        };
        this.spreadsheet = this.$element.jspreadsheet(settings);
        this.$element.find('thead td').each((index, element) => {
            const title = $(element).attr('title');
            const column = this.options.columns.find((c) => c.label === title);
            if (column && column.help) {
                const $span = $('<span></span>', { 'title': column.help, 'data-state': 'primary' }).text(column.label);
                $(element).empty().append($span);
                $span.tooltip();
            }
        });
        this.onChange();
        this.$form.on('submit', (event) => this.beforeSubmit(event));
        this.$form.on('modal:submit', (event) => this.beforeSubmit(event));
        this.$addButton.on('click', (event) => {
            if (this.empty) {
                this.spreadsheet.setData([[]]);
                this.empty = false;
            } else {
                this.spreadsheet.insertRow();
                this.spreadsheet.setFooter(this.getFooters());
            }
            event.preventDefault();
            return false;
        });
        this.updateErrors();
    }

    onChange() {
        this.spreadsheet.setFooter(this.getFooters());
    }

    footerRequired() {
        return !!this.options.columns.find((c) => c.sum);
    }

    getFooters() {
        if (this.footerRequired()) {
            let footer = this.options.columns.map((c) => c.sum ? '0' : '');
            if (this.spreadsheet) {
                const data = this.spreadsheet.getData();
                data.forEach((r) => {
                    for (let x = 0; x < this.options.columns.length; x++) {
                        if (this.options.columns[x].sum) {
                            if (parseFloat(r[x])) {
                                footer[x] = (parseFloat(footer[x]) + parseFloat(r[x])).toFixed(2);
                            }
                        }
                    }
                });
            }
            return [ footer ];
        } else {
            return [];
        }
    }

    identifyCell(row, column) {
        return this.options.columns[column].label + ' #' + (row + 1);
    }

    highlight(row, column, state = true) {
        const cell = String.fromCharCode(65 + parseInt(column)) + (parseInt(row) + 1);
        const current = this.spreadsheet.getStyle(cell, 'background-color');
        if (state && current !== 'orange') {
            this.spreadsheet.setStyle(cell, 'background-color', 'orange');
        } else if (!state && current === 'orange') {
            this.spreadsheet.setStyle(cell, 'background-color', 'inherit');
        }
    }

    updateErrors() {
        this.$errorContainer.empty();
        if (this.options.errors.length) {
            this.$errorContainer.addClass('alert alert-danger');
            this.options.errors.forEach((error) => {
                const $error = $('<div></div>').appendTo(this.$errorContainer);
                $('<span></span>', { class: 'badge badge-outline-secondary mr-2' })
                    .text(this.identifyCell(error.row, error.column)).appendTo($error);
                $('<span></span>').text(error.message).appendTo($error);
                this.highlight(error.row, error.column);
            });
        }
    }

    beforeSubmit(event) {
        if (this.busy) {
            return false;
        }
        console.log(event);
        this.$dataContainer.empty();
        const data = this.spreadsheet.getData();
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].length; j++) {
                let value = data[i][j];
                if (value === false) {
                    value = '';
                    continue;
                }
                if (value === true) {
                    value = '1';
                }
                const column = this.options.columns[j];
                $('<input>', {
                    type: 'hidden',
                    name: column.name.replace('__name__', i),
                    value
                }).appendTo(this.$dataContainer);
            }
        }
    }
}
