import { Modal } from './Modal'
import { Form } from '../form/Form';
import { DeferredControl } from './DeferredControl';
import moment from 'moment-timezone';
import { ActionBookmarkControl } from './ActionBookmarkControl';
import { ActionParameterControl } from './ActionParameterControl';

export class FormModal extends Modal
{
    constructor(id) {
        super(id);
        this.idHistory = [];
        this.nextCount = 0;
        this.clear();
        this.deferredControl = new DeferredControl(this.getModal());
        this.bookmarkControl = new ActionBookmarkControl(this);
        this.actionParameterControl = new ActionParameterControl(this);
        this.onButtonClick('submit', () => this.submit());
        this.onButtonClick('skip', () => this.skip());
        this.getModal().draggable({handle: '.modal-header'}).find('.modal-header').css('cursor', 'grab');
    }

    isNextOnSuccess() {
        const $control = $('#action-auto-next');
        return $control.is(':checked') && $control.is(':visible');
    }

    enableNextOnSuccess(enabled) {
        if (!enabled) {
            $('#action-auto-next').prop('checked', false);
        }
        const remaining = this.countNextItems();
        if (remaining === 0) {
            enabled = false;
        }
        $('.action-auto-next').toggle(enabled);
        this.getButtonByName('skip').toggle(enabled);
        const counter = $('#action-auto-next-count');
        if (enabled) {
            let button = this.findNextActionButton();
            if (button) {
                this.enableButton('skip');
            } else {
                this.disableButton('skip');
            }
            counter.text(remaining).show();
        } else {
            counter.hide();
        }
    }

    applyApprover(approver) {
        this.approver = approver;
        if (this.approver) {
            const approverStatement = Translator.trans('action.approval_required_by',
                { 'approver': this.approver });
            this.addBanner($('<i class="fa fa-shield"></i> <span>' + approverStatement + '</span>'));
        }
    }

    getForm() {
        return this.getBody().find('form');
    }

    openFromButton(button) {
        this.tableMode = true;
        this.$button = $(button);
        this.clear();
        this.action = this.$button.attr('data-action');
        this.actionTitle = this.$button.attr('data-title');
        this.reloadAfter = this.$button.attr('data-on-success');
        this.bookmarkControl.update();
        this.actionParameterControl.reset();
        this.applyApprover(this.$button.attr('data-approver'));
        this.uiCount = this.$button.attr('data-count') ? parseInt(this.$button.attr('data-count')) : null;
        this.setTitle(this.actionTitle);
        this.setVisibleButtons(['close']);
        this.enableNextOnSuccess(this.$button.hasClass('action-allow-next'));
        this.url = this.$button.attr('href');
        this.ids = [];
        const urlMatch = this.url.match(/ids=([0-9]+)/);
        if (urlMatch && urlMatch[1]) {
            this.ids.push(urlMatch[1]);
        }
        if (this.$button.attr('data-ids')) {
            this.ids = this.$button.attr('data-ids').split(',');
        }
        this.getModalTools().empty();
        this.startBusy();
        this.show();
        this.open();
    }

    countNextItems() {
        let nextCount = 0;
        $('[data-action="' + this.action + '"]').each((index, element) => {
            const id = $(element).closest('tr').find('input.datatable-row-select').val();
            if (this.idHistory.indexOf(id) === -1) {
                nextCount++;
            }
        });
        return nextCount - (this.idHistory.length > 0 ? 1 : 2);
    }

    findNextActionButton() {
        let button = null;
        this.idHistory = this.idHistory.concat(this.ids);
        $('[data-action="' + this.action + '"]').each((index, element) => {
            if (button === null) {
                const id = $(element).closest('tr').find('input.datatable-row-select').val();
                if (this.idHistory.indexOf(id) === -1) {
                    button = element;
                }
            }
        });
        return button;
    }


    openFromMultiSelectButton(button, table) {
        this.table = table;
        this.tableMode = true;
        this.$button = $(button);
        this.clear();
        this.action = this.$button.attr('data-action');
        this.actionTitle = this.$button.attr('data-title');
        this.reloadAfter = this.$button.attr('data-on-success');
        this.applyApprover(this.$button.attr('data-approver'));
        this.bookmarkControl.update();
        this.actionParameterControl.reset();

        this.setVisibleButtons(['close']);
        this.enableNextOnSuccess(this.$button.hasClass('action-allow-next'));
        this.url = this.$button.attr('data-url');
        this.getModalTools().empty();
        this.startBusy();
        this.show();
        this.setTitle(this.actionTitle);

        table.fetchSelectedIdentifiers((ids) => {
            this.ids = ids;
            const counter = this.uiCount > 0 ? this.uiCount : this.ids.length;
            this.setTitle(this.actionTitle,
                Translator.transChoice('table.selection_count', counter, { '%count%' : counter }));
            this.open();
        });
    }

    deferClick(event) {
        const $option = $(event.currentTarget);
        const $button = $option.closest('.btn-group').find('button');
        const $control = this.getForm().find('.action-defer-time');
        if ($option.attr('data-defer-clear') === '1') {
            $button.text($option.text());
            $control.val('');
            return;
        }
        let time = moment();
        let hour = parseInt($option.attr('data-defer-hour'));
        let day = parseInt($option.attr('data-defer-day'));
        let hours = parseInt($option.attr('data-defer-hours'));
        if (hours) {
            time.add(hours, 'hours');
        }
        if (hour) {
            time.hour(hour);
        }
        if (day) {
            while (time.day() !== day) {
                time.add(1, 'days');
            }
        }
        $button.text(time.format('ddd, MMM Do h:mm a'));
        $control.val(time.utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
    }

    open() {

        //this.getModal().find('.action-defer-group .dropdown-item').on('click', (event) => this.deferClick(event));
        this.deferredControl.reset();

        let data = {};
        if (this.ids.length > 0) {
            // string necessary otherwise the browser will limit the array to 1000 items
            data.ids = this.ids.join(',');
        }
        const url = this.$button.attr('data-url') ? this.$button.attr('data-url') : this.$button.attr('href');
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: (response) => {
                const $response = $(response);
                const $content = $($response.find('.modal-content').html());
                this.addContent($content);
                this.getModalTools().html($response.find('.modal-tools').html());
                const parameters = this.$button.attr('data-parameters');
                if (parameters) {
                    const data = JSON.parse(parameters);
                    const form = new Form(this.getForm());
                    form.setData(data);
                }
                const title = $response.find('.modal-title').text().trim();
                let subTitle = $response.find('.modal-entity-name').text().trim();
                if (this.ids.length > 1 || (subTitle === '' && this.ids.length === 1)) {
                    subTitle = Translator.transChoice('table.selection_count', this.ids.length, { '%count%' : this.ids.length });
                }
                this.setTitle(title ? title : this.actionTitle, subTitle);
                this.setVisibleButtons(['defer', 'submit'], ['skip']);
                /* hook into Assistant if loaded */
                if (window.assistant) {
                    window.assistant.run();
                }
                if (window.formDependencies) {
                    window.formDependencies.attach();
                }
                /* trigger change dependent features like select2entity() */
                this.getModal().trigger('content-loaded');
                this.actionParameterControl.reset();
            },
            complete: () => this.stopBusy(),
            error: (xhr /*, statusText, errorThrown*/) => {
                this.getBody().html(xhr.responseText);
            }
        })
    }

    onTaskCompleted(task) {
        if (task.link == null) {
            return;
        }
        if (task.output === 'REDIRECT') {
            $('<button></button>', {
                'class': 'btn btn-primary',
                'href': task.link
            }).text(Translator.trans('button.continue')).appendTo(this.getBody());
        } else {
            this.load(task.link, () => {
                this.setVisibleButtons(['close']);
            });
        }
    }

    skip() {
        this.getModal().find('.table-entity-list').each((index, element) => {
            $(element).val(this.ids.join(','));
        });
        let nextButton = this.findNextActionButton();
        if (nextButton) {
            if (this.tableMode) {
                $('.dataTable').DataTable().ajax.reload(null, false);
            }
            this.openFromButton(nextButton);
        }
    }

    submit() {
        if (!this.preSubmitValidate()) {
            return;
        }
        this.getModal().find('.table-entity-list').each((index, element) => {
            $(element).val(this.ids.join(','));
        });
        this.startBusy('submit');
        this.getForm().trigger('modal:submit');
        const data = this.getForm().serialize();
        const url = this.$button.attr('data-url') ? this.$button.attr('data-url') : this.$button.attr('href');
        $.ajax({
            method: 'POST',
            url: url,
            data: data,
            success: (response) => {
                if (response.success) {
                    if (response.flash) {
                        if (response.completed === false) {
                            window.statusManager.onTaskCompletion(response.channel,
                                (task) => window.statusManager.flashTask(task));
                        }
                    }
                    let nextButton = null;
                    if (this.isNextOnSuccess()) {
                        nextButton = this.findNextActionButton();
                    }
                    if (nextButton) {
                        if (this.tableMode && window.dataTable) {
                            window.dataTable.refreshTable();
                            //$('.dataTable').DataTable().ajax.reload(null, false);
                        }
                        this.openFromButton(nextButton);
                    } else {
                        if (response.async) {
                            this.clear();
                            this.addStatus(this.$button.attr('data-processing'));
                            this.addProgressBar();
                            this.getStatus().addClass('task-status-text-' + response.channel);
                            this.getProgressBar().addClass('task-progressbar-' + response.channel);
                            this.addContentText('', 'text-right task-progress-' + response.channel);
                            this.setVisibleButtons(['dismiss']);
                            window.statusManager.onTaskCompletion(response.channel, (task) => this.onTaskCompleted(task));
                        }
                        this.onHide(() => {
                            if (this.reloadAfter === 'reload') {
                                window.pageReloader.reload();
                            } else if (this.reloadAfter === 'navigate') {
                                window.location.reload();
                            } else if (this.reloadAfter === 'caption') {
                                window.pageReloader.reloadElement(this.$button);
                            } else {
                                $('.dataTable input[type=checkbox]').prop('checked', false).prop('indeterminate', false);
                                if (this.tableMode) {
                                    window.dataTable.refreshTable();
                                    //$('.dataTable').DataTable().ajax.reload(null, false);
                                }
                            }
                        });
                        if (response.completed) {
                            this.hide();
                        }
                        if (response.flash && response.completed === false) {
                            this.hide();
                        }
                    }
                    window.statusManager.activity();
                } else if (response.errors) {
                    for (let i = 0; i < response.errors.length; i++) {
                        const error = response.errors[i];
                        let text = error.message;
                        if (error.template) {
                            text = Translator.trans(error.template, error.parameters);
                        }
                        this.addError(text);
                    }
                    this.setVisibleButtons(['close']);
                } else {
                    const $response = $(response);
                    const $content = $($response.find('.modal-content').html());
                    this.clear();
                    this.addContent($content);
                    $('.select2entity[data-autostart="true"]').select2entity();
                    window.formDependencies.attach();
                }
            },
            error: (xhr, statusText, errorThrown) => {
                this.getBody().html(xhr.responseText);
            },
            complete: () => this.stopBusy()
        });
    }
}
