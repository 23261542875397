import { Modal } from './Modal.js'

export class ActionModal extends Modal
{
    applyApprover(approver) {
        this.approver = approver;
        if (this.approver) {
            const approverStatement = Translator.trans('action.approval_required_by',
                { 'approver': this.approver });
            this.addBanner($('<i class="fa fa-shield"></i> <span>' + approverStatement + '</span>'));
        }
    }

    openFromButton(button) {
        this.tableMode = true;
        this.$button = $(button);
        this.clear();
        this.action = this.$button.attr('data-action');
        this.setTitle(this.$button.attr('data-title'));
        this.applyApprover(this.$button.attr('data-approver'));
        this.reloadAfter = this.$button.attr('data-on-success') === 'reload';
        this.addContentText(this.$button.attr('data-description'));
        this.setVisibleButtons(['yes', 'no']);
        this.url = this.$button.attr('href');
        this.ids = [this.url.match(/ids=([0-9]+)/)[1]];
        this.onButtonClick('yes', () => { this.startClick(); });
        this.show();
    }

    openFromMultiSelectButton(button, table) {
        this.table = table;
        this.tableMode = true;
        this.$button = $(button);
        this.clear();
        this.ids = [];
        this.action = this.$button.attr('data-action');
        this.reloadAfter = this.$button.attr('data-on-success') === 'reload';
        this.actionTitle = this.$button.attr('data-title');
        this.setTitle(this.actionTitle);
        this.applyApprover(this.$button.attr('data-approver'));
        this.addContentText(this.$button.attr('data-description'));
        this.setVisibleButtons(['close']);
        this.url = this.$button.attr('data-url');
        this.onButtonClick('yes', () => { this.startClick(); });
        this.show();
        this.startBusy();

        table.fetchSelectedIdentifiers((ids) => {
            this.ids = ids;
            const counter = this.uiCount > 0 ? this.uiCount : this.ids.length;
            this.setTitle(this.actionTitle,
                Translator.transChoice('table.selection_count', counter, { '%count%' : counter }));
            this.setVisibleButtons(['yes', 'no']);
            this.stopBusy();
        });
    }

    onTaskCompleted(task) {
        if (task.link == null) {
            return;
        }
        if (task.output === 'REDIRECT') {
            $('<button></button>', {
                'class': 'btn btn-primary',
                'href': task.link
            }).text(Translator.trans('button.continue')).appendTo(this.getBody());
        } else {
            this.load(task.link, () => {
                this.setVisibleButtons(['close']);
            });
        }
    }

    startClick() {
        let data = { action: this.action };
        if (this.ids) {
            // string necessary otherwise the browser will limit the array to 1000 items
            data.ids = this.ids.join(',');
        }
        const buttonParameters = this.$button.attr('data-parameters');
        if (buttonParameters) {
            $.extend(data, JSON.parse(buttonParameters));
        }
        this.clear();
        this.addStatus(this.$button.attr('data-processing'));
        this.addProgressBar();
        this.setVisibleButtons(['dismiss']);
        const url = this.$button.attr('data-url') ? this.$button.attr('data-url') : this.$button.attr('href');
        $.ajax({
            method: 'POST',
            url: url,
            data: data,
            success: (response) => {
                if (response.success) {
                    if (response.async) {
                        this.getStatus().addClass('task-status-text-' + response.channel);
                        this.getProgressBar().addClass('task-progressbar-' + response.channel);
                        this.addContentText('', 'text-right task-progress-' + response.channel);
                        this.setVisibleButtons(['dismiss']);
                        window.statusManager.onTaskCompletion(response.channel, (task) => this.onTaskCompleted(task));
                    }
                    this.onHide(() => {
                        if (this.reloadAfter) {
                            window.pageReloader.reload();
                        } else {
                            $('.dataTable input[type=checkbox]').prop('checked', false).prop('indeterminate', false);
                            window.dataTable.refreshTable();
                        }
                    });
                    if (response.completed) {
                        this.hide();
                    }
                    if (response.flash) {
                        if (response.completed === false) {
                            window.statusManager.onTaskCompletion(response.channel,
                                (task) => window.statusManager.flashTask(task));
                            this.hide();
                        }
                    }
                    window.statusManager.activity();
                    this.removeProgressBar();
                }
                if (response.errors) {
                    for (let i = 0; i < response.errors.length; i++) {
                        const error = response.errors[i];
                        let text = error.message;
                        if (error.template) {
                            text = Translator.trans(error.template, error.parameters);
                        }
                        this.addError(text);
                    }
                    this.setVisibleButtons(['close']);
                }
            },
            error: (error) => {
                this.addError(error.statusText);
                this.setVisibleButtons(['close']);
            },
        });
    }

}
