import { ActionModal } from '../modal/ActionModal';
import { FormModal } from '../modal/FormModal';
import $ from 'jquery';

function openActionButton(event) {
    const $button = $(event.currentTarget);
    const actionType = $button.attr('data-action-type');
    if (actionType === 'form') {
        const modal = new FormModal('form-modal');
        modal.openFromButton(event.currentTarget);
    } else {
        const modal = new ActionModal('action-modal');
        modal.openFromButton(event.currentTarget);
    }
}

function registerActionButtons()
{
    $(function () {
        $(document).on('click', '.action-button', function (event) {
            event.preventDefault();
            if (window.currentModal) {
                window.currentModal.hide(function () {
                    openActionButton(event);
                });
            } else {
                openActionButton(event);
            }
            return false;
        });
        $(document).on('click', '.action-checkbox', function (event) {
            const $container = $(this).closest('.action-selectors');
            const selection = [];
            $container.find('.action-checkbox').each(function () {
                if ($(this).prop('checked')) {
                    const id = $(this).val();
                    let actions = ['*'];
                    if ($(this).attr('data-allowed-actions')) {
                        actions = $(this).attr('data-allowed-actions').split(',');
                    }
                    selection.push({ id, actions });
                }
            });
            $container.find('.action-selection').each(function () {
                const ids = [];
                const action = $(this).attr('data-action');
                for (let i = 0; i < selection.length; i++) {
                    const group = selection[i];
                    if (group.actions.indexOf(action) >= 0) {
                        ids.push(group.id);
                    }
                }
                let url = $(this).attr('href');
                let i = url.indexOf('ids=');
                url = url.substr(0, i) + 'ids=' + ids.join(',');
                $(this).attr('href', url);
                $(this).attr('data-ids', ids.join(','));
                $(this).toggleClass('disabled', ids.length === 0);
            });
        });
    });
}

registerActionButtons();
