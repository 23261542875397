import { DataTableControl } from './DataTableControl';

export class DataTableManager
{
    constructor () {
        this.dataTables = {};
        $.fn.dataTableExt.errMode = 'none';
    }

    registerTable(name, configuration) {
        const table = new DataTableControl(name, configuration);
        this.dataTables[name] = table;
        this.defaultTable = table;
        return table;
    }

    getTable(name) {
        return this.dataTables[name];
    }

    table() {
        return this.defaultTable;
    }
}
