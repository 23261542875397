import '../../assets/vendor/libs/c3/c3';
import { GridTransform } from './GridTransform';
import { Dataset } from './Dataset';
import Currency from '../util/Currency';

export default class ChartFormat {

    constructor (dataset) {
        this.dataset = dataset;
    }

    createC3Data(chartType) {
        const transformer = new GridTransform();
        const data = new Dataset(this.dataset);
        const grid = transformer.transform(data);
        let output = { data: { columns: [ ] }, axis: { x: {}, y: {} } };
        if (grid.axisType === 'DATE') {
            output.data.x = 'x';
            output.data.xFormat = '%Y-%m-%d';
            output.data.columns.push([ 'x', ...grid.axis ]);
            output.axis.x = { type: 'timeseries', tick: { format: '%Y-%m-%d' } };
        }
        if (grid.axisType === 'DATETIME') {
            output.data.x = 'x';
            output.data.columns.push([ 'x', ...grid.axis ]);
            output.data.xFormat = '%Y-%m-%d %H:%M';
            /* aggregation may have changed DATETIME to DATE */
            const sample = grid.axis[0] ?? '';
            if (null !== sample.match(/^\d+\-\d+\-\d+$/)) {
                output.data.xFormat = '%Y-%m-%d';
            }
            output.axis.x = { type: 'timeseries', tick: { format: '%Y-%m-%d %H:%M', fit: true, count: 5 } };
        }
        if (grid.axisType === 'STRING') {
            output.axis.x = { type: 'category', categories: grid.axis, tick: { multiline: false }, height: 200 };
            output.axis.rotated = true;
        }
        if (grid.metricType === 'MONEY') {
            output.axis.y = { tick: { format: (value) => Currency.format(value, this.dataset.options.currency) } };
        }
        if (chartType === 'pie') {
            data.rows.forEach((row) => {
                output.data.columns.push([...row]);
            });
        } else {
            grid.buckets.forEach((bucket) => {
                output.data.columns.push([bucket.series, ...bucket.data]);
            });
        }
        if (chartType) {
            output.data.type = chartType;
        }
        return output;
    }

    draw ($container, chartType, options) {
        let data = this.createC3Data(chartType);
        data.bindto = '#' + $container.attr('id');
        if (options) {
            $.extend(data, options);
            if (options.dateFormat) {
                if (data.axis.x.type === 'timeseries') {
                    data.axis.x.tick.format = options.dateFormat;
                }
            }
            if (options.bare) {
                data.padding = { left: 25, right: 25 };
                data.axis.x.show = true;
                data.axis.x.tick.count = 3;
                data.axis.x.tick.centered = false;
                //data.axis.x.padding = { left: 10, right: 10 };
                data.axis.y.show = false;
                data.legend = { show: false };
                //data.axis.y.tick.show = false;
            }
        }
        data = $.extend(data, options);
        window.c3.generate(data);
    }
}
