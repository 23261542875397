export class FormEvent
{
    constructor(id, parameters, delta, undoId, undoParameters, undoDelta) {
        this.timestamp = new Date();
        this.uid = this.timestamp.toISOString();
        this.id = id;
        this.undoId = undoId;
        this.parameters = parameters;
        this.undoParameters = undoParameters;
        this.delta = delta;
        this.undoDelta = undoDelta;
        this.description = null;
    }
}