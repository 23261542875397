export function formSearch(options) {
    new FormSearch($(this), options);
}

function FormSearchControl(id) {

    this.id = id;
    this.$control = $('#' + id);
    this.$container = null;
    this.label = null;
    this.help = null;

    this.getContainer = function () {
        if (this.$container == null) {
            this.$container = this.$control.closest('.form-group');
            if (this.$container.length === 0) {
                this.$container = this.$control;
            }
        }
        return this.$container;
    };

    this.getLabel = function () {
        if (this.$control.attr('data-label')) {
            this.label = this.$control.attr('data-label');
        }
        if (this.label == null) {
            let $labelControl = $('label[for=' + this.id + ']');
            if ($labelControl.length === 1) {
                this.label = $labelControl.text();
            }
        }
        if (this.label == null) {
            if (this.$control.attr('placeholder')) {
                this.label = this.$control.attr('placeholder');
            }
        }
        if (this.label == null) {
            if (this.$control.is('select')) {
                let $first = this.$control.find('option:first-child');
                if ($first.length) {
                    this.label = $first.text();
                }
            }
        }
        return this.label;
    };

    this.getHelp = function () {
        const self = this;
        if (this.help == null) {
            if (this.$control.attr('data-help')) {
                this.help = [ this.$control.attr('data-help') ];
                return this.help
            }
            this.help = [];
            let $helpControl = this.getContainer().find('small.form-text');
            if ($helpControl.length === 1) {
                this.help.push($helpControl.text());
            }
            if (this.$control.is('select')) {
                this.$control.find('option').each(function () {
                    self.help.push($(this).text());
                })
            }
        }
        return this.help;
    };

    this.isVisibleOrTab = function ($control) {
        if ($control.css('display') === 'none' && !$control.hasClass('tab-pane')) {
            return false;
        }
        if ($control.is('form') || $control.hasClass('form-search-element')) {
            return true;
        }
        return this.isVisibleOrTab($control.parent());
    };

    this.match = function (q) {
        if (!this.isVisibleOrTab(this.$control)) {
            return false;
        }
        const regex = new RegExp(q, 'i');
        const value = this.$control.val();
        if (value && regex.test(value)) {
            return true;
        }
        return (regex.test(this.getLabel()) || regex.test(this.getHelp().join(' ')));
    };

    this.render = function (q) {
        const regex = new RegExp(q, 'i');
        let display = this.getLabel();
        let help = this.getHelp();
        let helpText = '';
        for (let i = help.length - 1; i >= 0; i--) {
            helpText = help[i];
            if (helpText.search(regex) !== -1) {
                break;
            }
        }
        if (this.$control.val() && regex.test(this.$control.val())) {
            helpText = this.$control.val();
        }
        if (helpText.length > 60) {
            let i = helpText.search(regex);
            if (i > 25) {
                helpText = helpText.substr(i - 25);
                i = helpText.search(' ');
                if (i > 0) {
                    helpText = helpText.substr(i + 1);
                }
                helpText = '...' + helpText;
            }
            if (helpText.length > 60) {
                helpText = helpText.substr(0, 60) + '...';
            }
        }
        if (helpText) {
            display += '<br/><small>' + helpText  + '</small>';
        }
        return '<div class="form-search-suggestion">' + display + '</div>';
    };

    this.select = function () {
        const self = this;
        const $tab = this.$control.closest('.tab-pane');
        if ($tab.length) {
            const tabId = $tab.attr('id');
            $('.nav-link[href="#' + tabId + '"]').tab('show');
        }
        this.getContainer().addClass('highlight');
        setTimeout(function () {
            const link = self.$control.find('a');
            if (link.length === 1) {
                link.focus();
            } else {
                self.$control.focus();
            }
            self.$control.one('blur', function () {
                self.getContainer().removeClass('highlight');
            });
        }, 200);
        setTimeout(function () { self.getContainer().removeClass('highlight'); }, 1000);
    }
}

function FormSearch($searchControl) {

    let self = this;

    this.MINIMUM_CONTROLS = 6;

    this.controls = [];
    this.$searchControl = null;
    this.$form = null;

    this.reload = function () {
        this.controls = [];
        this.loadControls();
        this.$searchControl.closest('.form-search-container').toggle(this.controls.length >= this.MINIMUM_CONTROLS);
    };

    this.loadControls = function() {
        const self = this;
        this.$form.find('input, select, textarea, .form-search-element').each(function () {
            let $control = $(this);
            if ($control.hasClass('form-search-element') || $control.hasClass('form-control') || $control.parent().hasClass('custom-checkbox')) {
                const id = $control.attr('id');
                if (id) {
                    self.controls.push(new FormSearchControl(id));
                }
            }
        });
    };

    this.init = function ($searchControl) {
        this.$searchControl = $searchControl;
        if ($searchControl.attr('data-target')) {
            this.$form = $('form[name="' + $searchControl.attr('data-target') + '"]');
            if (this.$form.length === 0) {
                this.$form = $($searchControl.attr('data-target'));
            }
        } else {
            this.$form = $searchControl.closest('.layout-container').find('form');
        }
        if (this.$form.length) {
            this.initSearchControl(this.$searchControl);
            this.reload();
        }
    };

    this.searchFunction = function (q, syncResults) {
        let matches = [];
        for (let i = 0; i < self.controls.length; i++) {
            let control = self.controls[i];
            if (control.match(q)) {
                matches.push({id: control.id, q: q});
            }
        }
        syncResults(matches);
    };

    this.initSearchControl = function ($searchControl) {
        $searchControl.typeahead({
            highlight: true,
            minLength: 1
        }, {
            name: 'form',
            source: this.searchFunction,
            templates: {
                suggestion: function (data) {
                    const formSearchControl = new FormSearchControl(data.id);
                    return formSearchControl.render(data.q);
                },
                notFound: function () {
                    if ($searchControl.val() === '') {
                        return '';
                    }
                    return '<div class="form-search-empty">' + Translator.trans('form.search_empty', null, 'messages') + '</div>';
                }
            },
            display: function () { return ''; }
        });
        $searchControl.bind('typeahead:select', function (event, suggestion) {
            const formSearchControl = new FormSearchControl(suggestion.id);
            formSearchControl.select();
        });
        $searchControl.on('input', () => {
            let text = $searchControl.val();
            if (text.indexOf("\n") > 5) {
                this.$form.formAccessor().setFormText(text);
                this.$form.find('input,select').first().focus();
                setTimeout(() => $searchControl.val(''));
            }
        });
    };

    this.init($searchControl);

}
