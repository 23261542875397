export function formClipboardCopy(options) {
    new FormClipboardCopy($(this));
    return $(this);
}

function FormClipboardCopy($control) {
    this.$control = $control;

    this.$control.on('click', () => {
        const $form = this.$control.closest('.layout-container').find('form');
        const text = $form.formAccessor().getFormText();
        window.copyToClipboard(text);
    });
}
