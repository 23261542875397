import $ from 'jquery';

export function autocompleteControl(options) {
    new AutocompleteControl($(this), options)
}

class AutocompleteControl {

    constructor($control, options) {
        this.$control = $control;
        this.options = options;
        this.init();
    }

    getUrl() {
        return this.$control.attr('data-autocomplete-url');
    }

    getQueryParam() {
        return 'q';
    }

    getDisplayProperty() {
        return 'text';
    }

    init() {
        const self = this;
        this.$control.typeahead({
            highlight: true,
            minLength: 3,
        }, {
            display: function (data) {
                return data.text;
            },
            templates: {
                suggestion: function (data) {
                    return '<div>' + (data.html ? data.html : data.text) + '</div>';
                }
            },
            source: function(q, syncResults, asyncResults) {
                $.ajax({
                    url: self.getUrl(),
                    data: { q },
                }).then((data) => asyncResults(data.results));
            }
        });
        this.$control.bind('typeahead:select', (ev, suggestion) => {
            this.$control.attr('data-typeahead-id', suggestion.id);
            if (this.options.onSelect) {
                this.options.onSelect(suggestion);
            }
        });
        if (this.options.onKeyup) {
            this.$control.on('keyup', this.options.onKeyup);
        }
    }

}
