import Handlebars from 'handlebars/dist/handlebars';

export function helperCardTitle(card) {
    let output = '';
    if (card.orgname) {
        output = card.orgname;
    } else if (card.attn) {
        output = card.attn;
    } else if (card.city) {
        output = card.city;
    } else {
        output = '(' + card.country + ')';
    }
    return new Handlebars.SafeString(output);
}
