export class PageReloader
{
    constructor() {
        this.callbacks = [];
        this.reloadUrl = window.location.href;
    }

    registerCallback(callback) {
        this.callbacks.push(callback);
    }

    storeTabStates() {
        this.tabPanes = [];
        this.navLinks = [];
        $('.tab-pane').each((index, element) => {
            const id = $(element).attr('id');
            if (id) {
                this.tabPanes[id] = $(element).attr('class');
            }
        });
        $('.nav-tabs .nav-link').each((index, element) => {
            let id = $(element).attr('id');
            if (!id) {
                id = $(element).attr('href');
            }
            if (id && id !== '#') {
                this.navLinks[id] = $(element).attr('class');
            }
        });
    }

    loadTabStates() {
        $('.tab-pane').each((index, element) => {
            const id = $(element).attr('id');
            if (this.tabPanes.hasOwnProperty(id)) {
                $(element).attr('class', this.tabPanes[id]);
            }
        });
        $('.nav-tabs .nav-link').each((index, element) => {
            const id = $(element).attr('id');
            if (id && this.navLinks.hasOwnProperty(id)) {
                $(element).attr('class', this.navLinks[id]);
            } else {
                const href = $(element).attr('href');
                if (this.navLinks.hasOwnProperty(href)) {
                    $(element).attr('class', this.navLinks[href]);
                }
            }
        });
    }

    reloadElement($element) {
        const url = this.reloadUrl;
        $.ajax(url).then((page) => {
            const $page = $(page);
            const $replace = $page.find('#' + $element.attr('id'));
            $element.html($replace.html())
        });
    }

    reload() {
        const url = this.reloadUrl;
        const $layout = $('.layout-content');
        if ($layout.length === 1) {
            this.storeTabStates();
            $.ajax(url).then((page) => {
                const $page = $(page);
                const $newLayout = $page.find('.layout-content');
                if ($newLayout.length === 1) {
                    $layout.replaceWith($newLayout);
                } else {
                    const $modalContent = $page.find('.modal-content');
                    if ($modalContent.length === 1) {
                        $layout.find('.page-content').html($modalContent.html());
                    }
                }
                const $newTitle = $page.find('.navbar-title');
                if ($newTitle.length === 1) {
                    $('.navbar-title').replaceWith($newTitle);
                }
                this.loadTabStates();
                for (let i = 0; i < this.callbacks.length; i++) {
                    this.callbacks[i](this);
                }
            });
        }
    }
}
