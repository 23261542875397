import $ from 'jquery';
import _ from 'underscore';
import './GlobalSearch.scss'

export class GlobalSearch {

    constructor() {
        this.busyCounter = 0;
    }

    attach(selector, url) {
        this.url = url;
        this.$container = $(selector);
        this.$output = this.$container.find('.search-results-container');
        this.$output.on('click', (event) => {
            const $element = $(event.target);
            const url = $element.closest('.search-result').attr('data-target');
            if (url) {
                window.location.href = url;
            }
        });
        this.$input = this.$container.find('input.search-input');
        this.$input.on('keyup', _.debounce((event) => {
            if (event.key !== 'Enter') {
                this.updateSearch()
            }
        }, 300));
        this.$input.on('keydown', (event) => {
            if (event.key === 'Enter') {
                const $result = this.$output.find('.search-result').first();
                if ($result.length > 0) {
                    const url = $result.attr('data-target');
                    if (url) {
                        window.location.href = url;
                        event.preventDefault();
                        return false;
                    }
                } else {
                    window.location.href = this.$input.attr('data-url') + '?q=' + encodeURIComponent(this.$input.val());
                    event.preventDefault();
                    return false;
                }
            }
        });
        this.$container.on('shown.bs.dropdown', () => {
            this.$input.focus();
        })
    }

    handleResponse(response) {
        this.$output.html(response);
    }

    searchStarted() {
        this.busyCounter++;
        if (this.busyCounter === 1) {
            this.$output.addClass('busy-indicator').empty();
        }
    }

    searchCompleted() {
        this.busyCounter--;
        if (this.busyCounter === 0) {
            this.$output.removeClass('busy-indicator');
        }
    }

    updateSearch() {
        this.searchStarted();
        const terms = this.$input.val();
        $.ajax(this.url, { data: { q: terms }})
            .then((response) => this.handleResponse(response))
            .always(() => this.searchCompleted());
    }

}
