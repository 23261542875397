export class DisplayDocuments {

    constructor() {
        $('.btn-display-document').on('click', (e) => this.button(e.target));
    }

    getUrl() {
        return this.getModal().attr('data-url');
    }

    getModal() {
        return $('#display-document-modal');
    }

    getContainer() {
        return $('#display-document-container')
    }

    open() {
        this.getContainer().empty().addClass('busy-indicator');
        this.getModal().modal('show');
    }

    display(url, mimeType) {
        $('<object></object>')
            .attr('data', url)
            .attr('type', mimeType)
            .attr('width', '100%')
            .attr('height', '100%')
            .appendTo(this.getContainer());
    }

    load(ids, type) {
        this.open();
        $.ajax(this.getUrl(), {
            method: 'POST',
            data: { ids, type }
        }).done((document) => {
            this.display(document.url, document.mimeType);
        }).fail((error) => {
            this.error(error.statusText);
        }).always(() => {
            this.getContainer().removeClass('busy-indicator');
        });
    }

    error(msg) {
        this.getContainer().empty();
        $('<div></div>').addClass('alert alert-danger').text(msg).appendTo(this.getContainer());
    }

    modal(e) {
        const $button = $(e);
        const ids = $button.attr('data-selection').split(',');
        const documentType = $button.attr('data-type');
        this.load(ids, documentType);
        return false;
    }


}
