export default class Currency
{
    static format(value, currency) {
        let amount = Math.round(value * 100) / 100;
        if (amount < 0) {
            amount = -amount;
            return '-$' + amount.toFixed(2);
        } else {
            return '$' + amount.toFixed(2);
        }
    }
}