export class ActionBookmarkControl
{
    constructor(modal) {
        this.modal = modal;
        this.$button = $('#btn-action-bookmark');
        this.$button.on('click', () => this.createBookmark());
    }

    update() {
        this.$button.closest('.action-bookmark-group').toggle(!!this.modal.action);
    }

    createBookmark() {
        const url = this.$button.attr('data-url');
        const caption = $('#action-bookmark-caption').val();
        const data = { 'action': this.modal.action, 'action_ids': this.modal.ids, 'view_caption': caption };
        this.$button.attr('disabled', 'disabled');
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: () => {
                window.flash.success(this.$button.attr('data-success-flash'));
                window.statusManager.reloadViews();
            },
            complete: () => this.$button.removeAttr('disabled')
        });
    }
}
