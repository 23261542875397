import $ from 'jquery';
import _ from 'underscore';
import Handlebars from 'handlebars/dist/handlebars';
import { helperCard } from '../template/HelperCard';
import { helperCardTitle } from '../template/HelperCardTitle';

Handlebars.registerHelper('card-display', helperCard);
Handlebars.registerHelper('card-title', helperCardTitle);

export function searchControl(options) {
    const control = new SearchControl(this, options);
    control.initControl();
    control.updateSearch();
}

class SearchControl
{

    constructor(control, options)
    {
        this.$control = $(control);
        this.template = null;
        this.lastResponse = null;
        this.requestingMore = false;
        this.options = $.extend({
            'input': this.$control.attr('data-input'),
            'url': this.$control.attr('data-url'),
            'template': this.$control.attr('data-template'),
            'highlight': []
        }, options);
        this.$input = $(this.options.input);
        this.initTemplate();
    }

    applyHighlights(text)
    {
        let terms = this.$input.val().trim();
        if (terms.indexOf('#') === 0) {
            if (this.options.highlight) {
                terms = this.options.highlight;
            }
        }
        if (typeof terms === 'string') {
            terms = terms.split(' ');
        }
        for (let i = 0; i < terms.length; i++) {
            let highlight = terms[i].toUpperCase();
            highlight = highlight.replace(/[^0-9A-Z]/g, '.');
            let highlightTest = highlight.replace('.', '');
            if (highlightTest.length >= 3) {
                const regex = new RegExp('(\>[^\<\>]*)(' + highlight + ')([^\<\>]*\<)', 'gi');
                text = text.replace(regex, '$1<span class="highlight">$2</span>$3');
            }
        }
        return text;
    }

    initTemplate()
    {
        const $template = this.$control.find('.template');
        if ($template.length) {
            let str = $template.html();
            str = str.replace(/\{\$/g, '{{').replace(/\$\}/g, '}}');
            this.template = Handlebars.compile(str);
            $template.remove();
        }
    }

    handleSearchResponse(response)
    {
        this.lastResponse = response;
        if (response.page === 1) {
            this.$control.empty();
        }
        for (let i = 0; i < response.results.length; i++) {
            const record = response.results[i];
            let item = this.template(record);
            item = this.applyHighlights(item);
            $(item).appendTo(this.$control);
        }
    }

    hasMore()
    {
        if (this.lastResponse) {
            if (this.lastResponse.morePath) {
                return true;
            }
        }
        return false;
    }

    searchMore()
    {
        if (this.requestingMore === false && this.hasMore()) {
            this.requestingMore = true;
            this.$control.addClass('busy-indicator');
            $.ajax(this.lastResponse.morePath, {
                success: (response) => this.handleSearchResponse(response),
                complete: () => {
                    this.$control.removeClass('busy-indicator');
                    this.requestingMore = false;
                }
            });
        }
    }

    updateSearch()
    {
        const terms = this.$input.val();
        this.$control.addClass('busy-indicator');
        $.ajax(this.options.url, {
            data: { q: terms, page: 1 },
            success: (response) => this.handleSearchResponse(response),
            complete: () => this.$control.removeClass('busy-indicator')
        });
    }

    initControl()
    {
        this.$input.on('keyup paste', _.debounce(() => this.updateSearch(), 300));
        this.$control.on('scroll', () => {
            if (this.$control.scrollTop() + this.$control.innerHeight() >= this.$control[0].scrollHeight) {
                this.searchMore();
            }
        });
    }
}

