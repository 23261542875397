import { Modal } from './Modal';

export class ViewModal extends Modal
{
    constructor() {
        super('action-modal');
        this.setVisibleButtons(['close']);
    }

    openFromButton(button) {
        this.$button = $(button);
        this.open(this.$button.attr('href'));
    }

    open(url) {
        this.clear();
        this.startBusy();
        this.setTitle('');
        this.show();
        $.ajax(url, {
            success: (response) => {
                if (response.content) {
                    /* This is the new method */
                    this.clear();
                    this.setTitle(response.title);
                    this.addContent($(response.content));
                    if (response.banner) {
                        this.addBanner($(response.banner));
                    }
                    if (response.buttons) {
                        for (let name in response.buttons) {
                            if (response.buttons.hasOwnProperty(name)) {
                                this.enableButton(name);
                                for (let attr in response.buttons[name]) {
                                    if (response.buttons[name].hasOwnProperty(attr)) {
                                        this.getButtonByName(name).attr(attr, response.buttons[name][attr]);
                                        if (attr === 'data-ajax') {
                                            this.getButtonByName(name)
                                                .off('click')
                                                .on('click', (event) => this.modalButtonClick(event));
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (response.downloads && response.downloads.length > 0) {
                        this.enableButton('download');
                        const $formats = $('#action-output-download-formats').empty();
                        for (let i = 0; i < response.downloads.length; i++) {
                            let ref = response.downloads[i];
                            const $link = $('<a></a>', {
                                'class': 'dropdown-item',
                                'href': ref.url,
                            }).text(ref.caption).appendTo($formats);
                        }
                    }
                } else {
                    /* This is the old method */
                    const $html = $(response);
                    const content = $html.find('.modal-content').html();
                    const $title = $html.find('.modal-title');
                    const $buttons = $html.find('.modal-buttons');
                    this.clear();
                    this.setTitle($title.text());
                    this.addContent($(content));
                    this.addCustomButtons($buttons);
                    this.getCustomButtons()
                        .find('button').off('click').on('click', (event) => this.modalButtonClick(event));
                }
                this.getModal().trigger('content-loaded');
            },
            error: (xhr) => {
                this.addContent($(xhr.responseText));
            },
            complete: () => {
                this.stopBusy();
            }
        })
    }
}
