import $ from 'jquery';
import { Form } from '../form/Form';

export class ActionParameterControl
{
    constructor(modal) {
        this.modal = modal;
        this.$button = $('#action-parameters-control');
        this.$button.click(() => this.click());
        this.setState(false);
        this.update();
    }

    getForm() {
        return new Form(this.modal.getForm());
    }

    reset() {
        this.setState(false);
        this.update();
    }

    click() {
        this.setState(!this.getState());
        this.update();
    }

    setState(state) {
        if (state) {
            this.getTagIdControl().val(this.getTagId());
            const list = {};
            $('[data-tag-parameter]').each((index, element) => {
                const name = this.getForm().getPathFromControl($(element));
                list[name[0]] = '1';
            });
            this.getTagParameterMapControl().val(JSON.stringify(list));
        } else {
            this.getTagIdControl().val('');
            this.getTagParameterMapControl().val('');
        }
    }

    getState() {
        return !!this.getTagIdControl().val();
    }

    getTagParameterMapControl() {
        return this.modal.getModal().find('.action-parameters-map');
    }

    getTagIdControl() {
        return this.modal.getModal().find('.action-parameters-tag');
    }

    getTagId() {
        if (window.dataTableManager && window.dataTableManager.defaultTable && window.dataTableManager.defaultTable.tag) {
            return window.dataTableManager.defaultTable.tag;
        }
        return '';
    }

    update() {
        $('.action-parameter-tag-field').remove();
        if (this.getTagId()) {
            this.show();
            const state = this.getState();
            if (state) {
                this.$button.addClass('btn-secondary').removeClass('btn-outline-secondary');
                $('[data-tag-parameter]').each((index, element) => this.createTagIcon(element));
            } else {
                this.$button.addClass('btn-outline-secondary').removeClass('btn-secondary');
            }
        } else {
            this.setState(false);
            this.hide();
        }
    }

    hide() {
        this.$button.hide();
    }

    show() {
        this.$button.show();
    }

    createTagIcon(control) {
        const $label = $(control).closest('.form-group').find('label');
        const $icon = $('<i></i>', {
            'class': 'fa fa-tag text-muted ml-1 action-parameter-tag-field',
            'data-toggle': 'tooltip',
            'title': Translator.trans('action.tag_parameter_field')
        });
        $label.append($icon);
    }

}
