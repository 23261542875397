import Currency from '../util/Currency';

export function tableSummary(options) {
    return new TableSummary(this, options);
}

class TableSummary {

    constructor(element, options) {
        this.$table = $(element);
        this.options = options;
        this.update();
        this.$table.on('click', 'input[type=checkbox]', () => this.update());
    }

    update() {
        const checks = this.$table.find('input[type=checkbox]:checked').length;
        this.$table.find('.summary-currency').each((index, element) => {
            const target = $(element).attr('data-target');
            const requireSelection = $(element).attr('data-require-selection') || false;
            let totals = {};
            this.$table.find(target).each((index, element) => {
                const checked = $(element).closest('tr').find('input[type=checkbox]').prop('checked');
                const value = $(element).attr('data-value') * 1;
                if ((checks === 0 && !requireSelection) || checked) {
                    const currency = $(element).attr('data-currency');
                    if (!totals.hasOwnProperty(currency)) {
                        totals[currency] = 0;
                    }
                    totals[currency] += value;
                }
            });
            let html = '';

            for (let currency in totals) {
                html += '<div class="currency">' + Currency.format(totals[currency], currency) + ' ' + currency + '</div>';
            }


            $(element).html(html);
        });
    }

}
