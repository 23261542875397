import $ from 'jquery';
import { FormSubmit } from '../form/FormSubmit';

export class TagParameter {

    constructor (url) {
        this.url = url;
        this.cache = [];
        $(document).on('content-loaded', () => {
            this.run();
        })
    }

    currentEntityId() {
        if (window.currentModal.ids) {
            if (window.currentModal.ids.length === 1) {
                return window.currentModal.ids[0];
            }
        }
        return null;
    }

    run() {
        const $elements = $('[data-tag-parameter]');
        const id = this.currentEntityId();
        if (id && $elements.length > 0) {
            FormSubmit.disableSubmission();
            $.ajax(this.url, {
                data: { eid: id },
                success: (response) => {
                    if (response.match) {
                        $elements.each((index, element) => {
                            const $input = $(element);
                            const parameterKey = 'parameter' + $input.attr('data-tag-parameter');
                            if (response.hasOwnProperty(parameterKey)) {
                                $input.val(response[parameterKey]);
                            }
                        });
                    }
                },
                complete: () => FormSubmit.enableSubmission()
            })
        }
    }

}
