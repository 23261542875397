import { Form } from '../form/Form';
import $ from 'jquery';
import './ReportEditor.scss';

export function reportEditor(options) {
    new ReportEditor($(this), options);
    return $(this);
}

class ReportEditor extends Form
{
    constructor ($form, options) {
        super($form);
        this.options = options;
        this.DIMENSION = 'DIMENSION';
        this.METRIC = 'METRIC';
        $(document)
            .on('click', '.filter-object .btn-add-child', (event) => {
                this.filterAddChild($(event.currentTarget));
            })
            .on('click', '.filter-object .btn-delete-object', (event) => {
                this.filterDeleteObject($(event.currentTarget));
            })
            .on('click', '.filter-object .btn-conjunction-change', (event) => {
                this.filterChangeConjunction($(event.currentTarget));
            })
            .on('click', '.filter-object .btn-filter-column', (event) => {
                this.filterChangeColumn($(event.currentTarget));
            })
            .on('click', '.filter-object .btn-operator', (event) => {
                this.filterChangeOperator($(event.currentTarget));
            })
            .on('click', '.column-selector .btn-column-select', (event) => {
                this.addColumn($(event.currentTarget));
            })
            .on('click', '.column-container .btn-column-select', (event) => {
                this.changeColumn($(event.currentTarget));
            })
            .on('click', '.column-container .btn-delete-object', (event) => {
                this.deleteColumn($(event.currentTarget));
            })
            .on('click', '.btn-grouping-select', (event) => {
                this.changeGrouping($(event.currentTarget));
            })
            .on('click', '.btn-aggregation-select', (event) => {
                this.changeAggregation($(event.currentTarget));
            })
            .on('change', 'input[type="text"]', () => {
                this.updateSorting();
            })
            .on('click', '.btn-sorting-select', (event) => {
                this.sortChanged($(event.currentTarget));
            })
            .on('click', '.btn-direction-select', (event) => {
                this.sortDirectionChanged($(event.currentTarget));
            })
            .on('click', '.btn-format-select', (event) => {
                this.formatChanged($(event.currentTarget));
            })
            .on('click', '.btn-value-select', (event) => {
                this.filterValueSelected($(event.currentTarget));
            })
            .on('change', '.filter-value-date', (event) => {
                this.filterValueChanged($(event.currentTarget));
            })
            .on('change', '.filter-value-text', (event) => {
                this.filterValueChanged($(event.currentTarget));
            })
        ;
        this.updateSorting();
        this.initializeDragOrdering();
    }

    initializeDragOrdering() {
        this.$form.find('.columns-container').sortable({
            handle: '.sort-handle',
            stop: () => this.$form.find('.columns-container').each((index, element) => this.columnOrderReset($(element)))
        });
    }

    columnOrderReset($container) {
        let counter = -1;
        let lastIndex = null;
        const regex = /\[([0-9]+)\]/gm;
        $container.find('input').each((index, element) => {
            const $element = $(element);
            const name = $element.attr('name');
            if (name) {
                const result = regex.exec(name);
                if (result) {
                    const thisIndex = result[1];
                    if (thisIndex !== lastIndex) {
                        counter++;
                        lastIndex = thisIndex;
                    }
                    $element.attr('name', name.replace(regex, '[' + counter + ']'));
                }
            }
        });
    }

    filterValueChanged($input) {
        const $target = $($input.attr('data-target'));
        $target.val($input.val());
    }

    filterValueSelected($button) {
        const value = $button.attr('data-value');
        const $target = $($button.attr('data-target'));
        $target.val(value);
        $button.closest('.input-group').find('.btn-value-select-control').text($button.text());
    }

    formatChanged($button) {
        const formatId = $button.attr('data-id');
        const $target = $($button.attr('data-target'));
        $target.val(formatId);
        $button.closest('.input-group').find('.btn-format-choice').html($button.html());
    }

    deleteColumn($button) {
        $button.closest('.column-container').remove();
    }

    getColumnContainer(section) {
        return this.$form.find('.column-container[data-type="' + section + '"]');
    }

    filterChangeColumn($button) {
        const columnId = $button.attr('data-column');
        const $target = $($button.attr('data-target'));
        $target.val(columnId);
        $button.closest('.input-group').find('.btn-column-choice').text($button.text());
        this.updateSorting();
        const $object = $button.closest('.filter-object');
        $object.attr('data-type', $button.attr('data-type'));
        $object.attr('data-column', columnId);
        $object.find('.btn-operator-choice').text('');
        $object.find('.btn-value-select-control').text();
        $object.attr('data-operator', '');
    }

    filterChangeOperator($button) {
        const operatorId = $button.attr('data-operator');
        const $target = $($button.attr('data-target'));
        $target.val(operatorId);
        $button.closest('.input-group').find('.btn-operator-choice').text($button.text());
        const $object = $button.closest('.filter-object');
        $object.attr('data-operator', operatorId);
        $object.find('.btn-value-select-control').text();
    }

    filterChangeText($input) {
        const $target = $($input.attr('data-target'));
        $target.val($input.val());
    }
    
    updateColumnContainer($container, $button)
    {
        const grouping = $button.attr('data-grouping');
        const groupingCaption = $button.attr('data-grouping-caption');
        const aggregation = $button.attr('data-aggregation');
        const aggregationCaption = $button.attr('data-aggregation-caption');
        let columnCaption = $button.text();
        const columnType = $button.attr('data-type');
        const columnId = $button.attr('data-id');
        $container.find('[data-field="columnId"]').val(columnId);
        $container.find('[data-field="aggregation"]').val(aggregation);
        $container.find('[data-field="grouping"]').val(grouping);
        $container.find('.btn-aggregation-choice').text(aggregationCaption);
        $container.find('.btn-grouping-choice').text(groupingCaption);
        $container.find('.btn-column-choice').text(columnCaption);
        $container.find('input[type=text]').val(columnCaption.trim());
        $container.attr('data-column-id', columnId);
        $container.attr('data-column-type', columnType);
    }

    addColumn($button) {
        const destination = $button.attr('data-destination');
        const $container = this.$form.find('.columns-container[data-type="' + destination + '"]');
        let prototype = $container.attr('data-prototype');
        const index = parseInt($container.attr('data-index')) + 1;
        $container.attr('data-index', index);
        const $prototype = $(prototype.replace(/__name__/g, index));
        this.updateColumnContainer($prototype, $button);
        $container.append($prototype);
        this.updateSorting();
    }

    changeColumn($button) {
        const $container = $button.closest('.column-container');
        this.updateColumnContainer($container, $button);
    }

    changeGrouping($button) {
        const $container = $button.closest('.column-container');
        const grouping = $button.attr('data-grouping');
        $container.find('[data-field="grouping"]').val(grouping);
        $container.find('.btn-grouping-choice').text($button.text());
    }

    changeAggregation($button) {
        const $container = $button.closest('.column-container');
        const aggregation = $button.attr('data-aggregation');
        $container.find('[data-field="aggregation"]').val(aggregation);
        $container.find('.btn-aggregation-choice').text($button.text());
    }

    filterAddChild($btn) {
        const $container = $($btn.attr('data-target'));
        let prototype = $container.attr('data-prototype');
        if (prototype) {
            const name = $container.attr('data-prototype-name');
            let index = parseInt($container.parent().attr('data-index'));
            const regex = new RegExp(name, 'g');
            const $prototype = $(prototype.replace(regex, index));
            $container.parent().attr('data-index', index + 1);
            $container.append($prototype);
            $prototype.find('.btn-conjunction-change[data-conjunction="AND"]').click();
            const level = parseInt($container.attr('data-level'));
            if (level === 1) {
                $prototype.find('.btn-add-child').remove();
            }
        }
    }

    filterDeleteObject($btn) {
        const $object = $btn.closest('.filter-object');
        $object.parent().remove();
    }

    filterChangeConjunction($btn) {
        const $object = $btn.closest('.filter-object');
        const $target = $($btn.attr('data-target'));
        const conjunction = $btn.attr('data-conjunction');
        $target.val(conjunction);
        $object.attr('data-conjunction', conjunction);
        $btn.closest('.btn-group').find('.btn-conjunction').text($btn.text());
    }

    updateSorting() {
        const $selectedColumns = this.$form.find('.columns-container input[data-field="columnId"]');
        const $currentSelection = $('#report_parameters_sorting_columnId');
        const currentSelection = $currentSelection.val();
        let matched = false;
        const $menu = this.$form.find('.menu-sorting-columns').empty();
        const $choiceButton = this.$form.find('.btn-sort-choice');
        $selectedColumns.each((index, element) => {
            const $columnId = $(element);
            const columnId = $columnId.val();
            const $container = $columnId.closest('.column-container');
            const customerCaption = $container.find('input[type="text"]').val();
            const columnCaption = $container.find('.btn-column-choice').text();
            const aggregation = $container.find('[data-field="aggregation"]').val();
            const aggregationCaption = $container.find('.btn-aggregation-choice').text();
            const grouping = $container.find('[data-field="grouping"]').val();
            let caption = columnCaption;
            if (aggregationCaption) {
                caption += ' (' + aggregationCaption + ')';
            }
            if (currentSelection === columnId) {
                $choiceButton.text(caption);
                matched = true;
            }
            $('<a></a>', {
                'class': 'dropdown-item btn-sorting-select',
                'data-id': columnId,
                'data-aggregation': aggregation,
                'data-grouping': grouping,
                'href': 'javascript:void(0);'
            }).text(caption).appendTo($menu);
        });
        if (!matched) {
            $currentSelection.val('');
            $choiceButton.text($choiceButton.attr('data-default-caption'));
        }
    }

    sortChanged($button) {
        const $currentSelection = $('#report_parameters_sorting_columnId');
        const $choiceButton = this.$form.find('.btn-sort-choice');
        const $grouping = $('#report_parameters_sorting_grouping');
        const $aggregation = $('#report_parameters_sorting_aggregation');
        $currentSelection.val($button.attr('data-id'));
        $choiceButton.text($button.text());
        $grouping.val($button.attr('data-grouping'));
        $aggregation.val($button.attr('data-aggregation'));
    }

    sortDirectionChanged($button) {
        const $direction = $('#report_parameters_sorting_direction');
        $direction.val($button.attr('data-direction'));
        const $directionButton = this.$form.find('.btn-direction-choice');
        $directionButton.text($button.text());
    }
}
