import $ from 'jquery';

export class Paginator
{
    constructor(containerId, pageCount = 0, options = {}) {
        this.pageCount = pageCount;
        this.currentPage = 1;
        this.$container = $('#' + containerId);
        this.$container.on('click', '.page-link', (event) => this.pageSelect(event));
        this.onPageSelect = null;
        this.options = options;
        this.draw();
    }

    setPageCount(count) {
        this.pageCount = count;
        this.draw();
    }

    pageSelect(event) {
        const $page = $(event.currentTarget);
        const number = +$page.attr('data-page-number');
        if (this.onPageSelect) {
            this.onPageSelect(number);
        }
    }

    setPage(number) {
        this.currentPage = number;
        this.draw();
    }

    showPageNumber(number) {
        if (number === 1 || number === this.pageCount) {
            return true;
        }
        if (number >= (this.currentPage - 2) && number <= (this.currentPage + 2)) {
            return true;
        }
        return false;
    }

    draw() {
        this.$container.empty();
        let lastShow = null;
        if (this.pageCount <= 1) {
            return;
        }
        for (let n = 1; n <= this.pageCount; n++) {
            let show = this.showPageNumber(n);
            if (show) {
                const page = $('<li></li>', {
                    'class': 'page-item' + (n === this.currentPage ? ' active' : ''),
                }).appendTo(this.$container);
                $('<a></a>', {
                    'href': '#',
                    'class': 'page-link',
                    'data-page-number': n
                }).text(n).appendTo(page);
            } else if (lastShow) {
                const page = $('<li></li>', {
                    'class': 'page-item disabled',
                }).appendTo(this.$container);
                $('<a></a>', {
                    'href': '#',
                    'class': 'page-link disabled',
                    'data-page-number': 0
                }).html('&hellip;').appendTo(page);
            }
            lastShow = show;
        }
    }


}
