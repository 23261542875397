import { copyToClipboard } from './Clipboard';

function initializeClipboardElement() {
    window.addEventListener('mousedown', clipboardElementMouseDown, { capture: true });
    window.addEventListener('click', clipboardElementClick, { capture: true });
    window.addEventListener('keydown', clipboardElementKey);
    window.addEventListener('keyup', clipboardElementKey);
}

function clipboardElementMouseDown(event) {
    if (event.altKey && event.shiftKey) {
        const $element = $(event.target);
        const text = $element.text();
        if (text) {
            copyToClipboard(text.trim());
            flash.success(Translator.trans('flash.copied_to_clipboard'));
        }
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        return false;
    }
}

function clipboardElementClick(event) {
    if (event.altKey && event.shiftKey) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        return false;
    }
}

function clipboardElementKey(event) {
    $('body')
        .toggleClass('ctrl-key', event.ctrlKey)
        .toggleClass('shift-key', event.shiftKey)
        .toggleClass('alt-key', event.altKey);
}

initializeClipboardElement();
