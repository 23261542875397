/*
 * SearchTrigger
 *
 * Allow the user to click on keywords or key-phrases to have them copied to a search trigger control
 */

import $ from 'jquery';

export function searchTriggers(selector, target)
{
    $(document).on('mouseover', selector, (event) => {
        const $element = $(event.currentTarget);
        $('<i></i>', {
            'class': 'search-trigger fa fa-search',
            'style': 'position: absolute; right: 0; opacity: 0.75'
        }).appendTo($element);
        $element.css('cursor', 'pointer');
    });
    $(document).on('mouseout', selector, (event) => {
        const $element = $(event.currentTarget);
        $element.find('.search-trigger').remove();
    });
    $(document).on('click', selector, (event) => {
        const $element = $(event.currentTarget);
        let text = $element.text();
        if ($element.attr('data-term')) {
            text = $element.attr('data-term');
        }
        if ($element.attr('data-append')) {
            let append = $element.attr('data-append');
            text = $(target).val();
            if (text.indexOf(append) === -1) {
                text += ' ' + append;
            }
        }
        $(target).val(text).trigger('keyup');
    });
}