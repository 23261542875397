import Handlebars from 'handlebars/dist/handlebars';
import { helperCard } from './HelperCard';

export class Template
{
    constructor(selector) {
        if (selector) {
            this.source(selector);
        }
    }

    source(selector) {
        const $source = $(selector);
        let str = $source.html();
        str = str.replace(/\{\$/g, '{{').replace(/\$\}/g, '}}');
        this.template = Handlebars.compile(str);
        $source.remove();
    }

    render(record) {
        if (this.template) {
            return this.template(record);
        }
        return '';
    }
}

Handlebars.registerHelper('card-display', helperCard);
